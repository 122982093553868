import { SORT_DIRECTION } from '@capasystems/constants';
import { TSocketMethod } from '@capasystems/types';
import { Avatar, Icon, IconButton, LayoutCentered, LayoutRow, Loading, Page, Tab, Tabs, useParams } from '@capasystems/ui';
import { Url, getSortingFunction, noop } from '@capasystems/utils';
import { TAndroidConfigurationWithId } from '@db/party';
import pluralize from 'pluralize';
import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import ConfirmDialog from '../../confirm-dialog/confirm-dialog';
import { streamlineApplicationDocument } from '../../hooks/useApi/android-connection';
import { useAndroidApi, useManagementApi } from '../../hooks/useApi/useApi';
import useNavigate from '../../hooks/useNavigate/useNavigate';
import { useAndroidApplicationOrConfigurationSocket, useGroupsSocket } from '../../hooks/useSocket/useSocket';
import { PageTitle } from '../../page/page-title';
import { ActionsDialog, CapaOneLink, RenamingDialog, TwoLineCellRenderer } from '../../thirdparty-components/thirdparty-components';
import { TransferList } from '../../transfer-list/transfer-list';
import { AndroidApplicationManagedConfigurationController } from '../android-management-exports';
import { AndroidApplicationSummary } from './AndroidApplicationSummary';
import { AndroidConfigurationSummary } from './AndroidConfigurationSummary';

const tabClassName = 'tw-font-semibold tw-min-w-0 lg:tw-px-8';

const renameAction = {
    id: 'rename',
    name: 'Rename',
};
const deleteAction = {
    id: 'delete',
    name: 'Delete',
};

const sortingFunction = getSortingFunction({
    sortDirection: SORT_DIRECTION.ASC,
    sortBy: 'name',
});

type TAndroidConfigurationWithInstallTypeName = TAndroidConfigurationWithId & { installTypeName?: string };

export type AndroidApplicationAndConfigurationMembershipManagementProps = {
    isApplication: boolean;
};

const AndroidApplicationAndConfigurationMembershipManagement: React.FC<AndroidApplicationAndConfigurationMembershipManagementProps> = ({ isApplication }) => {
    const androidApi = useAndroidApi();
    const { configurationId } = useParams();
    const { appendBaseURL, ...navigate } = useNavigate();
    const [configuration, setConfiguration] = useState<TAndroidConfigurationWithInstallTypeName | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState(Url.getString('tab', 'summary'));
    const [actionsDialogProps, setActionsDialogProps] = useState<{
        open: boolean;
        anchorEl: any;
        pages: any[];
        actions: any[];
        category: string;
        title: string;
    }>({
        open: false,
        anchorEl: null,
        pages: [],
        actions: [],
        category: '',
        title: '',
    });
    const [isRenaming, setIsRenaming] = useState(false);
    const [deleteState, setDeleteState] = useState<{ open: boolean; isProcessing?: boolean; anErrorOccurred?: boolean }>({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
    });

    const onTabChange = (_: any, tab: string) => {
        setActiveTab(tab);
        Url.set('tab', tab);
    };

    const openMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
        setActionsDialogProps({
            open: true,
            anchorEl: e.currentTarget,
            pages: [
                {
                    name: 'Edit',
                    url: isApplication ? `android/application/${configuration?.id}/edit` : `android/configuration/${configuration?.id}/edit`,
                    icon: 'editOutlined',
                },
            ],
            actions: [renameAction, deleteAction],
            category: isApplication ? 'Application' : 'Configuration',
            title: configuration?.name || '',
        });
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            closeMenu();
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName: string) => {
        setIsRenaming(false);
        if (isApplication) {
            androidApi
                .updateAndroidApplication(configuration?.id, { name: newName })
                .then(noop)
                .catch(() => {
                    setIsRenaming(true);
                });
        } else {
            androidApi
                .updateAndroidConfiguration(configuration?.id, { name: newName })
                .then(noop)
                .catch(() => {
                    setIsRenaming(true);
                });
        }
    };

    const closeMenu = () => {
        setActionsDialogProps((c) => ({
            ...c,
            open: false,
        }));
    };

    const onCancelDelete = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onDelete = () => {
        if (deleteState.open) {
            setDeleteState({
                open: true,
                isProcessing: true,
            });

            const errorHandling = () => {
                setDeleteState({
                    open: true,
                    anErrorOccurred: true,
                });
            };

            if (isApplication) {
                androidApi
                    .deleteApplication(configurationId)
                    .then(() => {
                        navigate.to('android/application/list', true);
                    })
                    .catch(errorHandling);
            } else {
                androidApi
                    .deleteAndroidConfiguration(configurationId)
                    .then(() => {
                        navigate.to('android/configuration/list', true);
                    })
                    .catch(errorHandling);
            }
        } else {
            closeMenu();
            setDeleteState({
                open: true,
            });
        }
    };

    const onActionClick = (action: any) => {
        closeMenu();
        switch (action.id) {
            case renameAction.id:
                onRename();
                break;
            case deleteAction.id:
                onDelete();
                break;
        }
        return;
    };

    useAndroidApplicationOrConfigurationSocket(
        isApplication,
        configurationId,
        useCallback<TSocketMethod>(({ fullDocument, documentId }, { updateOperation, deleteOperation }) => {
            if (updateOperation) {
                if (isApplication) {
                    const streamlinedApplicationDocument = streamlineApplicationDocument(fullDocument);
                    setConfiguration({
                        ...streamlinedApplicationDocument,
                        id: documentId,
                    });
                } else {
                    setConfiguration({
                        ...fullDocument,
                        id: documentId,
                    });
                }
            } else if (deleteOperation) {
                if (isApplication) {
                    navigate.to('android/application/list', true);
                } else {
                    navigate.to('android/configuration/list', true);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
    );

    useEffect(() => {
        if (isApplication) {
            androidApi
                .getSingleApplication(configurationId)
                .then(setConfiguration)
                .catch(() => {
                    setErrorMessage('Could not get application');
                });
        } else {
            androidApi
                .getAndroidConfiguration(configurationId)
                .then(setConfiguration)
                .catch(() => {
                    setErrorMessage('Could not get configuration');
                });
        }
    }, [androidApi, configurationId, isApplication]);

    const isEndpointTab = window.location.search.includes('tab=endpoints');
    const isGroupsTab = window.location.search.includes('tab=groups');

    if (configuration) {
        return (
            <Page
                title={
                    isEndpointTab
                        ? isApplication
                            ? 'Application · Endpoints'
                            : 'Configuration · Endpoints'
                        : isGroupsTab
                        ? isApplication
                            ? 'Application · Groups'
                            : 'Configuration · Groups'
                        : isApplication
                        ? 'Application'
                        : 'Configuration'
                }
            >
                <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-auto-1fr tw-px-4 tw-py-4">
                    <div>
                        <LayoutRow verticalAlign="center">
                            <div>
                                {isApplication &&
                                    (configuration.iconUrl ? (
                                        <Avatar
                                            src={configuration.iconUrl}
                                            alt={configuration.name}
                                            variant="rounded"
                                            className="tw-mr-4 tw-h-10 tw-w-10"
                                        />
                                    ) : (
                                        <Icon
                                            type="app"
                                            className="tw-mr-4 tw-h-10 tw-w-10"
                                        />
                                    ))}
                            </div>
                            <div>
                                <PageTitle
                                    category={isApplication ? 'Application' : 'Configuration'}
                                    description={
                                        isApplication ? 'Assign groups and endpoints to the application' : 'Assign groups and endpoints to the configuration'
                                    }
                                >
                                    <span>{configuration.name}</span>
                                    {isApplication && (
                                        <span>
                                            &nbsp;&middot;&nbsp;
                                            {configuration.installTypeName}
                                        </span>
                                    )}
                                </PageTitle>
                            </div>
                            <div className="tw-ml-4">
                                <AndroidApplicationManagedConfigurationController application={configuration} />
                                <IconButton onClick={openMenu}>
                                    <Icon type="moreVert" />
                                </IconButton>

                                {/* @ts-ignore - ActionsDialog is not typed */}
                                <ActionsDialog
                                    {...actionsDialogProps}
                                    onClose={closeMenu}
                                    // @ts-ignore - ActionsDialog is not typed
                                    onActionClick={onActionClick}
                                />
                                <RenamingDialog
                                    onCancel={onRename}
                                    onSubmit={onRenameSubmit}
                                    currentName={configuration.name}
                                    open={isRenaming}
                                />
                                {/* @ts-ignore - ActionsDialog is not typed */}
                                <ConfirmDialog
                                    onConfirm={onDelete}
                                    onCancel={onCancelDelete}
                                    open={deleteState.open}
                                    title={`Delete ${configuration.name}?`}
                                    isProcessing={deleteState.isProcessing}
                                >
                                    {deleteState.anErrorOccurred && (
                                        <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{configuration.name}". Please try again.</b>
                                    )}
                                </ConfirmDialog>
                            </div>
                        </LayoutRow>
                    </div>
                    <div className="tw-mb-2 tw-py-2">
                        <Tabs
                            value={activeTab}
                            onChange={onTabChange}
                            onRails
                        >
                            <Tab
                                label="Summary"
                                value="summary"
                                disableRipple
                                className={tabClassName}
                            />
                            <Tab
                                label="Endpoints"
                                value="endpoints"
                                disableRipple
                                className={tabClassName}
                            />
                            <Tab
                                label="Groups"
                                value="groups"
                                disableRipple
                                className={tabClassName}
                            />
                        </Tabs>
                    </div>

                    <div className="tw-overflow-hidden">
                        {activeTab === 'summary' &&
                            (isApplication ? (
                                <AndroidApplicationSummary
                                    isApplication={isApplication}
                                    configuration={configuration}
                                />
                            ) : (
                                <AndroidConfigurationSummary
                                    // @ts-ignore - AndroidConfigurationSummary is not typed
                                    isApplication={isApplication}
                                    configuration={configuration}
                                />
                            ))}

                        {activeTab === 'endpoints' && (
                            <EndpointMembershipManagement
                                isApplication={isApplication}
                                configuration={configuration}
                            />
                        )}
                        {activeTab === 'groups' && (
                            <GroupMembershipManagement
                                isApplication={isApplication}
                                configuration={configuration}
                            />
                        )}
                    </div>
                </div>
            </Page>
        );
    }
    return <LayoutCentered>{errorMessage ? <h2>{errorMessage}</h2> : <Loading />}</LayoutCentered>;
};

export type EndpointMembershipManagementProps = {
    configuration: TAndroidConfigurationWithInstallTypeName;
    isApplication: boolean;
};

const EndpointMembershipManagement: React.FC<EndpointMembershipManagementProps> = ({ configuration, isApplication }) => {
    const androidApi = useAndroidApi();
    const [items, setItems] = useState([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{ loading?: boolean; savingChanges?: boolean; errorMessage?: string }>({
        loading: true,
    });
    const [viewMode, setViewMode] = useState(true);

    useEffect(() => {
        androidApi
            .getDevices({ pageSize: 99999 })
            .then((endpoints) => {
                setItems(
                    endpoints.content.map((endpoint: any) => ({
                        ...endpoint,
                        applied: configuration.endpointRefIds.includes(endpoint.id),
                    }))
                );
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get endpoints');
            });
    }, [androidApi, configuration, isApplication]);

    const onAdd = (endpointIds: any[]) => {
        setTransferListProps({
            savingChanges: true,
        });

        const errorHandling = (response: any) => {
            console.log(response);
            setTransferListProps({
                savingChanges: false,
                errorMessage: `Could not apply ${pluralize('endpoint', endpointIds.length)}`,
            });
        };

        if (isApplication) {
            androidApi
                .addEndpointsToApplication(configuration.id, endpointIds)
                .then(() => {
                    // Let the socket notification on configuration change handle the update.
                })
                .catch(errorHandling);
        } else {
            androidApi
                .addEndpointsToConfiguration(configuration.id, endpointIds)
                .then(() => {
                    // Let the socket notification on configuration change handle the update.
                })
                .catch(errorHandling);
        }
    };

    const onRemove = (endpointIds: any[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        const errorHandling = (response: any) => {
            setTransferListProps({
                savingChanges: false,
                errorMessage: `Could not remove ${pluralize('endpoint', endpointIds.length)}`,
            });
        };
        if (isApplication) {
            androidApi
                .removeEndpointsFromApplication(configuration.id, endpointIds)
                .then(() => {
                    // Let the socket notification on configuration change handle the update.
                })
                .catch(errorHandling);
        } else {
            androidApi
                .removeEndpointsFromConfiguration(configuration.id, endpointIds)
                .then(() => {
                    // Let the socket notification on configuration change handle the update.
                })
                .catch(errorHandling);
        }
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                // @ts-ignore - CapaOneLink is not typed
                <CapaOneLink to={`android/device/${rowData.id}/${isApplication ? 'applications' : 'groups'}`}>
                    <div>
                        <TwoLineCellRenderer
                            main={rowData.name}
                            callToAction
                            secondary={rowData.androidData.hardwareInfo?.model}
                        />
                    </div>
                </CapaOneLink>
            );
        }
        return (
            <TwoLineCellRenderer
                main={rowData.name}
                callToAction
                secondary={rowData.androidData.hardwareInfo?.model}
            />
        );
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }

    return (
        <TransferList
            // @ts-ignore - TransferList is not typed
            items={items}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="endpoint"
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={items.filter((i: any) => !i.applied).length}
            totalRowCountAssigned={items.filter((i: any) => i.applied === true).length}
            {...transferListProps}
        />
    );
};

export type GroupMembershipManagementProps = {
    configuration: TAndroidConfigurationWithInstallTypeName;
    isApplication: boolean;
};

const GroupMembershipManagement: React.FC<GroupMembershipManagementProps> = ({ configuration, isApplication }) => {
    const managementApi = useManagementApi();
    const [items, setItems] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [transferListProps, setTransferListProps] = useState<{ loading?: boolean; savingChanges?: boolean; errorMessage?: string }>({
        loading: true,
    });
    const [viewMode, setViewMode] = useState(true);

    useEffect(() => {
        managementApi
            .getGroups()
            .then((groupsResponse) => {
                groupsResponse.sort(sortingFunction);
                if (isApplication) {
                    setItems(
                        groupsResponse.map((group: any) => ({
                            ...group,
                            applied: group.applicationRefs.some(({ refId }: { refId: any }) => refId === configuration.id),
                        }))
                    );
                } else {
                    setItems(
                        groupsResponse.map((group: any) => ({
                            ...group,
                            applied: group.configurationRefs.some(({ refId }: { refId: any }) => refId === configuration.id),
                        }))
                    );
                }
                setTransferListProps({});
            })
            .catch((errorResponse) => {
                console.log(errorResponse);
                setErrorMessage('Could not get groups');
            });
    }, [managementApi, configuration, isApplication]);

    useGroupsSocket(
        useCallback<TSocketMethod>(
            ({ updateDescription, documentId, fullDocument }, { updateOperation, insertOperation, deleteOperation }) => {
                if (updateOperation) {
                    const key = isApplication ? 'applicationRefs' : 'configurationRefs';
                    setItems((currentItems) => {
                        return currentItems
                            .map((group: any) => {
                                if (group.id === documentId) {
                                    return {
                                        ...fullDocument,
                                        id: documentId,
                                        applied: fullDocument[key].some(({ refId }: { refId: any }) => refId === configuration.id),
                                    };
                                }
                                return group;
                            })
                            .sort(sortingFunction);
                    });
                } else if (insertOperation) {
                    setItems((currentItems) => {
                        return [
                            ...currentItems,
                            {
                                ...fullDocument,
                                id: documentId,
                                applied: false,
                            },
                        ].sort(sortingFunction);
                    });
                } else if (deleteOperation) {
                    setItems((currentItems) => {
                        return currentItems.filter((group) => group.id !== documentId);
                    });
                }
            },
            [configuration, isApplication]
        )
    );

    const onAdd = (groupIds: any[]) => {
        setTransferListProps({
            savingChanges: true,
        });

        const errorHandling = (response: any) => {
            setTransferListProps({
                savingChanges: false,
                errorMessage: `Could not apply ${pluralize('group', groupIds.length)}`,
            });
        };

        if (isApplication) {
            managementApi
                .addApplicationToGroups(configuration.id, groupIds, 'android')
                .then(() => {
                    setTransferListProps({});
                })
                .catch(errorHandling);
        } else {
            managementApi
                .addAndroidConfigurationToGroups(configuration.id, groupIds)
                .then(() => {
                    setTransferListProps({});
                })
                .catch(errorHandling);
        }
    };

    const onRemove = (groupIds: any[]) => {
        setTransferListProps({
            savingChanges: true,
        });
        const errorHandling = (response: any) => {
            console.log(response);
            setTransferListProps({
                savingChanges: false,
                errorMessage: `Could not remove ${pluralize('group', groupIds.length)}`,
            });
        };
        if (isApplication) {
            managementApi
                .removeApplicationFromGroups(configuration.id, groupIds, 'android')
                .then(() => {
                    setTransferListProps({});
                })
                .catch(errorHandling);

            /* androidApi.deleteGroupApplicationMembership(groupIds, [configuration.id]).then(() => {
                setTransferListProps({});
            }).catch(errorHandling); */
        } else {
            managementApi
                .removeConfigurationFromGroups(configuration.id, groupIds, 'android')
                .then(() => {
                    setTransferListProps({});
                })
                .catch(errorHandling);

            /* androidApi.deleteGroupConfigurationMembership(groupIds, [configuration.id]).then(() => {
                setTransferListProps({});
            }).catch(errorHandling); */
        }
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                // @ts-ignore - CapaOneLink is not typed
                <CapaOneLink to={`management/group/${rowData.id}/android/${isApplication ? 'applications' : 'configurations'}`}>
                    <b>{rowData.name}</b>
                </CapaOneLink>
            );
        }
        return <b>{rowData.name}</b>;
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }

    return (
        <TransferList
            // @ts-ignore - TransferList is not typed
            items={items}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="group"
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={items.filter((i) => !i.applied).length}
            totalRowCountAssigned={items.filter((i) => i.applied === true).length}
            {...transferListProps}
        />
    );
};

export { AndroidApplicationAndConfigurationMembershipManagement };
