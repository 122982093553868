import { title } from 'process';
import { ApplicationInstallTypeEnum } from './android-management-enums';

const examplePackageName = 'com.android.chrome';
const exampleUrl = `https://play.google.com/store/apps/details?id=${examplePackageName}`;
// See https://developers.google.com/android/management/reference/rest/v1/enterprises.policies#ApplicationPolicy
export const AndroidApplication = {
    schema: {
        title: 'Application',
        type: 'object',
        schemaId: 'application',
        properties: {
            applications: {
                title: 'Applications',
                description: 'Application settings for device.',
                type: 'array',
                items: {
                    title: 'Set up Google Play application',
                    description: 'Configure how to manage this Android app from the Google Play Store',
                    type: 'object',
                    properties: {
                        packageName: {
                            title: 'Application ID',
                            description: `The application ID is in the URL of the Google Play store page.\nFor example, the URL of the <strong><a href="${exampleUrl}" target='google_play_store'>Google Chrome</strong></a> app page is <a class='tw-texts-blue-500 tw-font-semibold tw-underlinetw-underline-offset-4' href='${exampleUrl}' target='google_play_store'>${exampleUrl}</a> and its package name is <strong class='tw-bg-ambser-400 tw-underlinetw-decoration-pink-500tw-underline-offset-4tw-decoration-2'>${examplePackageName}</strong>`,
                            type: 'string',
                            required: true,
                            disabled: true,
                        },
                        vendor: {
                            title: 'Vendor',
                            description: 'The app’s vendor.',
                            type: 'string',
                            required: true,
                            disabled: true,
                        },
                        installType: {
                            title: 'Install type',
                            description:
                                'The type of installation to perform. When choosing <strong>Preinstalled</strong> the application will get installed on the device if the user have not deleted the application from the device at some earlier point. If this is the case the application will be available for download in store.',
                            type: 'string',
                            options: Object.entries(ApplicationInstallTypeEnum).map(([id, name]) => ({ id, name })),
                            required: true,
                        },
                        defaultPermissionPolicy: {
                            title: 'Default permission policy',
                            description: 'The default policy for all permissions requested by the app.',
                            help: 'Note: If specified, this overrides the policy-level defaultPermissionPolicy which applies to all apps. It does not override the permissionGrants which applies to all apps.',
                            type: 'string',
                            options: [
                                {
                                    id: 'PROMPT',
                                    name: 'Prompt the user to grant a permission',
                                },
                                {
                                    id: 'GRANT',
                                    name: 'Automatically grant a permission',
                                },
                                {
                                    id: 'DENY',
                                    name: 'Automatically deny a permission',
                                },
                            ],
                        },
                        disabled: {
                            title: 'Disabled',
                            description: 'Whether the app is disabled.',
                            note: 'When disabled, the app data is still preserved.',
                            type: 'boolean',
                        },
                        minimumVersionCode: {
                            title: 'Minimum version code',
                            description: 'The minimum version of the app that runs on the device.',
                            help: 'If set, the device attempts to update the app to at least this version code. If the app is not up-to-date, the device will contain a NonComplianceDetail with nonComplianceReason set to APP_NOT_UPDATED. The app must already be published to Google Play with a version code greater than or equal to this value. At most 20 apps may specify a minimum version code per policy.',
                            type: 'number',
                        },
                        delegatedScopes: {
                            title: 'Delegated scopes',
                            description: 'The scopes delegated to the app from Android Device Policy.',
                            type: 'string',
                            options: [
                                {
                                    id: 'CERT_INSTALL',
                                    name: 'Certificate installation and management',
                                },
                                {
                                    id: 'MANAGED_CONFIGURATIONS',
                                    name: 'Managed configurations management',
                                },
                                {
                                    id: 'BLOCK_UNINSTALL',
                                    name: 'Block uninstallation',
                                },
                                {
                                    id: 'PERMISSION_GRANT',
                                    name: 'Permission policy and permission grant state',
                                },
                                {
                                    id: 'PACKAGE_ACCESS',
                                    name: 'Package access state',
                                },
                                {
                                    id: 'ENABLE_SYSTEM_APP',
                                    name: 'Enabling system apps',
                                },
                                {
                                    id: 'NETWORK_ACTIVITY_LOGS',
                                    name: 'Network activity logs',
                                },
                                {
                                    id: 'SECURITY_LOGS',
                                    name: 'Security logs',
                                },
                                {
                                    id: 'CERT_SELECTION',
                                    name: 'Certificate selection',
                                },
                            ],
                        },
                        /*   Removed as it seems like an R&D feature which only will confuse the users
                        accessibleTrackIds: {
                            title: "Accessible track IDs",
                            description: "List of the app’s track IDs that a device belonging to the enterprise can access. If the list contains multiple track IDs, devices receive the latest version among all accessible tracks. If the list contains no track IDs, devices only have access to the app’s production track.",
                            type: "array",
                            items: {
                                type: "string"
                            }
                        },
                        */
                        connectedWorkAndPersonalApp: {
                            title: 'Connected work and personal app',
                            description:
                                "Controls whether the app can communicate with itself across a device's work and personal profiles, subject to user consent.",
                            type: 'string',
                            options: [
                                {
                                    id: 'CONNECTED_WORK_AND_PERSONAL_APP_DISALLOWED',
                                    name: 'Prevent the app from communicating cross-profile',
                                },
                                {
                                    id: 'CONNECTED_WORK_AND_PERSONAL_APP_ALLOWED',
                                    name: 'Allow the app to communicate across profiles after receiving user consent',
                                },
                            ],
                        },
                        autoUpdateMode: {
                            title: 'Auto update mode',
                            description: 'Controls the auto-update mode for the app.',
                            type: 'string',
                            options: [
                                {
                                    id: 'AUTO_UPDATE_DEFAULT',
                                    name: 'Low priority',
                                },
                                {
                                    id: 'AUTO_UPDATE_POSTPONED',
                                    name: 'Postponed',
                                },
                                {
                                    id: 'AUTO_UPDATE_HIGH_PRIORITY',
                                    name: 'High priority',
                                },
                            ],
                        },
                        alwaysOnVpnLockdownExemption: {
                            title: 'Always on VPN lockdown exemption',
                            description:
                                "Specifies whether the app is allowed networking when the VPN is not connected and alwaysOnVpnPackage.lockdownEnabled is enabled. If set to 'Respect always-on VPN lockdown setting', the app is not allowed networking, and if set to 'Exempt from the always-on VPN lockdown setting', the app is allowed networking.",
                            help: 'Note: Only supported on devices running Android 10 and above. If this is not supported by the device, the device will contain a NonComplianceDetail with nonComplianceReason set to API_LEVEL and a fieldPath. If this is not applicable to the app, the device will contain a NonComplianceDetail with nonComplianceReason set to UNSUPPORTED and a fieldPath. The fieldPath is set to applications[i].alwaysOnVpnLockdownExemption, where i is the index of the package in the applications policy.',
                            type: 'string',
                            options: [
                                {
                                    id: 'VPN_LOCKDOWN_ENFORCED',
                                    name: 'Respect always-on VPN lockdown setting',
                                },
                                {
                                    id: 'VPN_LOCKDOWN_EXEMPTION',
                                    name: 'Exempt from the always-on VPN lockdown setting',
                                },
                            ],
                        },
                        permissionGrants: {
                            title: 'Permission grants',
                            description: 'Explicit permission grants or denials for the app.',
                            help: 'Note: These values override the defaultPermissionPolicy and permissionGrants which apply to all apps.',
                            type: 'object',
                            properties: {
                                policy: {
                                    title: 'Policy',
                                    description: 'The policy for granting the permission.',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'PROMPT',
                                            name: 'Prompt the user to grant a permission',
                                        },
                                        {
                                            id: 'GRANT',
                                            name: 'Automatically grant a permission',
                                        },
                                        {
                                            id: 'DENY',
                                            name: 'Automatically deny a permission',
                                        },
                                    ],
                                },
                                permission: {
                                    title: 'Permission',
                                    description: 'The Android permission or group, e.g. android.permission.READ_CALENDAR or android.permission_group.CALENDAR.',
                                    type: 'string',
                                    required: (siblings: any) => {
                                        if (siblings.policy) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                    shouldRender: (siblings: any) => {
                                        if (siblings.policy) {
                                            return true;
                                        }
                                        delete siblings.permission;
                                        return false;
                                    },
                                },
                            },
                        },
                        managedConfigurationTemplate: {
                            title: 'Managed configuration template',
                            description: 'The managed configurations template for the app, saved from the managed configurations iframe.',
                            help: 'This field is ignored if Managed Configuration is set.',
                            type: 'object',
                            properties: {
                                templateId: {
                                    title: 'Template ID',
                                    description: 'The ID of the managed configurations template.',
                                    type: 'string',
                                },
                            },
                            shouldRender: () => false,
                        },
                        extensionConfig: {
                            title: 'Extension config',
                            description:
                                'Configuration to enable this app as an extension app, with the capability of interacting with Android Device Policy offline.',
                            help: 'Note: This field can be set for at most one app.',
                            type: 'object',
                            properties: {
                                signingKeyFingerprintsSha256: {
                                    title: 'Signing key fingerprints SHA256',
                                    description:
                                        'Hex-encoded SHA256 hash of the signing certificate of the extension app. Only hexadecimal string representations of 64 characters are valid. If not specified, the signature for the corresponding package name is obtained from the Play Store instead. If this list is empty, the signature of the extension app on the device must match the signature obtained from the Play Store for the app to be able to communicate with Android Device Policy. If this list is not empty, the signature of the extension app on the device must match one of the entries in this list for the app to be able to communicate with Android Device Policy.',
                                    help: 'Note: In production use cases, it is recommended to leave this empty.',
                                    type: 'array',
                                    items: {
                                        type: 'string',
                                    },
                                },
                                notificationReceiver: {
                                    title: 'Notification receiver',
                                    description:
                                        'Fully qualified class name of the receiver service class for Android Device Policy to notify the extension app of any local command status updates.',
                                    type: 'string',
                                },
                            },
                        },
                        workProfileWidgets: {
                            title: 'Work Profile Widgets',
                            description: 'Controls if a work profile application is allowed to add widgets to the home screen.',
                            type: 'string',
                            options: [
                                {
                                    id: 'WORK_PROFILE_WIDGETS_ALLOWED',
                                    name: 'Allow work profile widgets',
                                },
                                {
                                    id: 'WORK_PROFILE_WIDGETS_DISALLOWED',
                                    name: 'Disallow work profile widgets',
                                },
                            ],
                        },
                        credentialProviderPolicy: {
                            title: 'Credential Provider Policy',
                            description: 'Whether the app is allowed to act as a credential provider on Android 14 and above.',
                            type: 'string',
                            options: [
                                {
                                    id: 'CREDENTIAL_PROVIDER_ALLOWED',
                                    name: 'Allow',
                                },
                            ],
                        },
                        installConstraint: {
                            title: 'Install Constraint',
                            description:
                                'Amongst apps with InstallType set to: FORCE_INSTALLED or PREINSTALLED this defines a set of restrictions for the app installation. At least one of the fields must be set. When multiple fields are set, then all the constraints need to be satisfied for the app to be installed.',
                            type: 'object',
                            properties: {
                                networkTypeConstraint: {
                                    title: 'Network Type Constraint',
                                    description: 'Network type constraint',
                                    setUrl: 'NetworkTypeConstraint',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'INSTALL_ON_ANY_NETWORK',
                                            name: 'Any active networks (Wi-Fi, cellular, etc.)',
                                        },
                                        {
                                            id: 'INSTALL_ONLY_ON_UNMETERED_NETWORK',
                                            name: 'Any unmetered network (e.g. Wi-FI)',
                                        },
                                    ],
                                },
                                chargingConstraint: {
                                    title: 'Charging Constraint',
                                    description: 'Charging constraint.',
                                    setUrl: 'ChargingConstraint',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'CHARGING_NOT_REQUIRED',
                                            name: `Device doesn't have to be charging`,
                                        },
                                        {
                                            id: 'INSTALL_ONLY_WHEN_CHARGING',
                                            name: 'Only when charging',
                                        },
                                    ],
                                },
                                deviceIdleConstraint: {
                                    title: 'Device Idle Constraint',
                                    description: 'Device idle state constraint.',
                                    setUrl: 'DeviceIdleConstraint',
                                    type: 'string',
                                    options: [
                                        {
                                            id: 'DEVICE_IDLE_NOT_REQUIRED',
                                            name: `Device doesn't have to be idle, app can be installed while the user is interacting with the device`,
                                        },
                                        {
                                            id: 'INSTALL_ONLY_WHEN_DEVICE_IDLE',
                                            name: 'Device has to be idle',
                                        },
                                    ],
                                },
                            },
                        },
                        installPriority: {
                            title: 'Install Priority',
                            description:
                                'Optional. Amongst apps with installType set to: FORCE_INSTALLED PREINSTALLED this controls the relative priority of installation.',
                            help: 'A value of 0 (default) means this app has no priority over other apps. For values between 1 and 10,000, a lower value means a higher priority. Values outside of the range 0 to 10,000 inclusive are rejected.',
                            setUrl: 'FIELDS.install_priority',
                            type: 'number',
                        },
                    },
                },
            },
        },
    },
};
