import { BUTTON } from '@capasystems/constants';
import {
    Avatar,
    Button,
    Checkbox,
    Column,
    CopyTextButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Ellipsis,
    Icon,
    IconButton,
    Input,
    LayoutCenter,
    LayoutCentered,
    LayoutColumn,
    LayoutRow,
    Loading,
    Padding,
    Paper,
    Select,
    Snackbar,
    SnackbarContent,
    Tab,
    Tabs,
    Tooltip,
    VirtualizedTable,
    virtualizedTableColumnPropTypes,
} from '@capasystems/ui';
import { Url } from '@capasystems/utils';
import { usePackageSocket } from '@thirdparty/ui';
import { useEffect, useMemo, useState } from 'react';
import { useApi } from '../hooks/useApi/useApi';
import { BooleanBadge, TwoLineCellRenderer } from '../thirdparty-components/thirdparty-components';
import ChipInput from './chipInput';
import { PackageMatchChipInput } from './packageMatchChipInput';

export default () => {
    //#region State
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initializedProgress, setInitializedProgress] = useState(0);
    const [appSearch, setAppSearch] = useState(Url.getString('appSearch', ''));
    const [versionSearch, setVersionSearch] = useState(Url.getString('versionSearch', ''));
    const [selectedApplicationId, setSelectedApplicationId] = useState(null);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [editApplicationId, setEditApplicationId] = useState(null);
    const [editVersionId, setEditVersionId] = useState(null);
    const [error, setError] = useState(null);
    const [packageTestData, setPackageTestData] = useState([]);
    const [applicationTestResult, setApplicationTestResult] = useState([]);
    const [logs, setLogs] = useState([]);
    const [logsDialog, setLogsDialog] = useState(false);
    const [tabId, setTabId] = useState('Install');
    const [singleLogDialogOpen, setSingleLogDialogOpen] = useState(false);
    const [singleLog, setSingleLog] = useState();
    const [logDialogTitle, setLogsDialogTitle] = useState('');
    const [tabs, setTabs] = useState([]);
    const [testsInQueue, setTestsInQueue] = useState([]);
    const [queueDialog, setQueueDialog] = useState(false);
    //#endregion

    const availableTabs = [];

    const selectedApplication = useMemo(() => packages.find((p) => p.applicationId === selectedApplicationId), [packages, selectedApplicationId]);

    const api = useApi();

    const getApplications = async () => {
        setLoading(true);
        const promises = [api.getPackageTestResults(), api.getApplications({ withTesting: true })];
        Promise.all(promises)
            .then(([packageTestResultsResponse, applicationsResponse]) => {
                setPackageTestData(packageTestResultsResponse);
                setPackages(applicationsResponse.content);
            })
            .catch((errorResponse) => {
                setError(errorResponse.message);
            })
            .finally(() => {
                setLoading(false);
                setInitializedProgress(50);
            });
    };

    useEffect(() => {
        getApplications();
    }, []);

    usePackageSocket(({ fullDocument }) => {
        const { ...changes } = fullDocument;
        // TODO: Not only test data but also new imports etc
        if (selectedApplicationId) {
            const selectedPackage = packages.find((p) => p.applicationId === selectedApplicationId);

            const version = selectedPackage.versions.find((v) => v.packageId === changes.packageId);

            if (!changes.hasFailed && !changes.success) {
                version.testResult = 'queued';
            } else if (changes.hasFailed) {
                version.testResult = 'failed';
            } else if (changes.success) {
                version.testResult = 'success';
            } else {
                version.testResult = 'no test';
            }
            setPackages([...packages]);
        } else {
            const testPackage = packageTestData.find((p) => p.applicationId === changes.applicationId);
            testPackage.hasFailed = changes.hasFailed;
            testPackage.success = changes.success;
            testPackage.numTests = changes.numTests;
            setPackageTestData(packageTestData);
        }
    });

    const selectApplication = ({ rowData }) => {
        const testResult = packageTestData.filter((a) => {
            return rowData.versions.some((b) => {
                return b.packageId === a.packageId;
            });
        });
        rowData.versions.forEach((version) => {
            if (testResult.length === 0) {
                version.testResult = 'no test';
            } else {
                const pVersion = testResult.find((p) => p.packageId === version.packageId);
                if (!pVersion) {
                    version.testResult = 'no test';
                } else if (!pVersion.hasFailed && !pVersion.success) {
                    version.testResult = 'queued';
                } else if (pVersion.hasFailed) {
                    version.testResult = 'failed';
                } else if (pVersion.success) {
                    version.testResult = 'success';
                }
            }
        });
        setApplicationTestResult(testResult.reverse());
        setSelectedApplicationId(rowData.applicationId);
        Url.set('applicationId', rowData.applicationId);
    };

    const onSearch = (e) => {
        const search = e.target.value;
        if (selectedApplication) {
            setVersionSearch(search);
            Url.set('versionSearch', search);
        } else {
            setAppSearch(search);
            Url.set('appSearch', search);
        }
    };

    const matchTestResult = (packageId) => {
        const testing = applicationTestResult.find((p) => p.packageId === packageId);
        if (!testing || (!testing.hasFailed && !testing.success)) {
            return 'no test';
        }
        if (testing.hasFailed) {
            return 'failed';
        }
        return 'success';
    };

    const manualTestPackage = (selectedPackage) => {
        api.startPackageManualTest(selectedPackage.applicationId, selectedPackage).then(() => {
            api.getPackageTestResults().then((data) => {
                setPackageTestData(data);
            });
        });
    };

    const closeLogsDialog = () => {
        setLogsDialog(false);
        setLogsDialogTitle('');
    };

    const openLogsDialog = (rowData) => {
        api.getSinglePackageTestResults(rowData.packageId).then((data) => {
            let logs = [];
            if (data && data.length !== 0) {
                logs = data.reverse()[0].logs;
                logs?.forEach((log) => {
                    if (!availableTabs.find((tab) => tab.id === log.type)) {
                        availableTabs.push({ name: log.type, id: log.type });
                    }
                });
            }
            setLogsDialogTitle(selectedApplication.application + ' ' + rowData.version);
            setTabs(availableTabs);
            setLogs(logs);
            setLogsDialog(true);
        });
    };

    const onTabChange = (e, tabIndex) => {
        setTabId(tabIndex);
    };

    const download = (filename, text) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    const viewAllLogs = () => {
        let combinedLog = [];
        logs.forEach((log, index) => {
            combinedLog = combinedLog.concat(log.content);
            if (index !== logs.length - 1) {
                combinedLog.push('\r\n----------NEW FILE----------\r\n\r\n');
            }
        });
        setSingleLog({ folderName: logDialogTitle, fileName: 'all logs', content: combinedLog });
        setSingleLogDialogOpen(true);
    };

    const testQueue = () => {
        const queue = packageTestData?.filter((p) => p.hasFailed === false && p.success === false && p.numTests !== 2);
        const formattedQueue = [];
        queue?.forEach((q) => {
            packages?.forEach((p) => {
                const match = p.versions.find((v) => v.packageId === q.packageId);
                if (match) {
                    formattedQueue.push({ application: p.application, version: match.version, language: match.language, packageId: q.packageId });
                }
            });
        });
        setTestsInQueue(formattedQueue.reverse());
        setQueueDialog(true);
    };
    if (initializedProgress < 100) {
        if (initializedProgress > 0) {
            const applicationId = Url.getNumber('applicationId');
            if (applicationId) {
                const foundApplication = packages.find((p) => p.applicationId === applicationId);
                if (foundApplication) {
                    selectApplication({ rowData: foundApplication });
                }
            }
            setInitializedProgress(100);
        }
        return (
            <div className="tw-h-full">
                <LayoutCentered>
                    <Loading />
                </LayoutCentered>
            </div>
        );
    }
    return (
        <>
            <Padding className="tw-h-full">
                <div className="tw-grid-cols-1fr tw-grid tw-h-full tw-grid-rows-auto-1fr tw-gap-4">
                    <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
                        <div className="tw-flex tw-w-full tw-flex-row tw-items-center">
                            {selectedApplication && (
                                <>
                                    <Button
                                        onClick={() => {
                                            setSelectedApplicationId(null);
                                            Url.set('applicationId', null);
                                            Url.set('versionSearch', null);
                                            setVersionSearch('');
                                        }}
                                        variant={BUTTON.TEXT}
                                        color={BUTTON.PRIMARY}
                                    >
                                        <Icon type="arrowLeft" />
                                    </Button>
                                    <p className="tw-text-lg tw-font-bold">{selectedApplication?.application}</p>
                                </>
                            )}
                        </div>
                        <Input
                            onChange={onSearch}
                            placeholder="Search"
                            rounded
                            light
                            callToAction
                            type="search"
                            autoFocus
                            value={selectedApplication ? versionSearch : appSearch}
                        />
                        <div className="tw-flex tw-w-full tw-justify-end">
                            <Button
                                onClick={() => testQueue()}
                                variant={BUTTON.RAISED}
                                color={BUTTON.PRIMARY}
                            >
                                Test Queue
                            </Button>
                            <Button
                                onClick={() => setImportDialogOpen(true)}
                                variant={BUTTON.RAISED}
                                color={BUTTON.PRIMARY}
                            >
                                Import
                            </Button>
                        </div>
                    </div>
                    <Paper className="tw-h-full">
                        {!selectedApplication ? (
                            <ApplicationList
                                selectApplication={selectApplication}
                                applications={packages.filter((p) => p.application.toLowerCase().includes(appSearch.toLowerCase()))}
                                onEditApplication={(applicationId) => {
                                    setEditApplicationId(applicationId);
                                }}
                                loading={loading}
                            />
                        ) : (
                            <VersionList
                                loading={loading}
                                versions={selectedApplication?.versions?.filter((v) => v.version.toLowerCase().includes(versionSearch.toLocaleLowerCase()))}
                                onChangeStatus={(packageId, status) => {
                                    const newPackages = [...packages];
                                    const application = newPackages.find((p) => p.applicationId === selectedApplicationId);
                                    // optimistic update
                                    const version = application.versions.find((v) => v.packageId === packageId);
                                    version.status = status;
                                    setPackages(newPackages);

                                    // real update
                                    setLoading(true);
                                    api.updatePackageStatus(application.applicationId, packageId, status)
                                        .then(() => getApplications())
                                        .catch((e) => {
                                            setError(e.message);
                                            setLoading(false);
                                        });
                                }}
                                onEditVersion={(packageId) => {
                                    setEditVersionId(packageId);
                                }}
                                matchTestResult={matchTestResult}
                                manualTestPackage={manualTestPackage}
                                openLogsDialog={openLogsDialog}
                                onDeleteVersion={(packageId) => {
                                    setLoading(true);
                                    api.deletePackage(selectedApplicationId, packageId)
                                        .then(() => getApplications())
                                        .catch((e) => {
                                            setError(e.message);
                                            setLoading(false);
                                        });
                                }}
                            />
                        )}
                    </Paper>
                </div>
            </Padding>
            <ImportDialog
                open={importDialogOpen}
                onClose={(updateApps) => {
                    setImportDialogOpen(false);
                    if (updateApps === true) {
                        getApplications();
                    }
                }}
                existingList={packages}
            />
            <EditApplicationDialog
                open={!!editApplicationId}
                onClose={(updateApps = false) => {
                    setEditApplicationId(null);
                    if (updateApps) {
                        getApplications();
                    }
                }}
                application={packages.find((p) => p.applicationId === editApplicationId)}
            />
            <EditVersionDialog
                open={!!editVersionId}
                onClose={(updateApps = false) => {
                    setEditVersionId(null);
                    if (updateApps === true) {
                        getApplications();
                    }
                }}
                version={packages.find((p) => p.applicationId === selectedApplicationId)?.versions.find((v) => v.packageId === editVersionId)}
            />
            <Snackbar
                open={!!error}
                autoHideDuration={1000}
                onClose={() => setError(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SnackbarContent
                    severity="error"
                    message={<b>{error}</b>}
                />
            </Snackbar>
            <Dialog
                open={logsDialog}
                onClose={closeLogsDialog}
            >
                <DialogTitle>
                    {logDialogTitle !== '' && (
                        <p>
                            Logs for{' '}
                            <Icon
                                type="arrowRight"
                                size="small"
                            />{' '}
                            {logDialogTitle}
                        </p>
                    )}
                </DialogTitle>
                <DialogContent style={{ height: '350px' }}>
                    {Object.values(tabs).length === 0 ? (
                        <LayoutCenter>No logs for the selected application version.</LayoutCenter>
                    ) : (
                        <>
                            <Padding
                                left={0}
                                right={0}
                                top={0}
                            >
                                <Tabs
                                    value={tabId}
                                    onChange={onTabChange}
                                    pills
                                    className="tw-mt-4"
                                >
                                    {Object.values(tabs).map((tab) => (
                                        <Tab
                                            value={tab.id}
                                            label={tab.name}
                                            disableRipple
                                        />
                                    ))}
                                </Tabs>
                            </Padding>
                            {Object.values(logs)
                                .filter((l) => l.type === tabId)
                                .map((log) => (
                                    <span>
                                        <LayoutRow
                                            align="space-between"
                                            vertialAlign="center"
                                        >
                                            {log.folderName + ' ' + log.fileName}
                                            <LayoutRow>
                                                <Button
                                                    variant={BUTTON.RAISED}
                                                    color={BUTTON.PRIMARY}
                                                    onClick={() => {
                                                        setSingleLog(log);
                                                        setSingleLogDialogOpen(true);
                                                    }}
                                                >
                                                    View
                                                </Button>
                                                <Button
                                                    variant={BUTTON.RAISED}
                                                    color={BUTTON.PRIMARY}
                                                    download
                                                    onClick={() => download(log.folderName + '_' + log.fileName, log.content.join('\r\n'))}
                                                >
                                                    Download
                                                </Button>
                                            </LayoutRow>
                                        </LayoutRow>
                                        <br></br>
                                    </span>
                                ))}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => viewAllLogs()}
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                    >
                        View all
                    </Button>
                    <Button onClick={() => setLogsDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                size="md"
                open={singleLogDialogOpen}
                onClose={() => setSingleLogDialogOpen(false)}
            >
                <DialogTitle>{singleLog?.folderName + ' ' + singleLog?.fileName}</DialogTitle>
                <DialogContent>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {singleLog?.content.map((line) => (
                            <p>{line}</p>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <CopyTextButton
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                        hideConfirm
                        text={singleLog?.content.join('\r\n') || ''}
                        onClick={() => setSingleLogDialogOpen(false)}
                    >
                        Copy
                    </CopyTextButton>
                    <Button onClick={() => setSingleLogDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={queueDialog}
                onClose={() => setQueueDialog(false)}
                size="lg"
            >
                <DialogTitle>Tests in queue</DialogTitle>
                <DialogContent className="tw-px-0">
                    <div style={{ height: '50vh' }}>
                        <VirtualizedTable
                            items={testsInQueue}
                            entity="test"
                        >
                            <Column
                                dataKey="application"
                                label="Application"
                                minWidth={120}
                            />
                            <Column
                                dataKey="version"
                                label="Version"
                                minWidth={120}
                            />
                            <Column
                                dataKey="language"
                                label="Language"
                                minWidth={120}
                            />
                            <Column
                                dataKey="packageId"
                                label="Package Id"
                                minWidth={120}
                            />
                        </VirtualizedTable>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setQueueDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const ApplicationList = ({ applications, loading, selectApplication, onSearch, onEditApplication }) => (
    <VirtualizedTable
        items={applications}
        isLoading={loading}
        onRowClick={selectApplication}
    >
        <Column
            key="thumbnail"
            dataKey="thumbnail"
            label=""
            minWidth={32}
            maxWidth={32}
            cellRenderer={({ rowData }) => (
                <Avatar
                    alt={rowData.application}
                    src={rowData.thumbnail}
                    variant="square"
                    style={{ width: 32, height: 32 }}
                />
            )}
        />
        <Column
            key="application"
            dataKey="application"
            label="Name"
            minWidth={160}
            cellRenderer={({ cellData, rowData }) => (
                <TwoLineCellRenderer
                    main={cellData}
                    callToAction
                    secondary={rowData.vendor}
                />
            )}
        />
        <Column
            key="category"
            dataKey="category"
            label="Category"
            minWidth={160}
            maxWidth={160}
        />
        <Column
            key="description"
            dataKey="description"
            label="Description"
            minWidth={160}
            disableSort
        />
        <Column
            key="matchName"
            dataKey="matchName"
            label="Match Name"
            minWidth={320}
            disableSort
            cellRenderer={({ cellData }) => <Ellipsis>{cellData.join(', ')}</Ellipsis>}
        />
        <Column
            key="matchVendor"
            dataKey="matchVendor"
            label="Match Vendor"
            minWidth={320}
            disableSort
            cellRenderer={({ cellData }) => <Ellipsis>{cellData.join(', ')}</Ellipsis>}
        />
        <Column
            key="customDetection"
            dataKey="customDetection"
            label="Custom Detection"
            minWidth={120}
            maxWidth={120}
            disableSort
            cellRenderer={({ rowData }) => <BooleanBadge value={rowData.customDetection.enabled} />}
        />
        <Column
            key="edit"
            dataKey="edit"
            label=""
            minWidth={32}
            maxWidth={32}
            justify={virtualizedTableColumnPropTypes.justify.end}
            disableSort
            cellRenderer={({ rowData }) => (
                <Tooltip content="Edit">
                    <IconButton
                        color={BUTTON.PRIMARY}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onEditApplication(rowData.applicationId);
                        }}
                    >
                        <Icon type="edit" />
                    </IconButton>
                </Tooltip>
            )}
        />
    </VirtualizedTable>
);

const VersionList = ({ versions, loading, onChangeStatus, onDeleteVersion, onEditVersion, matchTestResult, manualTestPackage, openLogsDialog }) => (
    <VirtualizedTable
        items={versions}
        isLoading={loading}
    >
        <Column
            dataKey="packageId"
            label="Package Id"
            minWidth={96}
        />
        <Column
            dataKey="version"
            label="Version"
            minWidth={160}
        />
        <Column
            key="matchVersion"
            dataKey="matchVersion"
            label="Match Version"
            minWidth={160}
            disableSort
        />
        <Column
            dataKey="language"
            label="Language"
            minWidth={160}
        />
        <Column
            dataKey="platform"
            label="Platform"
            minWidth={96}
        />
        <Column
            dataKey="testStatus"
            label="Test Status"
            minWidth={80}
            maxWidth={80}
            cellRenderer={({ cellData, rowData }) => (
                <Tooltip
                    content={
                        rowData.testResult === 'success'
                            ? 'Last test for this package version succeded'
                            : rowData.testResult === 'failed'
                            ? 'Last test for this package version failed'
                            : rowData.testResult === 'no test'
                            ? 'No test result for this package version'
                            : 'Test is queued'
                    }
                >
                    <Icon
                        type="circle"
                        color={
                            rowData.testResult === 'success'
                                ? 'success'
                                : rowData.testResult === 'failed'
                                ? 'error'
                                : rowData.testResult === 'no test'
                                ? 'inherit'
                                : 'yellow'
                        }
                        size="small"
                        style={rowData.testResult === 'queued' ? { color: 'yellow' } : {}}
                    />
                </Tooltip>
            )}
        />
        <Column
            dataKey="status"
            label=""
            minWidth={400}
            maxWidth={400}
            justify={virtualizedTableColumnPropTypes.justify.end}
            cellRenderer={({ cellData, rowData }) => (
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-evenly tw-gap-x-2">
                    <div className="tw-w-24">
                        <Select
                            fullWidth
                            onChange={(e) => {
                                const { id: status } = e[0];
                                onChangeStatus(rowData.packageId, status);
                            }}
                            options={[
                                { id: 'Released', name: 'Released' },
                                { id: 'Testing', name: 'Testing' },
                            ]}
                            selectedOptions={[{ id: cellData, name: cellData }]}
                            required
                            light
                        />
                    </div>
                    <div>
                        <Button
                            variant={BUTTON.RAISED}
                            color={BUTTON.PRIMARY}
                            size={BUTTON.SMALL}
                            onClick={() => manualTestPackage(rowData)}
                            disabled={rowData.testResult === 'queued'}
                            noMargin
                        >
                            {rowData.testResult === 'queued' ? 'Test is queued' : 'Test Package'}
                        </Button>
                    </div>
                    <Tooltip content="View logs">
                        <div>
                            <IconButton
                                noMargin
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    openLogsDialog(rowData);
                                }}
                            >
                                <Icon type="dataObject" />
                            </IconButton>
                        </div>
                    </Tooltip>
                    <div>
                        <IconButton
                            noMargin
                            color={BUTTON.PRIMARY}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onEditVersion(rowData.packageId);
                            }}
                        >
                            <Icon type="edit" />
                        </IconButton>
                    </div>
                    <div>
                        <IconButton
                            noMargin
                            color={BUTTON.DANGER}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onDeleteVersion(rowData.packageId);
                            }}
                        >
                            <Icon type="delete" />
                        </IconButton>
                    </div>
                </div>
            )}
        />
    </VirtualizedTable>
);

const checkChipForWildcard = (chip) => {
    if (chip.includes('*') && chip.length < 5) {
        return false;
    } else if (chip.startsWith('*')) {
        return false;
    } else {
        return true;
    }
};

const doPackageMatchTests = (api, application, setMatchNamesTestResult, setMatchNamesTestResultInverted) => {
    api.cancel();
    api.packageMatchTest(application.matchName || [], application.matchTestNames || [])
        .then((result) => {
            setMatchNamesTestResult(result ?? {});
            // Create inverted as well:
            const res = {};
            (application.matchTestNames || []).forEach((testString) => {
                res[testString] = [];
                Object.entries(result).forEach(([matchName, results]) => {
                    if (results.includes(testString)) res[testString].push(matchName);
                });
            });
            setMatchNamesTestResultInverted(res);
        })
        .catch(() => {
            // ignore this
        });
};

const ImportDialog = ({ open, onClose, existingList = [] }) => {
    const api = useApi();

    const [applications, setApplications] = useState([]);
    const [page, setPage] = useState(0);
    const [selectedApplicationId, setSelectedApplicationId] = useState(null);
    const [applicationSearch, setApplicationSearch] = useState('');
    const [selectedVersionId, setSelectedVersionId] = useState(null);
    const [matchNamesTestResult, setMatchNamesTestResult] = useState({});
    const [matchNamesTestResultInverted, setMatchNamesTestResultInverted] = useState({});
    const [error, setError] = useState(null);

    const application = useMemo(() => applications.find((p) => p.applicationId === selectedApplicationId), [applications, selectedApplicationId]);

    const selectedOptions = useMemo(() => (application ? [{ id: application.applicationId, name: application.application }] : []), [application]);

    const selectedVersion = useMemo(() => application?.versions?.find((v) => v.packageId === selectedVersionId), [selectedVersionId, application]);

    // EA IT'S IN THE GAME
    const applicationDoesExist = useMemo(() => !!existingList.find((ea) => ea.applicationId === application?.applicationId), [existingList, application]);

    const getApplications = async () => {
        try {
            const data = await fetch('https://capapacksbeta.capaone.com/api/package');
            const json = await data.json();
            setApplications(json);
        } catch (error) {
            setError(error.message);
        }
    };

    const filterExistingVersions = (version) => {
        const existingVersions = existingList.find((p) => p.applicationId === selectedApplicationId)?.versions?.map((v) => v.packageId);
        return !existingVersions?.find((v) => v === version.packageId);
    };

    useEffect(() => {
        if (open) {
            getApplications();
            setApplicationSearch('');
            setSelectedApplicationId(null);
            setSelectedVersionId(null);
            setPage(0);
        }
    }, [open]);

    useEffect(() => {
        if (application && page === 0 && !applicationDoesExist) {
            doPackageMatchTests(api, application, setMatchNamesTestResult, setMatchNamesTestResultInverted);
        }
    }, [application]);

    const onImport = () => {
        if (applicationDoesExist) {
            selectedVersion.dateAdded = new Date(selectedVersion.dateAdded);
            api.createPackage(application.applicationId, selectedVersion)
                .then(() => {
                    onClose(true);
                })
                .catch((error) => {
                    setError(error.message);
                });
        } else {
            selectedVersion.dateAdded = new Date(selectedVersion.dateAdded);
            application.versions = [selectedVersion];
            api.createApplication(application)
                .then(() => {
                    onClose(true);
                })
                .catch((error) => {
                    setError(error.message);
                });
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
            >
                <DialogTitle>Import</DialogTitle>
                <DialogContent>
                    {page === 0 && (
                        <>
                            <h2>Choose Application</h2>
                            <Select
                                searchTerm={applicationSearch}
                                onSearch={setApplicationSearch}
                                options={applications
                                    .filter((app) => app.application.toLocaleLowerCase().includes(applicationSearch.toLocaleLowerCase()))
                                    .map((a) => ({ id: a.applicationId, name: a.application }))
                                    .sort((a, b) => a.name.localeCompare(b.name))}
                                selectedOptions={selectedOptions}
                                onChange={(e) => {
                                    setSelectedApplicationId(e[0].id);
                                }}
                                required
                            />
                            {application && !applicationDoesExist && (
                                <LayoutColumn>
                                    <PackageMatchChipInput
                                        className="tw-mt-4"
                                        label="Match Name"
                                        value={application.matchName || []}
                                        placeholder="This will be application name if not set"
                                        onAdd={(chip) => {
                                            setApplications(
                                                applications.map((a) => {
                                                    if (a.applicationId === application.applicationId) {
                                                        return {
                                                            ...a,
                                                            matchName: [...(a.matchName || []), chip],
                                                        };
                                                    }
                                                    return a;
                                                })
                                            );
                                        }}
                                        onDelete={(chip) => {
                                            setApplications(
                                                applications.map((a) => {
                                                    if (a.applicationId === application.applicationId) {
                                                        return {
                                                            ...a,
                                                            matchName: a.matchName?.filter((m) => m !== chip) || [],
                                                        };
                                                    }
                                                    return a;
                                                })
                                            );
                                        }}
                                        testResults={matchNamesTestResult}
                                        mode="matchName"
                                        helperText="Can not start with wildcard (*) and must be at least 4 characters long if used"
                                        onBeforeAdd={checkChipForWildcard}
                                    />
                                    <PackageMatchChipInput
                                        className="tw-mt-8"
                                        label="Match Name Test Strings"
                                        value={application.matchTestNames || []}
                                        placeholder="Names to test Match Names on."
                                        onAdd={(chip) => {
                                            setApplications(
                                                applications.map((a) => {
                                                    if (a.applicationId === application.applicationId) {
                                                        return {
                                                            ...a,
                                                            matchTestNames: [...(a.matchTestNames || []), chip],
                                                        };
                                                    }
                                                    return a;
                                                })
                                            );
                                        }}
                                        onDelete={(chip) => {
                                            setApplications(
                                                applications.map((a) => {
                                                    if (a.applicationId === application.applicationId) {
                                                        return {
                                                            ...a,
                                                            matchTestNames: a.matchTestNames?.filter((m) => m !== chip) || [],
                                                        };
                                                    }
                                                    return a;
                                                })
                                            );
                                        }}
                                        testResults={matchNamesTestResultInverted}
                                        mode="testString"
                                    />
                                    {/* <CpeInput
                                        value={application?.cpeString}
                                        onChange={(cpeString) => {
                                            setApplications(
                                                applications.map((a) => {
                                                    if (a.applicationId === application.applicationId) {
                                                        return {
                                                            ...a,
                                                            cpeString,
                                                        };
                                                    }
                                                    return a;
                                                })
                                            );
                                        }}
                                    /> */}
                                    <ChipInput
                                        className="tw-mt-8"
                                        label="Match Vendor"
                                        value={application.matchVendor}
                                        placeholder="This will be vendor name if not set"
                                        onAdd={(chip) =>
                                            setApplications(
                                                applications.map((a) => {
                                                    if (a.applicationId === application.applicationId) {
                                                        return {
                                                            ...a,
                                                            matchVendor: [...a.matchVendor, chip],
                                                        };
                                                    }
                                                    return a;
                                                })
                                            )
                                        }
                                        onDelete={(chip) => {
                                            setApplications(
                                                applications.map((a) => {
                                                    if (a.applicationId === application.applicationId) {
                                                        return {
                                                            ...a,
                                                            matchVendor: a.matchVendor.filter((m) => m !== chip),
                                                        };
                                                    }
                                                    return a;
                                                })
                                            );
                                        }}
                                        style={{ marginRight: 10 }}
                                    />
                                </LayoutColumn>
                            )}
                        </>
                    )}
                    {page === 1 && (
                        <>
                            <h2>Choose Version</h2>
                            <Select
                                options={application.versions
                                    .filter(filterExistingVersions)
                                    .map((a) => ({ id: a.packageId, name: `${a.packageId} - ${a.version} - ${a.language} - ${a.platform}` }))}
                                selectedOptions={
                                    selectedVersion
                                        ? [
                                              {
                                                  id: selectedVersion.packageId,
                                                  name: `${selectedVersion.packageId} - ${selectedVersion.version} - ${selectedVersion.language} - ${selectedVersion.platform}`,
                                              },
                                          ]
                                        : []
                                }
                                onChange={(e) => {
                                    setSelectedVersionId(e[0].id);
                                }}
                                required
                            />
                            {selectedVersion && (
                                <Input
                                    className="tw-mt-4"
                                    label="Match Version"
                                    value={selectedVersion?.matchVersion}
                                    onChange={(e) => {
                                        setApplications(
                                            applications.map((a) => {
                                                if (a.applicationId === application.applicationId) {
                                                    return {
                                                        ...a,
                                                        versions: a.versions.map((v) => {
                                                            if (v.version === selectedVersion.version) {
                                                                return {
                                                                    ...v,
                                                                    matchVersion: e.target.value,
                                                                };
                                                            }
                                                            return v;
                                                        }),
                                                    };
                                                }
                                                return a;
                                            })
                                        );
                                    }}
                                />
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color={BUTTON.PRIMARY}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (page > 0) {
                                setPage((p) => p - 1);
                                setSelectedVersionId(null);
                            }
                        }}
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                        disabled={page === 0}
                    >
                        Back
                    </Button>
                    <Button
                        disabled={(page === 0 && !selectedApplicationId) || (page === 1 && !selectedVersionId)}
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                        onClick={() => {
                            if (page < 1) {
                                setPage((p) => p + 1);
                            } else {
                                onImport();
                            }
                        }}
                    >
                        {page === 1 ? 'Import' : 'Next'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={!!error}
                autoHideDuration={1000}
                onClose={() => setError(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SnackbarContent
                    severity="error"
                    message={<b>{error}</b>}
                />
            </Snackbar>
        </>
    );
};

const EditApplicationDialog = ({ application, open, onClose }) => {
    const api = useApi();
    const [tmpApplication, setTmpApplication] = useState(null);
    const [matchNamesTestResult, setMatchNamesTestResult] = useState({});
    const [matchNamesTestResultInverted, setMatchNamesTestResultInverted] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        if (tmpApplication) {
            doPackageMatchTests(api, tmpApplication, setMatchNamesTestResult, setMatchNamesTestResultInverted);
        }
    }, [tmpApplication]);

    useEffect(() => {
        if (open) {
            setTmpApplication({ ...application });
        } else {
            setTmpApplication(null);
            setMatchNamesTestResult({});
            setMatchNamesTestResultInverted({});
        }
    }, [open, application]);

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
            >
                <DialogTitle>Edit Application - {tmpApplication?.application}</DialogTitle>
                <DialogContent>
                    <LayoutColumn>
                        <PackageMatchChipInput
                            className="tw-mt-4"
                            label="Match Name"
                            value={tmpApplication?.matchName || []}
                            placeholder="This will be application name if not set"
                            onAdd={(chip) => {
                                tmpApplication.matchName = [...(tmpApplication.matchName || []), chip];
                                setTmpApplication({ ...tmpApplication });
                            }}
                            onDelete={(chip) => {
                                tmpApplication.matchName = tmpApplication.matchName.filter((m) => m !== chip);
                                setTmpApplication({ ...tmpApplication });
                            }}
                            testResults={matchNamesTestResult}
                            mode="matchName"
                            helperText="Can not start with wildcard (*) and must be at least 4 characters long if used"
                            onBeforeAdd={checkChipForWildcard}
                        />
                        <PackageMatchChipInput
                            className="tw-mt-8"
                            label="Match Name Test Strings"
                            value={tmpApplication?.matchTestNames || []}
                            placeholder="Names to test Match Names on."
                            onAdd={(chip) => {
                                tmpApplication.matchTestNames = [...(tmpApplication.matchTestNames || []), chip];
                                setTmpApplication({ ...tmpApplication });
                            }}
                            onDelete={(chip) => {
                                tmpApplication.matchTestNames = tmpApplication.matchTestNames.filter((m) => m !== chip);
                                setTmpApplication({ ...tmpApplication });
                            }}
                            testResults={matchNamesTestResultInverted}
                            mode="testString"
                        />
                        {/* <CpeInput
                            value={tmpApplication?.cpeString}
                            onChange={(cpeString) => {
                                setTmpApplication((c) => ({
                                    ...c,
                                    cpeString,
                                }));
                            }}
                        /> */}
                        <ChipInput
                            className="tw-mt-8"
                            label="Match Vendor"
                            value={tmpApplication?.matchVendor}
                            placeholder="This will be vendor name if not set"
                            onAdd={(chip) => {
                                tmpApplication.matchVendor = [...(tmpApplication.matchVendor || []), chip];
                                setTmpApplication(tmpApplication);
                            }}
                            onDelete={(chip) => {
                                tmpApplication.matchVendor = tmpApplication.matchVendor.filter((m) => m !== chip);
                                setTmpApplication(tmpApplication);
                            }}
                            style={{ marginRight: 10 }}
                        />
                        <Checkbox
                            label="Custom Detection"
                            checked={tmpApplication?.customDetection?.enabled || false}
                            onChange={({ target: { checked } }) => {
                                setTmpApplication((c) => ({
                                    ...c,
                                    customDetection: { enabled: checked },
                                }));
                            }}
                        />
                    </LayoutColumn>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color={BUTTON.PRIMARY}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                        onClick={() => {
                            api.updateApplication(tmpApplication.applicationId, tmpApplication)
                                .then(() => {
                                    onClose(true);
                                })
                                .catch((e) => {
                                    setError(e.message);
                                });
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={!!error}
                autoHideDuration={1000}
                onClose={() => setError(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SnackbarContent
                    severity="error"
                    message={<b>{error}</b>}
                />
            </Snackbar>
        </>
    );
};

const EditVersionDialog = ({ version, open, onClose }) => {
    const api = useApi();

    const [tmpVersion, setTmpVersion] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (open) {
            setTmpVersion({ ...version });
        } else {
            setTmpVersion(null);
        }
    }, [open, version]);

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
            >
                <DialogTitle>Edit Version - {tmpVersion?.version}</DialogTitle>
                <DialogContent>
                    <Input
                        className="tw-mt-4"
                        label="Match Version"
                        value={tmpVersion?.matchVersion}
                        onChange={(e) => {
                            tmpVersion.matchVersion = e.target.value;
                            setTmpVersion({ ...tmpVersion });
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color={BUTTON.PRIMARY}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                        onClick={() => {
                            api.updatePackage(tmpVersion.applicationId, tmpVersion.packageId, tmpVersion)
                                .then((d) => {
                                    onClose(true);
                                })
                                .catch((e) => {
                                    setError(e.message);
                                });
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={!!error}
                autoHideDuration={1000}
                onClose={() => setError(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SnackbarContent
                    severity="error"
                    message={<b>{error}</b>}
                />
            </Snackbar>
        </>
    );
};

// const cpeSegments = 'cpe:2.3:a:vendor:product:*:*:*:*:*:*:*:*'.split(':');

// const validator = (cpeString) => {
//     const splitValue = cpeString.split(':');
//     if (splitValue.length === cpeSegments.length) {
//         const isInvalid = splitValue.some((v) => v === '' || v.includes(' '));
//         if (!isInvalid) {
//             return !cpeString.startsWith('cpe:2.3:a');
//         }
//     }
//     return true;
// };

// const CpeInput = ({ onChange, value = '' }) => {
//     const [inValid, setInvalid] = useState(validator(value || ''));
//     const [errorMessage, setErrorMessage] = useState(null);
//     return (
//         <div>
//             <Input
//                 className="tw-mt-8"
//                 label="CPE String"
//                 value={value}
//                 placeholder="CPE String - Example: cpe:2.3:a:microsoft:onedrive:*:*:*:*:*:*:*:*"
//                 onChange={({ target: { value } }) => {
//                     const tmpInValid = value.trim() !== '' && validator(value);
//                     if (!tmpInValid) {
//                         onChange(value.trim());
//                         setErrorMessage(null);
//                     }
//                     if (tmpInValid) {
//                         setErrorMessage('Not a valid CPE string. Check if format matches cpe:2.3:a:vendor:product:*:*:*:*:*:*:*:*');
//                     }
//                     setInvalid(tmpInValid);
//                 }}
//                 error={errorMessage !== null}
//                 helperText={
//                     <div>
//                         {errorMessage !== null && <div>{errorMessage}</div>}
//                         <div>
//                             Use{' '}
//                             <a
//                                 className="cs-link cs-link-primary"
//                                 href="https://nvd.nist.gov/products/cpe/search"
//                                 target="_blank"
//                             >
//                                 Nist Search Engine
//                             </a>{' '}
//                             to find the cpe string to the application.
//                             <br />
//                             Search given vendor and product.
//                         </div>
//                     </div>
//                 }
//             />
//         </div>
//     );
// };
