import CheckboxMUI, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import React from 'react';
import './checkbox.scss';

const checkboxClasses = {
    root: 'cs-checkbox',
    checked: 'cs-checkbox-checked',
    disabled: 'cs-checkbox-disabled',
    indeterminate: 'cs-checkbox-indeterminate',
    colorPrimary: 'cs-checkbox-color-primary',
    colorSecondary: 'cs-checkbox-color-secondary',
};

const formControlLabelClasses = {
    root: 'cs-checkbox-label-container',
    disabled: 'cs-checkbox-label-disabled',
    label: 'cs-checkbox-label',
    labelPlacementStart: 'cs-checkbox-label-placement-start',
    labelPlacementTop: 'cs-checkbox-label-placement-top',
    labelPlacementBottom: 'cs-checkbox-label-placement-bottom',
};

export const Checkbox: React.FC<CheckboxProps & Partial<FormControlLabelProps> & { labelProps?: Record<string, string> }> = ({
    label = null,
    labelProps = {},
    onChange = () => null,
    checked = false,
    color = 'primary',
    ...otherProps
}) => {
    const CheckboxElement = (
        <CheckboxMUI
            onChange={onChange}
            checked={checked}
            color={color}
            {...otherProps}
            classes={checkboxClasses}
        />
    );
    if (label) {
        return (
            <FormControlLabel
                {...labelProps}
                control={CheckboxElement}
                label={label}
                classes={formControlLabelClasses}
            />
        );
    }
    return CheckboxElement;
};

export default Checkbox;
