import { Icon, LayoutColumn, LoadingLinear, Page } from '@capasystems/ui';
import { isDefined, isUndefined } from '@capasystems/utils';
import { NONE, UpdateStatusEnums } from '@thirdparty/constants';
import { getLanguageName } from '@thirdparty/utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { PageTitle, TailwindBadge, UnitConverter, WidgetPaper, WidgetsContainer } from '../../../../index';

export type EndpointDashboardTabProps = {
    endpoint: any;
    appliedPolicy: any;
    inKioskMode: boolean;
};

export const EndpointDashboardTab: React.FC<EndpointDashboardTabProps> = ({ endpoint, appliedPolicy, inKioskMode }) => {
    let batteryEnable;
    if (endpoint.androidData?.powerManagementEvents) {
        const batteryLevelCollectedEvent = endpoint.androidData.powerManagementEvents.findLast((event: any) => event.eventType === 'BATTERY_LEVEL_COLLECTED');
        if (batteryLevelCollectedEvent) {
            batteryEnable = (
                <LayoutColumn className="tw-mr-10">
                    <b className="tw-text-tiny tw-opacity-75">Battery level</b>
                    <LoadingLinear
                        variant="determinate"
                        value={batteryLevelCollectedEvent.batteryLevel}
                        classes={{
                            root: classNames({
                                'tw-h-2 tw-rounded-full': true,
                                'tw-bg-red-100': batteryLevelCollectedEvent.batteryLevel < 20,
                                'tw-bg-emerald-100': batteryLevelCollectedEvent.batteryLevel >= 20,
                            }),
                            bar: classNames({
                                'tw-bg-red-600': batteryLevelCollectedEvent.batteryLevel < 20,
                                'tw-bg-emerald-400': batteryLevelCollectedEvent.batteryLevel >= 20,
                            }),
                        }}
                    />
                    <b>{batteryLevelCollectedEvent.batteryLevel}%</b>
                </LayoutColumn>
            );
        }
    } else {
        batteryEnable = (
            <LayoutColumn>
                <b className="tw-text-tiny tw-opacity-75">Battery level</b>
                <i className=" tw-font-normal tw-text-gray-300">{NONE}</i>
            </LayoutColumn>
        );
    }

    return (
        <WidgetsContainer className="tw-mt-4">
            <div className="tw-grid tw-grid-cols-1 tw-gap-6">
                <WidgetPaper headerless>
                    <CoreInfo
                        softwareInfo={endpoint?.androidData?.softwareInfo}
                        endpoint={endpoint}
                    />
                    <div className="tw-px-6 tw-pb-6">
                        <div className="tw-grid tw-grid-cols-5 tw-gap-4">
                            <LayoutColumn>
                                <b className="tw-text-tiny tw-opacity-75">Last policy sync.</b>
                                <b>{dayjs(endpoint?.androidData?.lastPolicySyncTime).fromNow()}</b>
                            </LayoutColumn>
                            <LayoutColumn>
                                <b className="tw-text-tiny tw-opacity-75">Phone number</b>
                                <b>
                                    {endpoint?.androidData?.networkInfo?.telephonyInfos?.find((telephonyInfo: any) => isDefined(telephonyInfo.phoneNumber))
                                        ?.phoneNumber || <i className="tw-font-normal tw-text-gray-300">{NONE}</i>}
                                </b>
                            </LayoutColumn>
                            <LayoutColumn className="tw-mr-10">
                                <b className="tw-text-tiny tw-opacity-75">RAM</b>
                                <b>
                                    <UnitConverter data={{ unit: 'bytes', value: endpoint?.androidData?.memoryInfo?.totalRam }} />
                                </b>
                            </LayoutColumn>
                            {batteryEnable}
                            <LayoutColumn className="tw-text-right">
                                <b className="tw-text-tiny tw-opacity-75">Enrollment time</b>
                                <b>{dayjs(endpoint?.androidData?.enrollmentTime).fromNow()}</b>
                            </LayoutColumn>
                        </div>
                    </div>
                </WidgetPaper>
            </div>
        </WidgetsContainer>
    );
};

export type CoreInfoProps = {
    softwareInfo: any;
    endpoint: any;
};

const CoreInfo: React.FC<CoreInfoProps> = ({ softwareInfo, endpoint }) => {
    if (isUndefined(softwareInfo)) {
        return <div className="tw-p-6 tw-font-semibold">No software info available</div>;
    }
    const updateStatus = UpdateStatusEnums[softwareInfo.systemUpdateInfo.updateStatus];
    return (
        <Page title="Endpoints · Dashboard">
            <div className="tw-px-6 tw-py-6">
                <div className="tw-grid tw-grid-cols-5 tw-gap-4">
                    <PageTitle
                        category="Android"
                        description={getLanguageName(softwareInfo.primaryLanguageCode)}
                    >
                        {softwareInfo.androidVersion}
                    </PageTitle>
                    <LayoutColumn className="tw-col-span-1">
                        <b className="tw-text-tiny tw-opacity-75">Serial number</b>
                        <b>{endpoint?.androidData?.hardwareInfo?.serialNumber || <i className="tw-font-normal tw-text-gray-300">{NONE}</i>}</b>
                    </LayoutColumn>
                    <LayoutColumn className="tw-col-span-1">
                        <b className="tw-text-tiny tw-opacity-75">IMEI</b>
                        <b>{endpoint?.androidData?.networkInfo?.imei || <i className="tw-font-normal tw-text-gray-300">{NONE}</i>}</b>
                    </LayoutColumn>
                    <div className="tw-text-right">
                        {/* @ts-ignore - TailwindBadge is not supported by ts yet */}
                        <TailwindBadge
                            color={classNames({
                                neutral: updateStatus === UpdateStatusEnums.UPDATE_STATUS_UNKNOWN,
                                emerald: updateStatus === UpdateStatusEnums.UP_TO_DATE,
                                amber: updateStatus === UpdateStatusEnums.UNKNOWN_UPDATE_AVAILABLE || updateStatus === UpdateStatusEnums.OS_UPDATE_AVAILABLE,
                                red: updateStatus === UpdateStatusEnums.SECURITY_UPDATE_AVAILABLE,
                            })}
                            className="tw-gap-1"
                            size="small"
                        >
                            {updateStatus === UpdateStatusEnums.UP_TO_DATE ? (
                                <Icon
                                    type="checkmark"
                                    color="inherit"
                                    size="small"
                                />
                            ) : (
                                <Icon
                                    type="infoOutlined"
                                    color="inherit"
                                    size="small"
                                />
                            )}
                            <span>{updateStatus}</span>
                        </TailwindBadge>
                    </div>
                </div>
            </div>
        </Page>
    );
};
