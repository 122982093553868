import { COMMAND_IDS } from '@apple';
import { BUTTON } from '@capasystems/constants';
import {
    Button,
    Divider,
    Drawer,
    EmptyState,
    Fade,
    Grid,
    Grow,
    Icon,
    IconButton,
    LayoutCenter,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Snackbar,
    Tab,
    Tabs,
    Tooltip,
} from '@capasystems/ui';
import { Url, isDefined, noop, useTheme } from '@capasystems/utils';
import { AppleEnrollmentConfiguration, PRODUCT_NAME } from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration, getDepIconColor } from '@thirdparty/utils';
import classNames from 'classnames';
import pluralize from 'pluralize';
import QRCode from 'qrcode.react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    ActionsDialog,
    CapaOneLink,
    ConfirmDialog,
    DrawerStepRow,
    Heading,
    PageTitle,
    QueryBuilder,
    RenamingDialog,
    SchemaBuilder,
    WidgetPaper,
    useAnalytics,
    useAppleApi,
    useAppleEnrollmentConfigurationsSocket,
    useAuthContext,
    useManagementApi,
    useMessageContext,
    useNavigate,
} from '../../../index';

const AppleEnrollment = () => {
    const hasAppleMdmAccess = true; //TODO add check for apple mdm access

    if (hasAppleMdmAccess === false) {
        return <SetupAppleMdmAccount />;
    }
    return <AppleEnrollmentConfigurationCardList />;
};

const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Configuration name' });

const viewAction = {
    id: 'view',
    name: 'View configuration',
};

const renameAction = {
    id: 'rename',
    name: 'Rename',
};

const deleteAction = {
    id: 'delete',
    name: 'Delete',
};

const defaultDep = {
    id: 'default',
    name: 'Set as default DEP',
};

const enrollmentConfigurationActions = [viewAction, renameAction, deleteAction, defaultDep];

const AppleEnrollmentConfigurationCardList = () => {
    const appleApi = useAppleApi();
    const managementApi = useManagementApi();
    const [configurationList, setConfigurationList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const { showErrorMessage } = useMessageContext();
    const [searchTerm, setSearchTerm] = useState('');
    const [configurationMarkedForDelete, setConfigurationMarkedForDelete] = useState({ id: null });
    const [showDrawer, setShowDrawer] = useState(false);
    const [selectedConfiguration, setSelectedConfiguration] = useState({
        data: {},
    });
    const [isRenaming, setIsRenaming] = useState(false);

    const [deleteEnrollmentConfigurationState, setDeleteEnrollmentConfigurationState] = useState({
        open: false,
        isProcessing: false,
        failed: false,
    });

    const [entities, setEntities] = useState({
        groups: [],
        configurations: [],
        depEndpoints: [],
        applications: [],
        initialized: false,
    });

    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        configuration: {
            name: '',
        },
    });

    const queryBuilderRef = useRef({});

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName) => {
        setIsRenaming(false);
        appleApi
            .updateEnrollmentConfiguration(menuState.configuration.id, { name: newName })
            .then(() => setIsRenaming(false))
            .catch((errorResponse) => {
                setIsRenaming(true);
                showErrorMessage(errorResponse.data.message, { autoHideDuration: 2500 });
            });
    };

    const refreshEnrollmentConfigurationsList = useCallback(() => {
        setLoading(true);

        const apiCalls = [appleApi.getEnrollmentConfigurations(), appleApi.getDepEndpoints()];

        Promise.all(apiCalls)
            .then(([enrollmentResponse, depEndpointsResponse]) => {
                setErrorMessage(null);
                setConfigurationList(
                    enrollmentResponse.map((configuration) => {
                        return {
                            groupIds: [],
                            configurationIds: [],
                            applicationIds: [],
                            ...configuration,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('Could not get Enrollment configurations');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [appleApi]);

    const closeDeleteDialog = () => {
        setConfigurationMarkedForDelete((c) => ({ ...c, id: null }));
    };

    const onConfirmDelete = () => {
        setConfigurationMarkedForDelete((c) => ({ ...c, isProcessing: true, anErrorOccurred: false }));
        appleApi
            .deleteEnrollmentConfiguration(configurationMarkedForDelete.id)
            .then(() => {
                setConfigurationList((c) => {
                    return c.filter((configuration) => configuration.id !== configurationMarkedForDelete.id);
                });
                closeDeleteDialog();
            })
            .catch(() => {
                setConfigurationMarkedForDelete((c) => ({ ...c, isProcessing: false, anErrorOccurred: true }));
            });
    };

    useAppleEnrollmentConfigurationsSocket(
        useCallback(({ updateDescription, documentId, fullDocument, operationType }, { insertOperation, updateOperation, deleteOperation }) => {
            if (insertOperation) {
                refreshEnrollmentConfigurationsList();
                setConfigurationList((currentConfigurations) => {
                    return [
                        ...currentConfigurations,
                        {
                            ...fullDocument,
                            id: fullDocument._id,
                        },
                    ];
                });
            } else if (updateOperation) {
                setConfigurationList((currentConfigurations) => {
                    return currentConfigurations.map((configuration) => {
                        if (configuration.id === documentId) {
                            return {
                                ...configuration,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return configuration;
                    });
                });
            } else if (deleteOperation) {
                setConfigurationList((currentList) => {
                    return currentList.filter((configuration) => configuration.id !== documentId);
                });
            }
        }, [])
    );

    const onSubmit = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const openDrawer = (conf) => () => {
        if (!entities.initialized) {
            Promise.all([managementApi.getGroups(), appleApi.getAppleConfigurations(), appleApi.getDepEndpoints(), appleApi.getAppleApplications()])
                .then(([groupsResponse, configurationsResponse, depEndpointsResponse, applicationsResponse]) => {
                    setEntities({
                        groups: groupsResponse,
                        configurations: configurationsResponse,
                        depEndpoints: depEndpointsResponse,
                        applications: applicationsResponse,
                        initialized: true,
                    });
                })
                .catch(noop);
        }
        setSelectedConfiguration(conf);
        setShowDrawer(true);
    };

    const deleteEnrollmentConfiguration = (event, requestedFromDashboard = false) => {
        setShowDrawer(false);
        setDeleteEnrollmentConfigurationState({
            open: true,
            isProcessing: false,
            failed: false,
            requestedFromDashboard,
        });
    };

    const updateEnrollmentConfiguration = (event, requestedFromDashboard = false) => {
        setShowDrawer(false);
        setIsRenaming(true);
    };

    const editEnrollmentConfiguration = () => {
        navigate.to(`apple/enrollment/${selectedConfiguration.id}/edit`);
    };

    const onCancelDeleteEnrollmentConfiguration = () => {
        if (!deleteEnrollmentConfigurationState.requestedFromDashboard) {
            setShowDrawer(true);
        }
        setDeleteEnrollmentConfigurationState({
            open: false,
            isProcessing: false,
            failed: false,
        });
    };

    const onConfirmDeleteEnrollmentConfiguration = () => {
        setDeleteEnrollmentConfigurationState({
            open: true,
            isProcessing: true,
            failed: false,
        });
        appleApi
            .deleteEnrollmentConfiguration(selectedConfiguration.id)
            .then(() => {
                refreshEnrollmentConfigurationsList();
                setDeleteEnrollmentConfigurationState({
                    open: false,
                    isProcessing: true,
                    failed: false,
                });
            })
            .catch(() => {
                setDeleteEnrollmentConfigurationState({
                    open: true,
                    isProcessing: false,
                    failed: true,
                });
            });
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case viewAction.id:
                openDrawer(menuState.configuration)();
                break;
            case renameAction.id:
                setIsRenaming(menuState.configuration);
                updateEnrollmentConfiguration(null, true);
                break;
            case deleteAction.id:
                setSelectedConfiguration(menuState.configuration);
                deleteEnrollmentConfiguration(null, true);
                break;
            case defaultDep.id:
                appleApi.sendCommand(null, { commandId: COMMAND_IDS.SET_DEFAULT_DEP_PROFILE, data: { enrollmentId: menuState.configuration.id } });
                break;
        }
    };

    useEffect(refreshEnrollmentConfigurationsList, [appleApi]);

    const memoizedConfigurationList = useMemo(() => {
        if (searchTerm) {
            return configurationList
                .filter((c) => c.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
                .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
        }
        return configurationList;
    }, [searchTerm, configurationList]);

    if (errorMessage) {
        return (
            <LayoutCentered>
                <div>
                    <Heading>{errorMessage}</Heading>
                    <Button
                        onClick={refreshEnrollmentConfigurationsList}
                        className="tw-mt-4"
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        disabled={loading}
                    >
                        Retry
                    </Button>
                </div>
            </LayoutCentered>
        );
    }

    if (loading) {
        return (
            <LayoutCenter fill>
                <Loading />
            </LayoutCenter>
        );
    }

    if (configurationList.length === 0) {
        return (
            <div className="tw-m-auto tw-h-full">
                <EmptyState
                    entity="Enrollment configuration"
                    onClick={() => navigate.to('apple/enrollment/create')}
                    description="An enrollment configuration represents a group of settings that control the behavior of the enrollment process of managed devices"
                />
            </div>
        );
    }

    return (
        <Page title="Enrollment">
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <div className="tw-flex tw-justify-end">
                    <QueryBuilder
                        defaultConfiguration={queryBuilderConfiguration}
                        onInit={onSubmit}
                        onSubmit={onSubmit}
                        ref={queryBuilderRef}
                    />
                    <Button
                        noMargin
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        onClick={() => navigate.to('apple/enrollment/create')}
                        className="tw-ml-4"
                    >
                        New
                    </Button>
                    <RenamingDialog
                        onCancel={onRename}
                        onSubmit={onRenameSubmit}
                        currentName={menuState.configuration.name}
                        open={isRenaming}
                    />
                    <ConfirmDialog
                        onConfirm={onConfirmDelete}
                        onCancel={closeDeleteDialog}
                        open={configurationMarkedForDelete.id !== null}
                        title={`Delete ${configurationMarkedForDelete.name}?`}
                        isProcessing={configurationMarkedForDelete.isProcessing}
                    >
                        {configurationMarkedForDelete.anErrorOccurred && (
                            <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{configurationMarkedForDelete.name}". Please try again.</b>
                        )}
                    </ConfirmDialog>
                </div>
                <div className="tw-overflow-auto">
                    {memoizedConfigurationList.length === 0 && (
                        <WidgetPaper
                            headerless
                            className="tw-h-full"
                        >
                            <EmptyState
                                searchTerm={searchTerm}
                                entity="Enrollment configuration"
                                isSearching={searchTerm !== ''}
                                onClick={() => navigate.to('apple/enrollment/create')}
                                onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                            />
                        </WidgetPaper>
                    )}
                    <Grid
                        container
                        spacing={2}
                    >
                        {memoizedConfigurationList.map((configuration) => {
                            const depStatus = getDepIconColor(configuration);
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={4}
                                    key={configuration.id}
                                >
                                    <WidgetPaper
                                        className="tw-h-full tw-overflow-auto"
                                        title={configuration.name}
                                        description={configuration.description || <i className="tw-opacity-25">No description available</i>}
                                        truncateHeaderElements
                                        actions={
                                            <IconButton
                                                onClick={(e) => {
                                                    setMenuState({
                                                        open: true,
                                                        anchorEl: e.currentTarget,
                                                        configuration,
                                                        pages: [
                                                            {
                                                                name: 'Edit',
                                                                icon: 'editOutlined',
                                                                url: `apple/enrollment/${configuration.id}/edit`,
                                                            },
                                                            {
                                                                name: 'Duplicate',
                                                                icon: 'clone',
                                                                url: `apple/enrollment/create?duplicateId=${configuration.id}`,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                noMargin
                                            >
                                                <Icon type="moreVert" />
                                            </IconButton>
                                        }
                                    >
                                        <Divider light />
                                        <div>
                                            <div
                                                className={classNames({
                                                    'tw-grid tw-bg-slate-50/50 tw-text-slate-900': true,
                                                    'tw-grid-cols-12 tw-py-4': true,
                                                })}
                                            >
                                                <div className="tw-col-span-2"></div>
                                                <LayoutCentered className="tw-col-span-4">
                                                    <Tooltip
                                                        content={depStatus.tooltip}
                                                        position="top"
                                                    >
                                                        <Icon
                                                            type="phoneLock"
                                                            size="large"
                                                            color={depStatus.color}
                                                        />
                                                    </Tooltip>
                                                </LayoutCentered>
                                                <LayoutCentered
                                                    className="tw-col-span-4"
                                                    direction="column"
                                                >
                                                    <div className="tw-text-2xl tw-font-semibold">{configuration.depenrollment?.devices?.length || '0'}</div>
                                                    <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                        {pluralize('Linked DEP devices', configuration.depenrollment?.devices?.length || '0')}
                                                    </div>
                                                </LayoutCentered>
                                                <div className="tw-col-span-2"></div>
                                            </div>
                                            <Divider light />
                                            <div
                                                className={classNames({
                                                    'tw-grid tw-bg-slate-50/50 tw-text-slate-900': true,
                                                    'tw-grid-cols-12': true,
                                                })}
                                            >
                                                <div className="tw-col-span-4 tw-p-4">
                                                    <LayoutCentered direction="column">
                                                        <div className="tw-text-2xl tw-font-semibold">{configuration.groupIds.length}</div>
                                                        <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                            {pluralize('Group', configuration.groupIds.length)}
                                                        </div>
                                                    </LayoutCentered>
                                                </div>
                                                <div className="tw-col-span-4 tw-border-x-[thin] tw-border-solid tw-border-slate-200 tw-p-4">
                                                    <LayoutCentered direction="column">
                                                        <div className="tw-text-2xl tw-font-semibold">{configuration.configurationIds.length}</div>
                                                        <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                            {pluralize('Configuration', configuration.configurationIds.length)}
                                                        </div>
                                                    </LayoutCentered>
                                                </div>
                                                <div className="tw-col-span-4 tw-p-4">
                                                    <LayoutCentered direction="column">
                                                        <div className="tw-text-2xl tw-font-semibold">{configuration.applicationIds.length}</div>
                                                        <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                            {pluralize('Application', configuration.applicationIds.length)}
                                                        </div>
                                                    </LayoutCentered>
                                                </div>
                                            </div>
                                            <Divider light />
                                            <Button
                                                fullWidth
                                                noMargin
                                                onClick={openDrawer(configuration)}
                                                size="large"
                                                color={BUTTON.PRIMARY}
                                                className="tw-rounded-none tw-py-4 tw-text-xs"
                                            >
                                                View
                                                <Icon
                                                    type="arrowRight"
                                                    size="small"
                                                    className="tw-ml-2"
                                                />
                                            </Button>
                                        </div>
                                    </WidgetPaper>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <ActionsDialog
                        open={menuState.open}
                        anchorEl={menuState.anchorEl}
                        onClose={closeMenu}
                        category="Enrollment configuration"
                        title={menuState.configuration.name}
                        pages={menuState.pages}
                        actions={enrollmentConfigurationActions}
                        onActionClick={onActionClick}
                    />
                    <ConfirmDialog
                        onConfirm={onConfirmDeleteEnrollmentConfiguration}
                        onCancel={onCancelDeleteEnrollmentConfiguration}
                        title={`Delete ${selectedConfiguration.name}?`}
                        isProcessing={deleteEnrollmentConfigurationState.isProcessing}
                        open={deleteEnrollmentConfigurationState.open}
                    >
                        {deleteEnrollmentConfigurationState.failed && (
                            <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{selectedConfiguration.name}". Please try again.</b>
                        )}
                    </ConfirmDialog>

                    <Drawer
                        open={showDrawer}
                        anchor="right"
                        PaperProps={{
                            style: {
                                minWidth: '20vw',
                                maxWidth: '100vw',
                                width: 860,
                            },
                        }}
                        onClose={() => {
                            setShowDrawer(false);
                        }}
                    >
                        <div className="tw-grid tw-h-full tw-grid-rows-auto-auto-1fr">
                            <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-gap-4 tw-px-4 tw-pb-2 tw-pt-4">
                                <div>
                                    <LayoutCentered>
                                        <Icon
                                            type="workOutlined"
                                            className="tw-h-12 tw-w-12"
                                        />
                                    </LayoutCentered>
                                </div>
                                <div>
                                    <PageTitle
                                        category="Enrollment configuration"
                                        description={selectedConfiguration.description}
                                    >
                                        {selectedConfiguration.name}
                                    </PageTitle>
                                </div>
                                <div>
                                    <Button
                                        noMargin
                                        variant={BUTTON.TEXT}
                                        onClick={editEnrollmentConfiguration}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        noMargin
                                        color={BUTTON.DANGER}
                                        variant={BUTTON.TEXT}
                                        onClick={deleteEnrollmentConfiguration}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                            <SelectedConfigurationContent
                                selectedConfiguration={selectedConfiguration}
                                entities={entities}
                            />
                        </div>
                    </Drawer>
                </div>
            </div>
        </Page>
    );
};

const SelectedConfigurationContent = ({ selectedConfiguration, entities }) => {
    const [selectedTab, setSelectedTab] = useState('qrcode');
    const { selectedOrganization } = useAuthContext();
    const theme = useTheme();

    return (
        <>
            <Tabs
                value={selectedTab}
                centered
                onChange={(e, tabValue) => setSelectedTab(tabValue)}
                variant="fullWidth"
                className="tw-rounded-none"
                onRails
            >
                <Tab
                    disableRipple
                    label="QR Code"
                    value="qrcode"
                />
                <Tab
                    disableRipple
                    label="Download"
                    value="download"
                />
                <Tab
                    disableRipple
                    label="DEP Configuration"
                    value="depConfiguration"
                />
                <Tab
                    disableRipple
                    label="Summary"
                    value="summary"
                />
            </Tabs>
            <div className="tw-h-full tw-overflow-auto">
                {selectedTab === 'depConfiguration' && <ConfigurationSummaryTab configuration={selectedConfiguration} />}
                {selectedTab === 'summary' && (
                    <ConfigurationSettingsTab
                        configuration={selectedConfiguration}
                        entities={entities}
                    />
                )}
                {selectedTab === 'qrcode' && (
                    <Fade in>
                        <div className="tw-p-6">
                            <PageTitle>Recommended</PageTitle>
                            <DrawerStepRow requirements>Mobile devices - iOS/iPadOS 13 or higher</DrawerStepRow>
                            <DrawerStepRow requirements>Mac - macOS 12 or higher</DrawerStepRow>
                            <PageTitle className="tw-mt-8">Procedure</PageTitle>
                            <DrawerStepRow badgeContent="1">Open the camera app on your device and scan the QR code below</DrawerStepRow>
                            <DrawerStepRow badgeContent="1.1">If the camera app does not support QR reading, then download a QR scanner app</DrawerStepRow>
                            <div className="tw-mb-12 tw-mt-12">
                                <LayoutCentered>
                                    <QRCode
                                        // TODO: change this to not be hardcoded
                                        value={`${window.location.protocol}//${window.location.host}/api/organizations/${selectedOrganization.id}/apple/enrollment/download/${selectedConfiguration.id}`}
                                        size={200}
                                    />
                                </LayoutCentered>
                            </div>
                            <DrawerStepRow badgeContent="2">Go into Settings &gt; General &gt; VPN & Device Management on the device</DrawerStepRow>
                            <DrawerStepRow badgeContent="3">
                                Select the downloaded profile and follow the on-screen prompts to complete the enrollment process
                            </DrawerStepRow>
                        </div>
                    </Fade>
                )}
                {selectedTab === 'download' && (
                    <Fade in>
                        <div className="tw-p-6">
                            <PageTitle>Recommended</PageTitle>
                            <DrawerStepRow requirements>Mobile devices - iOS/iPadOS 13 or higher</DrawerStepRow>
                            <DrawerStepRow requirements>Mac - macOS 12 or higher</DrawerStepRow>
                            <PageTitle className="tw-mt-8">Procedure</PageTitle>
                            <DrawerStepRow badgeContent="1">
                                Download this{' '}
                                <a
                                    href={`${window.location.protocol}//${window.location.host}/api/organizations/${selectedOrganization.id}/apple/enrollment/download/${selectedConfiguration.id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: theme.palette.primary.main }}
                                >
                                    <b>profile</b>
                                </a>{' '}
                                to your Apple device
                            </DrawerStepRow>
                            <DrawerStepRow badgeContent="2">
                                <p>On iOS go into Settings &gt; General &gt; VPN & Device Management on the device.</p>
                                <p className="tw-mt-2">
                                    On MacOS double click the downloaded profile then go to system settings &gt; Privacy and Security &gt; Profiles{' '}
                                </p>
                            </DrawerStepRow>
                            <DrawerStepRow badgeContent="3">
                                Select the downloaded profile and follow the on-screen prompts to complete the enrollment process
                            </DrawerStepRow>
                        </div>
                    </Fade>
                )}
            </div>
        </>
    );
};

const ConfigurationSummaryTab = ({ configuration }) => {
    const configItems = [
        { key: 'allow_pairing', label: 'Allow Pairing', isBoolean: true },
        { key: 'anchor_certs', label: 'Anchor Certificates', string: true },
        { key: 'auto_advance_setup', label: 'Await Device Configured', isBoolean: true },
        { key: 'configuration_web_url', label: 'Configuration Web Url', string: true },
        { key: 'department', label: 'Department', string: true },
        { key: 'devices', label: 'Devices', isArray: true },
        { key: 'is_mandatory', label: 'Mandatory', isBoolean: true },
        { key: 'is_mdm_removable', label: 'MDM Removable', isBoolean: true },
        { key: 'is_multi_user', label: 'Multi User', isBoolean: true },
        { key: 'is_supervised', label: 'Supervised', isBoolean: true },
        { key: 'language', label: 'Language', string: true },
        { key: 'org_magic', label: 'Managed Organization Services', string: true },
        { key: 'profile_name', label: 'Name', string: true },
        { key: 'region', label: 'Region', string: true },
        { key: 'skip_setup_items', label: 'Skip items', isArray: true },
        { key: 'supervising_host_certs', label: 'Supervising host certificates', string: true },
        { key: 'support_email_address', label: 'Support Email Address', string: true },
        { key: 'support_phone_number', label: 'Support Phone Number', string: true },
        { key: 'url', label: 'Url', string: true },
    ];
    const hasConfigData = configItems.some((item) => configuration.depenrollment && configuration.depenrollment[item.key] !== undefined);

    return (
        <div>
            {hasConfigData ? (
                <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-6 tw-p-6 tw-font-semibold">
                    {configItems.map(({ key, label, isBoolean, isArray }) => {
                        const value = configuration.depenrollment[key];
                        if (value !== undefined) {
                            return (
                                <React.Fragment key={key}>
                                    <div>{label}</div>
                                    <div className="tw-text-right">
                                        <div className="tw-grid tw-grid-cols-1 tw-gap-2">
                                            <span>
                                                {isArray
                                                    ? value.map((item, index) => (
                                                          <React.Fragment key={index}>
                                                              {item}
                                                              <br />
                                                          </React.Fragment>
                                                      ))
                                                    : isBoolean
                                                    ? value
                                                        ? 'Yes'
                                                        : 'No'
                                                    : value}
                                            </span>
                                        </div>
                                    </div>
                                    <Divider
                                        light
                                        className="tw-col-span-2"
                                    />
                                </React.Fragment>
                            );
                        }
                        return null;
                    })}
                </div>
            ) : (
                <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
                    <div className="tw-p-6 tw-font-semibold">No data found</div>
                </div>
            )}
        </div>
    );
};

const ConfigurationSettingsTab = ({ configuration, entities }) => {
    return (
        <div>
            <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-6 tw-p-6 tw-font-semibold">
                <div>Groups</div>
                <div className="tw-text-right">
                    <div className="tw-grid tw-grid-cols-1 tw-gap-2">
                        {configuration.groupIds.length > 0 ? (
                            entities.groups
                                .filter((entityGroup) => configuration.groupIds.includes(entityGroup.id))
                                .map((entityGroup) => (
                                    <CapaOneLink
                                        to={`management/group/${entityGroup.id}/apple/endpoints`}
                                        className="tw-block"
                                    >
                                        {entityGroup.name}
                                    </CapaOneLink>
                                ))
                        ) : (
                            <i className="tw-block tw-font-semibold tw-text-neutral-400">No groups assigned</i>
                        )}
                    </div>
                </div>
                <Divider
                    light
                    className="tw-col-span-2"
                />
                <div>Configurations</div>
                <div className="tw-text-right">
                    <div className="tw-grid tw-grid-cols-1 tw-gap-2">
                        {configuration.configurationIds.length > 0 ? (
                            entities.configurations
                                .filter((entityConfiguration) => configuration.configurationIds.includes(entityConfiguration.id))
                                .map((entityConfiguration) => (
                                    <CapaOneLink
                                        to={`apple/configuration/${entityConfiguration.id}/membership`}
                                        className="tw-block"
                                    >
                                        {entityConfiguration.name}
                                    </CapaOneLink>
                                ))
                        ) : (
                            <i className="tw-block tw-font-semibold tw-text-neutral-400">No configurations assigned</i>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const SetupAppleMdmAccount = () => {
    const analytics = useAnalytics();
    const { selectedOrganization } = useAuthContext();
    const [creatingAppleMdm, setCreatingAppleMdm] = useState(false);
    const appleApi = useAppleApi();

    const onCreateAccount = () => {
        if (!creatingAppleMdm) {
            setCreatingAppleMdm(true);
            appleApi
                .appleSignup()
                .then((response) => {
                    window.location.href = response.url;
                })
                .catch((error) => {
                    setCreatingAppleMdm(false);
                });
        }
    };

    return (
        <Page
            title="Enrollment"
            onInit={analytics.sendPageView}
        >
            <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr tw-bg-undraw-sync tw-bg-cover tw-bg-top">
                <div className="tw-bg-gradient-to-r tw-from-indigo-500 tw-to-purple-500 tw-shadow-sm">
                    <LayoutCenter>
                        <Grow in>
                            <div className="tw-px-16 tw-py-16 tw-text-center tw-text-white ">
                                <Heading bold>
                                    Hi <span className="tw-font-bold">{selectedOrganization.name}</span> 👋
                                </Heading>
                                <Heading
                                    bold
                                    className="tw-mt-2"
                                    subheading
                                >
                                    To get started you must first sign up for an Apple MDM account for <span className="tw-font-bold">{PRODUCT_NAME}</span>.
                                </Heading>
                                <br />

                                <Button
                                    onClick={onCreateAccount}
                                    variant={BUTTON.OUTLINED}
                                    color={BUTTON.INHERIT}
                                    size="large"
                                    className={classNames('tw-mt-4', {
                                        'tw-text-white tw-opacity-25': creatingAppleMdm,
                                    })}
                                    disabled={creatingAppleMdm}
                                >
                                    Setup Apple MDM account
                                    {creatingAppleMdm && (
                                        <Loading
                                            size={12}
                                            className="tw-ml-4 tw-text-white"
                                        />
                                    )}
                                </Button>
                            </div>
                        </Grow>
                    </LayoutCenter>
                </div>
            </div>
        </Page>
    );
};

const AppleCreateEnrollmentConfiguration = () => {
    const appleApi = useAppleApi();
    const managementApi = useManagementApi();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const propertiesBuilderRef = useRef({});
    const [failedToInitialize, setFailedToInitialize] = useState(false);
    const [isProcessingRequest, setIsProcessingRequest] = useState(false);
    const { enrollmentId } = useParams();

    const { showErrorMessage } = useMessageContext();

    const onSubmit = () => {
        const { name = '', description = '', osType = '' } = propertiesBuilderRef.current;
        setErrorMessage(null);
        if (name.trim() === '') {
            setErrorMessage('Name is required');
        } else {
            setIsProcessingRequest(true);
            const configurationObject = {
                name,
                description,
                osType,
                ...propertiesBuilderRef.current,
            };
            if (!configurationObject.depenrollment) {
                configurationObject.depenrollment = {};
            }
            if (isDefined(enrollmentId)) {
                appleApi
                    .updateEnrollmentConfiguration(enrollmentId, { applicationIds: [], configurationIds: [], ...configurationObject })
                    .then(navigateToEnrollmentPage)
                    .catch((errorResponse) => {
                        setIsProcessingRequest(false);
                        showErrorMessage(errorResponse.data.message, { autoHideDuration: 2500 });
                    });
            } else {
                appleApi
                    .createEnrollmentConfiguration(configurationObject)
                    .then(navigateToEnrollmentPage)
                    .catch((errorResponse) => {
                        setIsProcessingRequest(false);
                        showErrorMessage(errorResponse.data.message, { autoHideDuration: 2500 });
                    });
            }
        }
    };

    const navigateToEnrollmentPage = () => {
        navigate.to(`apple/enrollment`);
    };

    const fetchData = () => {
        const promises = [
            managementApi.getGroups(),
            appleApi.getAppleConfigurations(),
            appleApi.getAppleApplications(),
            appleApi.getDepEndpoints({ pageSize: 99999999 }),
        ];
        const duplicateConfigurationId = Url.getString('duplicateId');
        if (duplicateConfigurationId) {
            promises.push(appleApi.getEnrollmentConfiguration(duplicateConfigurationId));
        }
        if (isDefined(enrollmentId)) {
            promises.push(appleApi.getEnrollmentConfiguration(enrollmentId));
        }
        Promise.all(promises)
            .then(([groupsResponse, configurationsResponse, applicationsResponse, depEndpointsResponse, duplicateResponse]) => {
                propertiesBuilderRef.current.name = duplicateResponse?.name;
                propertiesBuilderRef.current.description = duplicateResponse?.description;
                propertiesBuilderRef.current.groupIds = duplicateResponse?.groupIds;
                propertiesBuilderRef.current.configurationIds = duplicateResponse?.configurationIds;
                propertiesBuilderRef.current.applicationIds = duplicateResponse?.applicationIds;
                propertiesBuilderRef.current.depenrollment = duplicateResponse?.depenrollment;

                // propertiesBuilderRef.current.configurationType = duplicateResponse.configurationType;
                // propertiesBuilderRef.current.enrollmentIdentifier = duplicateResponse.enrollmentIdentifier;

                AppleEnrollmentConfiguration.schema.properties.groupIds.items.options = groupsResponse.map(({ id, name }) => ({ id, name }));
                AppleEnrollmentConfiguration.schema.properties.configurationIds.items.options = configurationsResponse.map(({ id, name }) => ({ id, name }));
                AppleEnrollmentConfiguration.schema.properties.applicationIds.items.options = applicationsResponse.map(({ id, name }) => ({ id, name }));
                AppleEnrollmentConfiguration.schema.properties.depenrollment.properties.devices.items.options = depEndpointsResponse.content.map(
                    ({ serial_number }) => ({
                        id: serial_number,
                        name: serial_number,
                    })
                );
                setLoading(false);
            })
            .catch((error) => {
                setFailedToInitialize(true);
            });
    };

    useEffect(fetchData, []);

    if (failedToInitialize) {
        return (
            <LayoutCentered>
                <div>
                    <PageTitle
                        category="Something went wrong"
                        className="tw-mb-4"
                    >
                        Failed to get dependencies
                    </PageTitle>
                    <Button
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                        onClick={() => {
                            setFailedToInitialize(false);
                        }}
                    >
                        Retry
                    </Button>
                    <Button
                        color={BUTTON.PRIMARY}
                        onClick={navigateToEnrollmentPage}
                    >
                        Cancel
                    </Button>
                </div>
            </LayoutCentered>
        );
    }
    if (loading) {
        return <Loading />;
    }
    return (
        <Page title={isDefined(enrollmentId) ? 'Enrollment · Edit' : 'Enrollment · Create'}>
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <LayoutRow align="end">
                    <div className="tw-ml-16">
                        <Button
                            color={BUTTON.PRIMARY}
                            variant={BUTTON.RAISED}
                            onClick={onSubmit}
                            disabled={isProcessingRequest}
                        >
                            {isProcessingRequest ? (isDefined(enrollmentId) ? 'Saving' : 'Creating') : isDefined(enrollmentId) ? 'Save' : 'Create'}
                        </Button>
                        <Button
                            noMargin
                            onClick={navigateToEnrollmentPage}
                            variant={BUTTON.OUTLINED}
                        >
                            Cancel
                        </Button>
                    </div>
                </LayoutRow>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={errorMessage !== null}
                    onClose={() => setErrorMessage(null)}
                    autoHideDuration={3000}
                    message={<b>{errorMessage}</b>}
                />
                <WidgetPaper
                    className="tw-h-full tw-overflow-auto"
                    headerless
                >
                    <SchemaBuilder
                        key="AppleEnrollmentConfiguration"
                        properties={AppleEnrollmentConfiguration.properties}
                        schema={AppleEnrollmentConfiguration.schema}
                        ref={propertiesBuilderRef.current}
                        loading={loading}
                    />
                </WidgetPaper>
            </div>
        </Page>
    );
};

export { AppleCreateEnrollmentConfiguration, AppleEnrollment };
