import { SORT_DIRECTION, TOOLTIP } from '@capasystems/constants';
import {
    Button,
    Column,
    CopyTextButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Ellipsis,
    Link,
    Tooltip,
    useLocation,
    VirtualizedTable,
} from '@capasystems/ui';
import { formatTimestamp, Url } from '@capasystems/utils';
import { LEAF_ID, LOCAL_STORAGE_ID } from '@thirdparty/constants';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from '../../index';
import { ReliabilityContext } from '../contexts/reliability-context/reliability.context';
import { useApi } from '../hooks/useApi/useApi';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';

const PAGE_SIZE = 50;

const ReliabilityEventListWidget = ({ activeFilters, setUrlIsDirty }) => {
    const { deviceId, lockedPeriod, timezone, severityId, sampleInterval } = useContext(ReliabilityContext);
    const api = useApi();
    const location = useLocation();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [tooltipDialogOpen, setTooltipDialogOpen] = useState(false);
    const [tooltipDescription, setTooltipDescription] = useState('');
    const pagingRef = useRef({
        totalRowCount: 0,
        scrollToIndex: 0,
        pageNumber: 1,
    });
    const [widgetPaperProps, setWidgetPaperProps] = useState({
        loading: true,
    });
    const [sort, setSort] = useState({
        by: 'timestamp',
        direction: SORT_DIRECTION.DESC,
    });

    const updateSourceFilter = (selectedSourceId) => {
        Url.set(LEAF_ID.SOURCE, selectedSourceId);
        setTimeout(() => {
            setUrlIsDirty(true);
        }, 100);
    };

    const updateEventIdFilter = (selectedEventId) => {
        Url.set(LEAF_ID.EVENT_ID, selectedEventId);
        setTimeout(() => {
            setUrlIsDirty(true);
        }, 100);
    };

    const prepareTableData = (response) => {
        pagingRef.current.totalRowCount = response.rowCount;
        setTableData(response.data);
        setWidgetPaperProps({});
    };

    const openTooltipDialog = (description) => {
        setTooltipDescription(description);
        setTooltipDialogOpen(true);
    };

    const prepareScrollTableData = (response) => {
        pagingRef.current.scrollToIndex = PAGE_SIZE * pagingRef.current.pageNumber;
        pagingRef.current.totalRowCount = response.rowCount;
        pagingRef.current.pageNumber += 1;
        const combinedTableData = tableData.concat(response.data);
        setTableData(combinedTableData);
        setTableIsLoading(false);
    };

    const onRequestError = (error) => {
        console.log(error);
        setWidgetPaperProps({
            errorMessage: 'Could not load Event List',
        });
        setTableIsLoading(false);
    };

    const onNavigateToDevice = (selectedDeviceId) => () => {
        navigate.to(`windows/device/${selectedDeviceId}/reliability/${severityId}${location.search || '?'}&${LOCAL_STORAGE_ID.SHOW_ACTIVITY_LOG}=false`);
    };

    const navigateToLevel = (level) => {
        navigate.to(`windows/device/${deviceId}/reliability/${level.toLowerCase()}${location.search || '?'}&${LOCAL_STORAGE_ID.SHOW_ACTIVITY_LOG}=false`);
    };

    const scrolledToBottomHandler = () => {
        setTableIsLoading(true);
        const logTypeFilter = activeFilters.find((filter) => filter.id === LEAF_ID.LOG_TYPE);
        const sourceFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SOURCE);
        const evendIdFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.EVENT_ID);
        const searchFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SEARCH);
        let orderBy = `${sort.by},${sort.direction}`;
        if (deviceId) {
            if (severityId) {
                api.getEventDetailedTableSeverityDevice(
                    deviceId,
                    lockedPeriod,
                    sampleInterval,
                    timezone,
                    severityId,
                    pagingRef.current.pageNumber + 1,
                    PAGE_SIZE,
                    logTypeFilter?.value.join(),
                    sourceFilter?.value.join(),
                    evendIdFilter?.value.join(),
                    searchFilter?.value,
                    orderBy
                )
                    .then(prepareScrollTableData)
                    .catch(onRequestError);
            } else {
                api.getEventDetailedTableDevice(
                    deviceId,
                    lockedPeriod,
                    sampleInterval,
                    timezone,
                    pagingRef.current.pageNumber + 1,
                    PAGE_SIZE,
                    logTypeFilter?.value.join(),
                    sourceFilter?.value.join(),
                    evendIdFilter?.value.join(),
                    searchFilter?.value,
                    orderBy
                )
                    .then(prepareScrollTableData)
                    .catch(onRequestError);
            }
        } else {
            api.getEventDetailedTable(
                lockedPeriod,
                sampleInterval,
                timezone,
                severityId,
                pagingRef.current.pageNumber + 1,
                PAGE_SIZE,
                logTypeFilter?.value.join(),
                sourceFilter?.value.join(),
                evendIdFilter?.value.join(),
                searchFilter?.value,
                orderBy
            )
                .then(prepareScrollTableData)
                .catch(onRequestError);
        }
    };

    useEffect(() => {
        api.cancel();
        if (activeFilters === null) {
            return;
        }
        setTableData([]);
        pagingRef.current.scrollToIndex = 0;
        pagingRef.current.totalRowCount = 0;
        pagingRef.current.pageNumber = 1;
        setWidgetPaperProps({
            loading: true,
        });
        const logTypeFilter = activeFilters.find((filter) => filter.id === LEAF_ID.LOG_TYPE);
        const sourceFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SOURCE);
        const evendIdFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.EVENT_ID);
        const searchFilter = activeFilters.find((leaf) => leaf.id === LEAF_ID.SEARCH);
        let orderBy = `${sort.by},${sort.direction}`;
        if (deviceId) {
            if (severityId) {
                api.getEventDetailedTableSeverityDevice(
                    deviceId,
                    lockedPeriod,
                    sampleInterval,
                    timezone,
                    severityId,
                    pagingRef.current.pageNumber,
                    PAGE_SIZE,
                    logTypeFilter?.value.join(),
                    sourceFilter?.value.join(),
                    evendIdFilter?.value.join(),
                    searchFilter?.value,
                    orderBy
                )
                    .then(prepareTableData)
                    .catch(onRequestError);
            } else {
                api.getEventDetailedTableDevice(
                    deviceId,
                    lockedPeriod,
                    sampleInterval,
                    timezone,
                    pagingRef.current.pageNumber,
                    PAGE_SIZE,
                    logTypeFilter?.value.join(),
                    sourceFilter?.value.join(),
                    evendIdFilter?.value.join(),
                    searchFilter?.value,
                    orderBy
                )
                    .then(prepareTableData)
                    .catch(onRequestError);
            }
        } else {
            api.getEventDetailedTable(
                lockedPeriod,
                sampleInterval,
                timezone,
                severityId,
                pagingRef.current.pageNumber,
                PAGE_SIZE,
                logTypeFilter?.value.join(),
                sourceFilter?.value.join(),
                evendIdFilter?.value.join(),
                searchFilter?.value,
                orderBy
            )
                .then(prepareTableData)
                .catch(onRequestError);
        }
    }, [lockedPeriod, activeFilters, deviceId, severityId, timezone, api, sort]);

    return (
        <WidgetPaper
            title="Event list"
            description="Detailed information about events in the selected period"
            className="tw-h-112"
            {...widgetPaperProps}
        >
            <VirtualizedTable
                items={tableData}
                totalRowCount={pagingRef.current.totalRowCount}
                showRowCount
                scrollToIndex={pagingRef.current.scrollToIndex}
                onScrollToBottom={scrolledToBottomHandler}
                isLoading={tableIsLoading}
                sortBy={sort.by}
                sortDirection={sort.direction}
                sort={({ sortBy, sortDirection }) => {
                    setSort({
                        by: sortBy,
                        direction: sortDirection,
                    });
                }}
            >
                <Column
                    minWidth={120}
                    maxWidth={120}
                    dataKey="timestamp"
                    label="Time"
                    cellRenderer={({ rowData: { timestamp } }) => formatTimestamp(timestamp)}
                />

                {!severityId && (
                    <Column
                        minWidth={120}
                        maxWidth={120}
                        label="Level"
                        dataKey="level"
                        cellRenderer={({ rowData: { level } }) => (
                            <Link
                                onClick={() => navigateToLevel(level)}
                                id={level}
                            >
                                <Ellipsis>{level}</Ellipsis>
                            </Link>
                        )}
                    />
                )}

                {!deviceId && (
                    <Column
                        minWidth={160}
                        dataKey="deviceName"
                        label="Device"
                        cellRenderer={({ rowData: { deviceName, mongoId } }) => (
                            <Link onClick={onNavigateToDevice(mongoId)}>
                                <Ellipsis>{deviceName}</Ellipsis>
                            </Link>
                        )}
                    />
                )}
                <Column
                    minWidth={96}
                    maxWidth={96}
                    dataKey="event"
                    label="ID"
                    cellRenderer={({ rowData: { event: eventId } }) => (
                        <Link
                            disabled={tableIsLoading}
                            onClick={() => updateEventIdFilter(eventId)}
                        >
                            <Ellipsis>{eventId}</Ellipsis>
                        </Link>
                    )}
                />
                <Column
                    minWidth={160}
                    dataKey="source"
                    label="Source"
                    cellRenderer={({ rowData: { source } }) => (
                        <Link
                            disabled={tableIsLoading}
                            onClick={() => updateSourceFilter(source)}
                        >
                            <Ellipsis>{source}</Ellipsis>
                        </Link>
                    )}
                />
                <Column
                    minWidth={160}
                    dataKey="description"
                    label="Description"
                    cellRenderer={({ rowData: { description } }) => (
                        <Tooltip
                            position={TOOLTIP.POSITION.TOP}
                            content={
                                <div>
                                    <div className="reliability-tooltip">{description}</div>
                                    <CopyTextButton
                                        hideConfirm
                                        color="inherit"
                                        text={description}
                                        noMargin
                                    >
                                        Copy
                                    </CopyTextButton>
                                    <Button
                                        color="inherit"
                                        onClick={() => openTooltipDialog(description)}
                                    >
                                        View
                                    </Button>
                                </div>
                            }
                            interactive
                        >
                            <Ellipsis>{description}</Ellipsis>
                        </Tooltip>
                    )}
                />
            </VirtualizedTable>
            <Dialog
                size="md"
                open={tooltipDialogOpen}
                onClose={() => setTooltipDialogOpen(false)}
            >
                <DialogTitle>Event description</DialogTitle>
                <DialogContent>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <p>{tooltipDescription}</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <CopyTextButton
                        hideConfirm
                        text={tooltipDescription}
                        onClick={() => setTooltipDialogOpen(false)}
                    >
                        Copy
                    </CopyTextButton>
                    <Button onClick={() => setTooltipDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </WidgetPaper>
    );
};

ReliabilityEventListWidget.propTypes = {};

export { ReliabilityEventListWidget };
export default ReliabilityEventListWidget;
