/* eslint-disable indent */
import { SORT_DIRECTION, browserHistory, defaultTheme } from '@capasystems/constants';
import deepMerge from 'deepmerge';
import {
    isNumber as _isNumber,
    cloneDeep,
    inRange,
    isArray,
    isBoolean,
    isEqual,
    isFinite,
    isFunction,
    isInteger,
    isString,
    isUndefined,
    merge,
    uniqueId,
} from 'lodash';
import queryString from 'query-string';

const DECIMAL_SEPARATOR_TESTER = 1.1;

const QUERY_STRING_OPTIONS_NO_DECODE: queryString.ParseOptions = {
    arrayFormat: 'comma',
    decode: false, // Prevent "?paramName=one%2Ctwo,three" being parsed to ["one", "two", "three"] instead of the correct ["one,two", "three"].
};

const FULL_SCREEN_CLASS_NAME = 'cs-fullscreen';
const OBJECT_CONSTRUCTOR = {}.constructor;

//* Check if a string is a whole number (no decimal places) */
export const isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
};

/** Validate a version like 1.6.2 */
export const isValidVersionString = (version = '') => {
    const splittedVersion = version.split('.');
    return splittedVersion.every((item) => isNumeric(item)) && splittedVersion.length <= 4;
};

const updateBrowserHistory = (arrayOfNameAndValueObjects: any[], updateOperation: (input: any) => void) => {
    const { pathname, search } = window.location;
    const queryObject = queryString.parse(search, {
        decode: false,
    });
    arrayOfNameAndValueObjects.forEach(({ name, value }) => {
        const encodedName = encodeURIComponent(name);
        if (value !== null) {
            if (isArray(value)) {
                queryObject[encodedName] = value.map(encodeURIComponent).join(); // Encode all values and use comma separator because we use arrayFormat: 'comma' in getters.
            } else {
                queryObject[encodedName] = encodeURIComponent(value);
            }
        } else {
            delete queryObject[encodedName];
        }
    });
    const updatedSearch = `?${queryString.stringify(queryObject, {
        encode: false,
    })}`;
    if (search !== updatedSearch) {
        updateOperation(pathname + updatedSearch);
    }
};

export { cloneDeep, deepMerge, inRange, isArray, isBoolean, isEqual, isFunction, isInteger, isString, isUndefined, merge };

export const arraysAreEqual = (array1: any[], array2: any[]) => {
    return isEqual(array1.sort(), array2.sort());
};

export const decimalSeparator = DECIMAL_SEPARATOR_TESTER.toLocaleString().substring(1, 2);

export const thousandsSeparator = decimalSeparator === '.' ? ',' : /* istanbul ignore next */ '.';

export const getTheme = () => cloneDeep(defaultTheme);
/** Simply returns a new integer for every call. Starting at 1. */
export const getUniqueId = (prefix?: string) => uniqueId(prefix);
export const isDefined = (value: any) => isUndefined(value) === false;
export const isNumber = (value: any, excludeNaNAndInfinity = true) => {
    if (excludeNaNAndInfinity) {
        return isFinite(value);
    }
    /** Returns true on NaN, Infinity and -Infinity as well. */
    return _isNumber(value);
};

/**
 * Returns false for undefined, null and arrays too.
 * To test for arrays and objects use built-in typeof value === 'object'.
 */
export const isObject = (value: any) => {
    return (value && value.constructor === OBJECT_CONSTRUCTOR) || false;
};

/** Return a number as a string with thousand separators and decimals */
export const numberFormat = (number: number, decimals?: number) => {
    let calculatedNumber = number;
    if (isNumber(calculatedNumber)) {
        // Hide decimals if number is greater than 100 and no decimals have been specified.
        // eslint-disable-next-line no-nested-ternary
        let calculatedDecimals = (isNumber(decimals) ? decimals : calculatedNumber > 100 ? 0 : 2) as number;
        if (isInteger(calculatedNumber)) {
            calculatedDecimals = 0; // Do not show decimals on integers.
        } else if (inRange(+calculatedNumber, -0.1, 0.1) && calculatedDecimals > 0) {
            calculatedNumber = +calculatedNumber.toPrecision(calculatedDecimals);
            calculatedDecimals = calculatedNumber.toString().split('.')[1].length || 0;
        }
        return calculatedNumber.toLocaleString(undefined, {
            minimumFractionDigits: calculatedDecimals,
            maximumFractionDigits: calculatedDecimals,
        });
    }
    return calculatedNumber;
};

export class Url {
    /* istanbul ignore next */
    static getArray(name: string, defaultValue = null) {
        const value = queryString.parse(window.location.search, QUERY_STRING_OPTIONS_NO_DECODE)[encodeURIComponent(name)];
        if (isDefined(value)) {
            if (isString(value)) {
                return value.trim() === '' ? [] : value.split('').map(decodeURIComponent);
            }
            return value?.map(decodeURIComponent);
        }
        return defaultValue;
    }

    static getNumberArray(name: string, defaultValue = null) {
        const value = queryString.parse(window.location.search, { ...QUERY_STRING_OPTIONS_NO_DECODE, parseNumbers: true })[encodeURIComponent(name)];
        if (isDefined(value)) {
            if (isArray(value)) {
                return value.filter((a) => isNumber(a));
            }
            return isNumber(value) ? [value] : [];
        }
        return defaultValue;
    }

    /* istanbul ignore next */
    static getString(name: string, defaultValue: string | null = null) {
        const value = queryString.parse(window.location.search, QUERY_STRING_OPTIONS_NO_DECODE)[encodeURIComponent(name)];
        if (isString(value)) {
            return decodeURIComponent(value);
        }
        return defaultValue;
    }

    /* istanbul ignore next */
    static getBoolean(name: string, defaultValue = null) {
        const value = queryString.parse(window.location.search, { parseBooleans: true, decode: false })[encodeURIComponent(name)];
        if (isBoolean(value)) {
            return value;
        }
        return defaultValue;
    }

    /* istanbul ignore next */
    static getNumber(name: string, defaultValue = null) {
        const value = queryString.parse(window.location.search, { parseNumbers: true, decode: false })[encodeURIComponent(name)];
        if (isNumber(value)) {
            return value;
        }
        return defaultValue;
    }

    static set(name: string, value: string | null | string[] = null) {
        updateBrowserHistory(
            [
                {
                    name,
                    value,
                },
            ],
            browserHistory.replace
        );
    }

    static setMany(arrayOfNameAndValueObjects: any[]) {
        updateBrowserHistory(arrayOfNameAndValueObjects, browserHistory.replace);
    }

    /** Should only be used when the user must be able to go forward and backward in the browser.
     * If you need to do multiple URL changes simultaniously and persist them in history you should use push first and then Url.set on the subsequent modifiers.
     */
    static push(name: string, value = null) {
        updateBrowserHistory(
            [
                {
                    name,
                    value,
                },
            ],
            browserHistory.push
        );
    }

    static pushMany(arrayOfNameAndValueObjects: any[]) {
        updateBrowserHistory(arrayOfNameAndValueObjects, browserHistory.push);
    }
}

/* istanbul ignore next */
export const isFullscreen = () => document.fullscreenElement !== null;

// extend the Document interface
declare global {
    interface Document {
        mozCancelFullScreen?: () => Promise<void>;
        msExitFullscreen?: () => Promise<void>;
        webkitExitFullscreen?: () => Promise<void>;
        mozFullScreenElement?: Element;
        msFullscreenElement?: Element;
        webkitFullscreenElement?: Element;
        mozRequestFullScreen?: () => Promise<void>;
    }

    interface HTMLElement {
        msRequestFullscreen?: () => Promise<void>;
        mozRequestFullscreen?: () => Promise<void>;
        webkitRequestFullscreen?: () => Promise<void>;
    }
}
/* istanbul ignore next */
export const openFullscreen = () => {
    if (!isFullscreen()) {
        const { documentElement } = document;
        if (documentElement.requestFullscreen) {
            documentElement.requestFullscreen();
        } else if (documentElement.mozRequestFullscreen) {
            /* Firefox */
            documentElement.mozRequestFullscreen();
        } else if (documentElement.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            documentElement.webkitRequestFullscreen();
        } else if (documentElement.msRequestFullscreen) {
            /* IE/Edge */
            documentElement.msRequestFullscreen();
        }
    }
};

/* istanbul ignore next */
export const exitFullscreen = () => {
    if (isFullscreen()) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
};

// Notify Highcharts and other potential listeners.
export const dispatchResizeEvent = () => {
    window.dispatchEvent(new Event('resize'));
};

export const noop = () => null;

export const truncate = (text: string, maxLength = 140) => {
    if (isString(text)) {
        return text.length > maxLength ? text.slice(0, maxLength - 1) + '...' : text;
    }
    return '';
};

// Check whether a string is a valid HTTP URL
export const isValidHttpUrl = (string: string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getSortingFunction = (sortingDetails: any = {}, isNumeric = false, isDate = false, isBool = false, isVersionString = false) => {
    const { sortDirection = SORT_DIRECTION.ASC, sortBy = 'name' } = sortingDetails;
    if (isNumeric) {
        if (sortDirection === SORT_DIRECTION.ASC) {
            return (a: any, b: any) => a[sortBy] - b[sortBy];
        }
        return (a: any, b: any) => b[sortBy] - a[sortBy];
    }
    if (isDate) {
        if (sortDirection === SORT_DIRECTION.ASC) {
            return (a: any, b: any) => new Date(a[sortBy]).getMilliseconds() - new Date(b[sortBy]).getMilliseconds();
        }
        return (a: any, b: any) => new Date(b[sortBy]).getMilliseconds() - new Date(a[sortBy]).getMilliseconds();
    }
    if (isBool) {
        if (sortDirection === SORT_DIRECTION.ASC) {
            return (a: any, b: any) => {
                return a[sortBy] === b[sortBy] ? 0 : a[sortBy] ? -1 : 1;
            };
        }
        return (a: any, b: any) => {
            return a[sortBy] === b[sortBy] ? 0 : a[sortBy] ? 1 : -1;
        };
    }
    if (isVersionString) {
        if (sortDirection === SORT_DIRECTION.ASC) {
            return (a: any, b: any) => compareVersionStrings(a[sortBy], b[sortBy]);
        }
        return (a: any, b: any) => (compareVersionStrings(a[sortBy], b[sortBy]) ?? 0) * -1;
    }

    //Handle null so app don't crash if no email are represented
    if (sortDirection === SORT_DIRECTION.ASC) {
        return (a: any, b: any) => {
            if (!a[sortBy]) return 1;
            if (!b[sortBy]) return -1;
            return a[sortBy].localeCompare(b[sortBy]);
        };
    }
    return (a: any, b: any) => {
        if (!b[sortBy]) return 1;
        if (!a[sortBy]) return -1;
        return b[sortBy].localeCompare(a[sortBy]);
    };
};

export const compareVersionStrings = (a: any, b: any) => {
    if (a === b) {
        return 0;
    }
    const splitA = a.split('.');
    const splitB = b.split('.');
    const length = Math.max(splitA.length, splitB.length);
    for (let i = 0; i < length; i++) {
        //FLIP
        if (parseInt(splitA[i]) > parseInt(splitB[i]) || (splitA[i] === splitB[i] && isNaN(splitB[i + 1]))) {
            return 1;
        }
        //DONT FLIP
        if (parseInt(splitA[i]) < parseInt(splitB[i]) || (splitA[i] === splitB[i] && isNaN(splitA[i + 1]))) {
            return -1;
        }
    }
    return 0;
};

/*  
Example data:
[{ value: 10, weight: 1}, {value: 20, weight: 1}] => 15
[{ value: 10, weight: 10}, {value: 20, weight: 1}] => approx. 10.91 
 */
export const weightedAverage = (data = [], dataKey = 'value') => {
    const [sum, weightSum] = data.reduce(
        (acc, point: any) => {
            if (point.weight) {
                acc[0] = acc[0] + (point[dataKey] || 0) * point.weight;
                acc[1] = acc[1] + point.weight;
            }
            return acc;
        },
        [0, 0]
    );
    if (weightSum === 0) {
        return null;
    }
    return sum / weightSum;
};

/** Listen for fullscreenchange to catch ESC keypress. */
/* istanbul ignore next */
document.addEventListener('fullscreenchange', () => {
    if (isFullscreen()) {
        document.body.classList.add(FULL_SCREEN_CLASS_NAME);
    } else {
        document.body.classList.remove(FULL_SCREEN_CLASS_NAME);
    }
});
