import { DEVICE_STATE } from '../index';
const defaultSelection = true;

export const LEAF_TYPE = {
    SELECT: 'select',
    BOOLEAN: 'boolean',
    STRING: 'string',
    NUMBER: 'number',
    DATE: 'date',
};

export const LEAF_UNIT = {
    BYTES: 'bytes',
    HZ: 'Hz',
    CM: 'cm',
    BIT: 'bit',
    INCH: 'inch',
    PERCENT: '%',
    MAH: 'mAh',
    VOLT: 'v',
    RELATIVE: 'relative',
};

export const MONGO_DB = {
    REGEX: '$regex',
    ELEM_MATCH: '$elemMatch',
    REGEX_PREFIX: {
        STRING_NOT_CONTAINS: '^((?!',
        STARTS_WITH: '^(', // Wrap in parentheses to work with comma separated input.
        ENDS_WITH: '(', // Wrap in parentheses to work with comma separated input.
    },
    REGEX_SUFFIX: {
        STRING_NOT_CONTAINS: ').)*$',
        STARTS_WITH: ')', // Wrap in parentheses to work with comma separated input.
        ENDS_WITH: ')$', // Wrap in parentheses to work with comma separated input.
    },
    NOT: '$not',
    AND: '$and',
    EXPR: '$expr',
};

export const LEAF_ID = {
    USER_NAME: 'userName',
    APPLICATION_NAME: 'applicationName',
    SOFTWARE_NAME: 'softwareName',
    LOG_TYPE: 'logType',
    SOURCE: 'source',
    EVENT_ID: 'eventId',
    SEARCH: 'search',
    PERIOD: 'period',
    DEVICE_NAME: 'deviceName',
    ENDPOINT_NAME: 'endpointName',
    ONLINE: 'online',
    STATUS: 'status',
    NAME: 'name',
    OS_TYPE: 'osType',
    IS_VPP: 'isVpp',
    REMOVABLE: 'removable',
    ANDROID_APP_INSTALL_TYPE: 'androidAppInstallType',
    ANDROID_APPLICATION_TYPE: 'androidApplicationType',
    CVSS_SCORE: 'cvssScore',
};

export const LEAF_OPERATOR = {
    EQUAL: {
        id: '$eq',
        name: 'Equals',
    },
    STARTS_WITH: {
        id: 'startsWith',
        name: 'Starts with',
    },
    ENDS_WITH: {
        id: 'endsWith',
        name: 'Ends with',
    },
    NOT_EQUAL: {
        id: '$ne',
        name: 'Not equals',
    },
    GREATER_THAN: {
        id: '$gt',
        name: 'Greater than',
    },
    GREATER_THAN_OR_EQUAL: {
        id: '$gte',
        name: 'Greater than or equal to',
    },
    LESS_THAN: {
        id: '$lt',
        name: 'Less than',
    },
    LESS_THAN_OR_EQUAL: {
        id: '$lte',
        name: 'Less than or equal to',
    },
    ANY_IN_ARRAY: {
        id: '$in',
        name: 'Matches any',
    },
    ALL_IN_ARRAY: {
        id: '$all',
        name: 'Matches all',
    },
    NONE_IN_ARRAY: {
        id: '$nin',
        name: 'Matches none',
    },
    CONTAINS: {
        id: 'contains',
        name: 'Contains',
    },
    STRING_NOT_CONTAINS: {
        id: 'notContains',
        name: 'Not contains',
    },
    RANGE: {
        id: '$range',
        name: 'In range',
    },
    DATE_SUBTRACT: {
        id: '$dateSubtract',
        name: 'Relative Date',
    },
};

export const SUPPORTED_OPERATORS_FOR_LEAF_TYPE_SELECT = [LEAF_OPERATOR.ANY_IN_ARRAY, LEAF_OPERATOR.ALL_IN_ARRAY, LEAF_OPERATOR.NONE_IN_ARRAY];

export const SUPPORTED_OPERATORS_FOR_LEAF_TYPE_STRING = [
    LEAF_OPERATOR.CONTAINS,
    LEAF_OPERATOR.STRING_NOT_CONTAINS,
    LEAF_OPERATOR.EQUAL,
    LEAF_OPERATOR.NOT_EQUAL,
    LEAF_OPERATOR.STARTS_WITH,
    LEAF_OPERATOR.ENDS_WITH,
];
export const SUPPORTED_OPERATORS_FOR_LEAF_TYPE_DATE = [LEAF_OPERATOR.RANGE, LEAF_OPERATOR.DATE_SUBTRACT];

export const getLeafSupportedOperators = (leafType: keyof typeof LEAF_OPERATOR) => {
    switch (leafType) {
        case LEAF_TYPE.SELECT:
            return SUPPORTED_OPERATORS_FOR_LEAF_TYPE_SELECT;
        case LEAF_TYPE.STRING:
            return SUPPORTED_OPERATORS_FOR_LEAF_TYPE_STRING;
        case LEAF_TYPE.DATE:
            return SUPPORTED_OPERATORS_FOR_LEAF_TYPE_DATE;
        default:
            return [];
    }
};

const DRIVER_INVENTORY_CHILDREN_OPTIONS = [
    {
        id: 'name',
        name: 'Name',
        type: LEAF_TYPE.STRING,
        operator: LEAF_OPERATOR.CONTAINS,
        defaultValue: '',
    },
    {
        id: 'driverProvider',
        name: 'Driver Provider',
        type: LEAF_TYPE.STRING,
        operator: LEAF_OPERATOR.CONTAINS,
        defaultValue: '',
    },
    {
        id: 'deviceID',
        name: 'Device ID',
        type: LEAF_TYPE.STRING,
        operator: LEAF_OPERATOR.CONTAINS,
        defaultValue: '',
    },
    {
        id: 'hardwareID',
        name: 'Hardware ID',
        type: LEAF_TYPE.STRING,
        operator: LEAF_OPERATOR.CONTAINS,
        defaultValue: '',
    },
    {
        id: 'infName',
        name: 'Filename',
        type: LEAF_TYPE.STRING,
        operator: LEAF_OPERATOR.CONTAINS,
        defaultValue: '',
    },
    {
        id: 'manufacturer',
        name: 'Manufacturer',
        type: LEAF_TYPE.STRING,
        operator: LEAF_OPERATOR.CONTAINS,
        defaultValue: '',
    },
    {
        id: 'driverVersion',
        name: 'Driver Version',
        type: LEAF_TYPE.STRING,
        operator: LEAF_OPERATOR.CONTAINS,
        defaultValue: '',
    },
    {
        id: 'category',
        name: 'Category',
        type: LEAF_TYPE.STRING,
        operator: LEAF_OPERATOR.CONTAINS,
        defaultValue: '',
    },
    {
        id: 'driverDate',
        name: 'Driver Date',
        type: LEAF_TYPE.DATE,
        operator: LEAF_OPERATOR.RANGE,
        defaultValue: { min: null, max: null },
    },
];

export const QUERY_BUILDER_REF_DEVICE = {
    root: {
        gridItemColumns: 0,
        options: [
            { id: 'endpoint', name: 'Endpoint', defaultSelection },
            { id: 'hardwareInventory', name: 'Hardware', negateAble: true },
            { id: 'softwareInventory', name: 'Software', negateAble: true },
            { id: 'driverInventory', name: 'Driver', negateAble: true },
        ],
        children: {
            endpoint: {
                gridItemColumns: 4,
                options: [
                    { id: 'name', name: 'Name', defaultSelection },
                    { id: 'osName', name: 'OS name' },
                    { id: 'deviceType', name: 'Type' },
                    { id: 'online', name: 'Online' },
                    { id: 'virtual', name: 'Virtual' },
                    { id: 'agentVersion', name: 'Agent version' },
                    { id: 'osVersion', name: 'OS version' },
                    { id: 'motherboardUuid', name: 'Motherboard UUID' },
                    {
                        id: 'motherboardSerial',
                        name: 'Motherboard serial number',
                    },
                    { id: 'osSerial', name: 'OS serial number' },
                    { id: 'serviceStartUp', name: 'Latest agent startup' },
                    { id: 'osStartUp', name: 'Latest OS startup' },
                    { id: 'platform', name: 'Platform' },
                    { id: 'tags', name: 'Tags' },
                    { id: 'softwareCompliant', name: 'Software Up-to-date' },
                    { id: 'patchCount', name: 'Patch count' },
                    { id: 'failedPatchCount', name: 'Failed patch count' },
                    { id: 'patchseverity', name: 'Patch severity' },
                    { id: 'driverCompliant', name: 'Driver Up-to-date' },
                    { id: 'state', name: 'State' },
                    { id: 'driverSupported', name: 'Driver Supported' },
                ],
                children: null,
            },
            softwareInventory: {
                gridItemColumns: 4,
                options: [
                    { id: 'name', name: 'Name', defaultSelection },
                    { id: 'installDate', name: 'Install date' },
                    { id: 'userAccount', name: 'User account' },
                    { id: 'version', name: 'Version' },
                    { id: 'appCode', name: 'App code' },
                    { id: 'language', name: 'Language' },
                    { id: 'size', name: 'Size' },
                    { id: 'isSystemComponent', name: 'Is system component' },
                    { id: 'platform', name: 'Platform' },
                    { id: 'publisher', name: 'Publisher' },
                    { id: 'uninstallCommand', name: 'Uninstall command' },
                    { id: 'upgradeCode', name: 'Upgrade code' },
                ],
                children: null,
            },
            hardwareInventory: {
                gridItemColumns: 2,
                options: [
                    { id: 'battery', name: 'Battery' },
                    { id: 'biosInformation', name: 'Bios Information' },
                    { id: 'soundDevices', name: 'Sound Devices' },
                    { id: 'memoryBanks', name: 'Memory Banks' },
                    { id: 'hardwareMemory', name: 'Memory' },
                    {
                        id: 'trustedPlatformModule',
                        name: 'Trusted Platform Module',
                    },
                    { id: 'ipConfiguration', name: 'IP Configuration' },
                    { id: 'drives', name: 'Drives' },
                    { id: 'displayAdapters', name: 'Display Adapters' },
                    { id: 'scsiControllers', name: 'SCSI Controllers' },
                    { id: 'keyboards', name: 'Keyboards' },
                    { id: 'computer', name: 'Computer' },
                    { id: 'monitors', name: 'Monitors' },
                    { id: 'disks', name: 'Disks' },
                    { id: 'operatingSystem', name: 'Operating System' },
                    { id: 'mice', name: 'Mice' },
                    { id: 'networkAdapters', name: 'Network Adapters' },
                    { id: 'processors', name: 'Processors' },
                ],
                children: {
                    antivirus: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            {
                                id: 'enabled',
                                name: 'Enabled',
                                defaultSelection,
                            },
                            { id: 'snoozed', name: 'Snoozed' },
                            {
                                id: 'realTimeScanning',
                                name: 'Real Time Scanning',
                            },
                            {
                                id: 'definitionsUpToDate',
                                name: 'Definitions Up To Date',
                            },
                            { id: 'productVersion', name: 'Product Version' },
                        ],
                        children: null,
                    },
                    battery: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'chemistry', name: 'Chemistry' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'serialNumber', name: 'Serial Number' },
                            { id: 'model', name: 'Model' },
                            { id: 'availability', name: 'Availability' },
                            { id: 'deviceID', name: 'Device ID' },
                            { id: 'status', name: 'Status' },
                            { id: 'voltage', name: 'Voltage' },
                            { id: 'capacity', name: 'Capacity' },
                            { id: 'maximumCapacity', name: 'Maximum Capacity' },
                        ],
                        children: null,
                    },
                    biosInformation: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'version', name: 'Version' },
                            { id: 'releaseDate', name: 'Release Date' },
                            { id: 'serialNumber', name: 'Serial Number' },
                            {
                                id: 'smBiosMajorVersion',
                                name: 'SMBios Major Version',
                            },
                            {
                                id: 'smBiosMinorVersion',
                                name: 'SMBios Minor Version',
                            },
                            {
                                id: 'systemBiosMajorVersion',
                                name: 'System Bios Major Version',
                            },
                            {
                                id: 'systemBiosMinorVersion',
                                name: 'System Bios Minor Version',
                            },
                        ],
                    },
                    soundDevices: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'deviceID', name: 'Device ID' },
                        ],
                    },
                    memoryBanks: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'capacity', name: 'Capacity' },
                            { id: 'speed', name: 'Speed' },
                            { id: 'socket', name: 'Socket' },
                            { id: 'memoryType', name: 'Type' },
                            { id: 'typeDetail', name: 'Type Detail' },
                            { id: 'formFactor', name: 'Form Factor' },
                            { id: 'serialNumber', name: 'Serial Number' },
                            { id: 'partNumber', name: 'Part Number' },
                        ],
                    },
                    hardwareMemory: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'location', name: 'Location' },
                            { id: 'totalBanks', name: 'Total Banks' },
                            { id: 'availableBanks', name: 'Available Banks' },
                            { id: 'maxMemory', name: 'Max Memory' },
                            { id: 'totalMemory', name: 'Total Memory' },
                        ],
                    },
                    trustedPlatformModule: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'enabled', name: 'Enabled' },
                            { id: 'activated', name: 'Activated' },
                            { id: 'owned', name: 'Owned' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'physicalVersion', name: 'Phyiscal Version' },
                            { id: 'specialVersion', name: 'Special Version' },
                        ],
                    },
                    ipConfiguration: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'domainName', name: 'Domain Name' },
                            { id: 'dhcpScope', name: 'DHCP Scope' },
                            {
                                id: 'winsProxyEnabled',
                                name: 'WINS Proxy Enabled',
                            },
                            { id: 'nodeType', name: 'Node Type' },
                        ],
                    },
                    drives: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'type', name: 'Type' },
                            { id: 'media', name: 'Media' },
                            { id: 'fileSystem', name: 'File System' },
                            { id: 'access', name: 'Access' },
                            { id: 'bootDevice', name: 'Boot Device' },
                            { id: 'encrypted', name: 'Encrypted' },
                            { id: 'compressed', name: 'Compressed' },
                            { id: 'freeSpace', name: 'Free Space' },
                            { id: 'size', name: 'Size' },
                            { id: 'serialNumber', name: 'Serial Number' },
                            { id: 'supportsQuotas', name: 'Supports Quotas' },
                            {
                                id: 'supportsCompression',
                                name: 'Supports Compression',
                            },
                        ],
                    },
                    displayAdapters: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'gpuMemory', name: 'GPU Memory' },
                            { id: 'driverVersion', name: 'Driver Version' },
                            { id: 'driverDate', name: 'Driver Date' },
                            { id: 'maxRefresh', name: 'Max Refresh' },
                            { id: 'minRefresh', name: 'Min Refresh' },
                            { id: 'deviceID', name: 'Device ID' },
                            {
                                id: 'videoMemoryType',
                                name: 'Video Memory Type',
                            },
                            {
                                id: 'videoArchitecture',
                                name: 'Video Architecture',
                            },
                            {
                                id: 'videoModeDescription',
                                name: 'Video Mode Description',
                            },
                            { id: 'status', name: 'Status' },
                            { id: 'statusInfo', name: 'Status Info' },
                        ],
                    },
                    agentInformation: {
                        gridItemColumns: 2,
                        options: [
                            {
                                id: 'scriptingLibrary',
                                name: 'Scripting Library',
                            },
                            {
                                id: 'softwareInventory',
                                name: 'Software Inventory',
                            },
                            { id: 'eventScanner', name: 'Event Scanner' },
                            {
                                id: 'packageInstaller',
                                name: 'Package Installer',
                            },
                            {
                                id: 'hardwareInventory',
                                name: 'Hardware Inventory',
                            },
                            { id: 'collected', name: 'Collected' },
                        ],
                    },
                    scsiControllers: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'availability', name: 'Availability' },
                            { id: 'driverName', name: 'Driver Name' },
                            {
                                id: 'protocolSupported',
                                name: 'Protocol Supported',
                            },
                            { id: 'deviceID', name: 'Device ID' },
                            { id: 'status', name: 'Status' },
                            { id: 'statusInfo', name: 'Status Info' },
                        ],
                    },
                    keyboards: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'caption', name: 'Caption' },
                            { id: 'availability', name: 'Availability' },
                            { id: 'keyboardLayout', name: 'Keyboard Layout' },
                            { id: 'functionKeys', name: 'Function Keys' },
                            { id: 'deviceID', name: 'Device ID' },
                            { id: 'status', name: 'Status' },
                            { id: 'statusInfo', name: 'Status Info' },
                        ],
                    },
                    computer: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'computerName', name: 'Computer Name' },
                            { id: 'systemType', name: 'System Type' },
                            { id: 'timeZone', name: 'Time Zone' },
                            { id: 'daylightSaving', name: 'Daylight Saving' },
                            { id: 'description', name: 'Description' },
                            { id: 'domain', name: 'Domain' },
                            { id: 'domainRole', name: 'Domain Role' },
                            {
                                id: 'keyboardPassword',
                                name: 'Keyboard Password',
                            },
                            { id: 'pcType', name: 'PC Type' },
                            { id: 'thermalState', name: 'Thermal State' },
                            { id: 'wakeUpType', name: 'WakeUp Type' },
                            { id: 'status', name: 'Status' },
                            { id: 'uuid', name: 'UUID' },
                            { id: 'serialNumber', name: 'Serial Number' },
                            {
                                id: 'baseBoardProduct',
                                name: 'Base Board Product',
                            },
                            { id: 'systemSKU', name: 'System SKU' },
                        ],
                    },
                    monitors: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'modelName', name: 'Model Name' },
                            { id: 'serialNumber', name: 'Serial Number' },
                            { id: 'maxResolutionX', name: 'Max Resolution X' },
                            { id: 'maxResolutionY', name: 'Max Resolution Y' },
                            { id: 'outputType', name: 'Output Type' },
                            { id: 'inputType', name: 'Input Type' },
                            { id: 'monitorWidth', name: 'Monitor Width' },
                            { id: 'monitorHeight', name: 'Monitor Height' },
                            { id: 'screenSize', name: 'Screen Size' },
                            {
                                id: 'activeOffSupported',
                                name: 'Active Off Supported',
                            },
                            {
                                id: 'standbySupported',
                                name: 'Standby Supported',
                            },
                            {
                                id: 'suspendSupported',
                                name: 'Suspend Supported',
                            },
                            { id: 'deviceID', name: 'Device ID' },
                            { id: 'productCode', name: 'Product Code' },
                            { id: 'productionWeek', name: 'Production Week' },
                            { id: 'productionYear', name: 'Production Year' },
                        ],
                    },
                    disks: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'size', name: 'Size' },
                            { id: 'serialNumber', name: 'Serial Number' },
                            { id: 'mediaType', name: 'Media Type' },
                            { id: 'busType', name: 'Bus Type' },
                            { id: 'healthStatus', name: 'Health Status' },
                            { id: 'capabilities', name: 'Capabilities' },
                            { id: 'availability', name: 'Availability' },
                            { id: 'deviceID', name: 'Device ID' },
                            { id: 'status', name: 'Status' },
                            { id: 'statusInfo', name: 'Status Info' },
                        ],
                    },
                    operatingSystem: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'edition', name: 'Edition' },
                            {
                                id: 'updateBuildRelease',
                                name: 'Update Build Release',
                            },
                            { id: 'bootDevice', name: 'Boot Device' },
                            { id: 'buildType', name: 'Build Type' },
                            { id: 'encryptionLevel', name: 'Encryption Level' },
                            { id: 'installDate', name: 'Install Date' },
                            { id: 'serviceStartUp', name: 'Service StartUp' },
                            { id: 'organization', name: 'Organization' },
                            { id: 'architecture', name: 'Architecture' },
                            { id: 'serialNumber', name: 'Serial Number' },
                            { id: 'locale', name: 'Locale' },
                            { id: 'language', name: 'Language' },
                            { id: 'servicePack', name: 'Service Pack' },
                            { id: 'systemDirectory', name: 'System Directory' },
                            {
                                id: 'hasPendingReboot',
                                name: 'Has Pending Reboot',
                            },
                        ],
                    },
                    mice: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'pointingType', name: 'Pointing Type' },
                            { id: 'interface', name: 'Interface' },
                            { id: 'deviceID', name: 'Device ID' },
                            { id: 'status', name: 'Status' },
                            { id: 'statusInfo', name: 'Status Info' },
                        ],
                    },
                    networkAdapters: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'type', name: 'Type' },
                            { id: 'description', name: 'Description' },
                            {
                                id: 'operationalStatus',
                                name: 'Operational Status',
                            },
                            { id: 'physicalAddress', name: 'Physical Address' },
                            { id: 'speed', name: 'Speed' },
                            {
                                id: 'supportsMulticast',
                                name: 'Supports Multicast',
                            },
                            { id: 'isReceiveOnly', name: 'Is Receive Only' },
                            { id: 'dhcpEnabled', name: 'DHCP Enabled' },
                            { id: 'mtu', name: 'MTU' },
                            { id: 'apipaEnabled', name: 'APIPA Enabled' },
                            { id: 'apipaActive', name: 'APIPA Active' },
                            {
                                id: 'forwardingEnabled',
                                name: 'Forwarding Enabled',
                            },
                            { id: 'usesWINS', name: 'Uses WINS' },
                            { id: 'ipv4Address', name: 'IPv4 Address' },
                            { id: 'ipv6Address', name: 'IPv6 Address' },
                            { id: 'defaultGateway', name: 'Default Gateway' },
                            { id: 'dhcpAddress', name: 'DHCP Address' },
                            {
                                id: 'isDynamicDNSEnabled',
                                name: 'Is Dynamic DNS Enabled',
                            },
                            { id: 'dnsServers', name: 'DNS Servers' },
                        ],
                    },
                    processors: {
                        gridItemColumns: 2,
                        options: [
                            { id: 'name', name: 'Name' },
                            { id: 'manufacturer', name: 'Manufacturer' },
                            { id: 'clockSpeed', name: 'Clock Speed' },
                            { id: 'cores', name: 'Cores' },
                            { id: 'threads', name: 'Threads' },
                            { id: 'addressWidth', name: 'Address Width' },
                            { id: 'family', name: 'Family' },
                            { id: 'architecture', name: 'Architecture' },
                            { id: 'l2CacheSize', name: 'L2CacheSize' },
                            { id: 'l3CacheSize', name: 'L3CacheSize' },
                            { id: 'processorType', name: 'Processor Type' },
                            { id: 'status', name: 'Status' },
                            { id: 'statusInfo', name: 'Status Info' },
                        ],
                    },
                },
            },
            driverInventory: {
                gridItemColumns: 2,
                options: DRIVER_INVENTORY_CHILDREN_OPTIONS,
            },
        },
    },
    leafs: [
        /** DEVICE LEAFS */
        {
            id: 'name',
            branchDependencies: ['endpoint', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Endpoint name',
            primary: true,
        },
        {
            id: 'deviceType',
            branchDependencies: ['endpoint', 'deviceType'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Endpoint type',
        },
        {
            id: 'online',
            branchDependencies: ['endpoint', 'online'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
        },
        {
            id: 'virtual',
            branchDependencies: ['endpoint', 'virtual'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
        },
        {
            id: 'agentVersion',
            branchDependencies: ['endpoint', 'agentVersion'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Agent version',
        },
        {
            id: 'osVersion',
            branchDependencies: ['endpoint', 'osVersion'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS version',
        },
        {
            id: 'motherboardUuid',
            branchDependencies: ['endpoint', 'motherboardUuid'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Motherboard UUID',
        },
        {
            id: 'motherboardSerial',
            branchDependencies: ['endpoint', 'motherboardSerial'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Motherboard serial number',
        },
        {
            id: 'osSerial',
            branchDependencies: ['endpoint', 'osSerial'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS serial number',
        },
        {
            id: 'serviceStartUp',
            branchDependencies: ['endpoint', 'serviceStartUp'],
            type: LEAF_TYPE.DATE,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
        },
        {
            id: 'osStartUp',
            branchDependencies: ['endpoint', 'osStartUp'],
            type: LEAF_TYPE.DATE,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
        },
        {
            id: 'platform',
            branchDependencies: ['endpoint', 'platform'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            multiple: true,
            options: [],
            placeholder: 'Platform',
        },
        {
            id: 'osName',
            branchDependencies: ['endpoint', 'osName'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS Name',
        },
        {
            id: 'tags',
            branchDependencies: ['endpoint', 'tags'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ALL_IN_ARRAY,
            multiple: true,
            placeholder: 'Tags',
            options: [],
            defaultValue: [],
        },
        {
            id: 'software.severity',
            branchDependencies: ['endpoint', 'patchseverity'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            multiple: true,
            numeric: true,
            options: [
                {
                    id: 0,
                    name: 'None',
                },
                {
                    id: 1,
                    name: 'Low',
                },
                {
                    id: 2,
                    name: 'Medium',
                },
                {
                    id: 4,
                    name: 'High',
                },
                {
                    id: 8,
                    name: 'Critical',
                },
            ],
            placeholder: 'Patch severity',
        },
        {
            id: 'state',
            branchDependencies: ['endpoint', 'state'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ALL_IN_ARRAY,
            defaultValue: [],
            options: [
                {
                    id: DEVICE_STATE.BUSY,
                    name: 'Busy',
                },
                {
                    id: DEVICE_STATE.READY,
                    name: 'Ready',
                },
            ],
            placeholder: 'Endpoint state',
        },
        {
            id: 'software.compliant',
            branchDependencies: ['endpoint', 'softwareCompliant'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: false,
        },
        {
            id: 'software.patchCount',
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            branchDependencies: ['endpoint', 'patchCount'],
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: '',
        },
        {
            id: 'software.failedPatchCount',
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            branchDependencies: ['endpoint', 'failedPatchCount'],
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: '',
        },
        {
            id: 'driver.compliant',
            branchDependencies: ['endpoint', 'driverCompliant'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: false,
        },
        {
            id: 'driver.isSupported',
            branchDependencies: ['endpoint', 'driverSupported'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: false,
        },

        /** SOFTWARE LEAFS */
        {
            id: 'softwareInventory.Name',
            branchDependencies: ['softwareInventory', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Software name',
        },
        {
            id: 'softwareInventory.UserAccount',
            branchDependencies: ['softwareInventory', 'userAccount'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'User account',
        },
        {
            id: 'softwareInventory.Version',
            branchDependencies: ['softwareInventory', 'version'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Software version',
        },
        {
            id: 'softwareInventory.AppCode',
            branchDependencies: ['softwareInventory', 'appCode'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'App code',
        },
        {
            id: 'softwareInventory.Size',
            branchDependencies: ['softwareInventory', 'size'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            unit: LEAF_UNIT.BYTES,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Software size',
        },
        {
            id: 'softwareInventory.Language',
            branchDependencies: ['softwareInventory', 'language'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            multiple: true,
            placeholder: 'Language',
            options: [],
            defaultValue: [],
        },
        {
            id: 'softwareInventory.InstallDate',
            branchDependencies: ['softwareInventory', 'installDate'],
            type: LEAF_TYPE.DATE,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Software Install Date',
        },
        {
            id: 'softwareInventory.IsSystemComponent',
            branchDependencies: ['softwareInventory', 'isSystemComponent'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: false,
        },
        {
            id: 'softwareInventory.Platform',
            branchDependencies: ['softwareInventory', 'platform'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            numeric: true,
            options: [
                {
                    id: 32,
                    name: '32-bit',
                },
                {
                    id: 64,
                    name: '64-bit',
                },
            ],
            placeholder: 'Software Platform',
        },
        {
            id: 'softwareInventory.Publisher',
            branchDependencies: ['softwareInventory', 'publisher'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Software publisher',
        },
        {
            id: 'softwareInventory.Uninstall',
            branchDependencies: ['softwareInventory', 'uninstallCommand'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Software Uninstall Command',
        },
        {
            id: 'softwareInventory.UpgradeCode',
            branchDependencies: ['softwareInventory', 'upgradeCode'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Software upgrade code',
        },

        /** HARDWARE LEAFS */
        {
            id: 'hardwareInventory.Bios Information.Name',
            branchDependencies: ['hardwareInventory', 'biosInformation', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Bios Name',
        },
        {
            id: 'hardwareInventory.Bios Information.Manufacturer',
            branchDependencies: ['hardwareInventory', 'biosInformation', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Bios Manufacturer',
        },
        {
            id: 'hardwareInventory.Bios Information.Version',
            branchDependencies: ['hardwareInventory', 'biosInformation', 'version'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Bios Version',
        },
        {
            id: 'hardwareInventory.Bios Information.Release Date.value',
            branchDependencies: ['hardwareInventory', 'biosInformation', 'releaseDate'],
            type: LEAF_TYPE.DATE,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Bios Release Data',
        },
        {
            id: 'hardwareInventory.Bios Information.Serial Number',
            branchDependencies: ['hardwareInventory', 'biosInformation', 'serialNumber'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Bios Serial Number',
        },
        {
            id: 'hardwareInventory.Bios Information.SMBios Major Version',
            branchDependencies: ['hardwareInventory', 'biosInformation', 'smBiosMajorVersion'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'SMBios Major Version',
        },
        {
            id: 'hardwareInventory.Bios Information.SMBios Minor Version',
            branchDependencies: ['hardwareInventory', 'biosInformation', 'smBiosMinorVersion'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'SMBios Minor Version',
        },
        {
            id: 'hardwareInventory.Bios Information.System Bios Major Version',
            branchDependencies: ['hardwareInventory', 'biosInformation', 'systemBiosMajorVersion'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'System Bios Major Version',
        },
        {
            id: 'hardwareInventory.Bios Information.System Bios Minor Version',
            branchDependencies: ['hardwareInventory', 'biosInformation', 'systemBiosMinorVersion'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'System Bios Minor Version',
        },
        {
            id: 'hardwareInventory.Sound Devices.Name',
            branchDependencies: ['hardwareInventory', 'soundDevices', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Sound Device Name',
        },
        {
            id: 'hardwareInventory.Sound Devices.Manufacturer',
            branchDependencies: ['hardwareInventory', 'soundDevices', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Sound Device Manufacturer',
        },
        {
            id: 'hardwareInventory.Sound Devices.Device ID',
            branchDependencies: ['hardwareInventory', 'soundDevices', 'deviceID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Sound Device ID',
        },
        {
            id: 'hardwareInventory.Memory Banks.Name',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Memory Bank Name',
        },
        {
            id: 'hardwareInventory.Memory Banks.Manufacturer',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Memory Bank Manufacturer',
        },
        {
            id: 'hardwareInventory.Memory Banks.Capacity.value',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'capacity'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Memory Bank Capacity',
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Memory Banks.Speed.value',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'speed'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Memory Bank Speed',
            unit: LEAF_UNIT.HZ,
        },
        {
            id: 'hardwareInventory.Memory Banks.Socket',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'socket'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Memory Socket',
        },
        {
            id: 'hardwareInventory.Memory Banks.Memory Type',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'memoryType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Memory Type',
        },
        {
            id: 'hardwareInventory.Memory Banks.Type Detail',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'typeDetail'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Memory Type Detail',
        },
        {
            id: 'hardwareInventory.Memory Banks.Form Factor',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'formFactor'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Memory Form Factor',
        },
        {
            id: 'hardwareInventory.Memory Banks.Serial Number',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'serialNumber'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Memory Serial Number',
        },
        {
            id: 'hardwareInventory.Memory Banks.Part Number',
            branchDependencies: ['hardwareInventory', 'memoryBanks', 'partNumber'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Memory Part Number',
        },
        {
            id: 'hardwareInventory.Memory.Name',
            branchDependencies: ['hardwareInventory', 'hardwareMemory', 'name'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Memory name',
        },
        {
            id: 'hardwareInventory.Memory.Location',
            branchDependencies: ['hardwareInventory', 'hardwareMemory', 'location'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Memory Location',
        },
        {
            id: 'hardwareInventory.Memory.Total Banks',
            branchDependencies: ['hardwareInventory', 'hardwareMemory', 'totalBanks'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Memory Total Banks',
        },
        {
            id: 'hardwareInventory.Memory.Available Banks',
            branchDependencies: ['hardwareInventory', 'hardwareMemory', 'availableBanks'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Memory Available Banks',
        },
        {
            id: 'hardwareInventory.Memory.Max Memory.value',
            branchDependencies: ['hardwareInventory', 'hardwareMemory', 'maxMemory'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Memory Max Memory',
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Memory.Total Memory.value',
            branchDependencies: ['hardwareInventory', 'hardwareMemory', 'totalMemory'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Memory Total Memory',
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Trusted Platform Module.Name',
            branchDependencies: ['hardwareInventory', 'trustedPlatformModule', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Trusted Platform Module Name',
        },
        {
            id: 'hardwareInventory.Trusted Platform Module.Enabled',
            branchDependencies: ['hardwareInventory', 'trustedPlatformModule', 'enabled'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Trusted Platform Module Enabled',
        },
        {
            id: 'hardwareInventory.Trusted Platform Module.Activated',
            branchDependencies: ['hardwareInventory', 'trustedPlatformModule', 'activated'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Trusted Platform Module Activated',
        },
        {
            id: 'hardwareInventory.Trusted Platform Module.Owned',
            branchDependencies: ['hardwareInventory', 'trustedPlatformModule', 'owned'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Trusted Platform Module Owned',
        },
        {
            id: 'hardwareInventory.Trusted Platform Module.Manufacturer',
            branchDependencies: ['hardwareInventory', 'trustedPlatformModule', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Trusted Platform Module Manufacturer',
        },
        {
            id: 'hardwareInventory.Trusted Platform Module.Physical Version',
            branchDependencies: ['hardwareInventory', 'trustedPlatformModule', 'physicalVersion'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Trusted Platform Module Physical Version',
        },
        {
            id: 'hardwareInventory.Trusted Platform Module.Special Version',
            branchDependencies: ['hardwareInventory', 'trustedPlatformModule', 'specialVersion'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Trusted Platform Module Special Version',
        },
        {
            id: 'hardwareInventory.IP Configuration.Name',
            branchDependencies: ['hardwareInventory', 'ipConfiguration', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'IP Configuration Name',
        },
        {
            id: 'hardwareInventory.IP Configuration.Domain Name',
            branchDependencies: ['hardwareInventory', 'ipConfiguration', 'domainName'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'IP Configuration Domain Name',
        },
        {
            id: 'hardwareInventory.IP Configuration.DHCP Scope',
            branchDependencies: ['hardwareInventory', 'ipConfiguration', 'dhcpScope'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'IP Configuration DHCP Scope',
        },
        {
            id: 'hardwareInventory.IP Configuration.WINS Proxy Enabled',
            branchDependencies: ['hardwareInventory', 'ipConfiguration', 'winsProxyEnabled'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'IP Configuration WINS Proxy Enabled',
        },
        {
            id: 'hardwareInventory.IP Configuration.Node Type',
            branchDependencies: ['hardwareInventory', 'ipConfiguration', 'nodeType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'IP Configuration Node Type',
        },
        {
            id: 'hardwareInventory.Drives.Name',
            branchDependencies: ['hardwareInventory', 'drives', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Drive Node Name',
        },
        {
            id: 'hardwareInventory.Drives.Type',
            branchDependencies: ['hardwareInventory', 'drives', 'type'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Drive Type',
        },
        {
            id: 'hardwareInventory.Drives.Media',
            branchDependencies: ['hardwareInventory', 'drives', 'media'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Drive Media',
        },
        {
            id: 'hardwareInventory.Drives.File System',
            branchDependencies: ['hardwareInventory', 'drives', 'fileSystem'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Drive File System',
        },
        {
            id: 'hardwareInventory.Drives.Access',
            branchDependencies: ['hardwareInventory', 'drives', 'access'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Drive Access',
        },
        {
            id: 'hardwareInventory.Drives.Boot Device',
            branchDependencies: ['hardwareInventory', 'drives', 'bootDevice'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Drive Boot Device',
        },
        {
            id: 'hardwareInventory.Drives.Encrypted',
            branchDependencies: ['hardwareInventory', 'drives', 'encrypted'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Drive Encrypted',
        },
        {
            id: 'hardwareInventory.Drives.Compressed',
            branchDependencies: ['hardwareInventory', 'drives', 'compressed'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Drive Compressed',
        },
        {
            id: 'hardwareInventory.Drives.Free Space.value',
            branchDependencies: ['hardwareInventory', 'drives', 'freeSpace'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Drive Free Space',
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Drives.Size.value',
            branchDependencies: ['hardwareInventory', 'drives', 'size'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Drive Size',
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Drives.Serial Number',
            branchDependencies: ['hardwareInventory', 'drives', 'serialNumber'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Drive Serial Number',
        },
        {
            id: 'hardwareInventory.Drives.Supports Quotas',
            branchDependencies: ['hardwareInventory', 'drives', 'supportsQuotas'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Drive Supports Quotas',
        },
        {
            id: 'hardwareInventory.Drives.Supports Compression',
            branchDependencies: ['hardwareInventory', 'drives', 'supportsCompression'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Drive Supports Compression',
        },
        {
            id: 'hardwareInventory.Batteries.Name',
            branchDependencies: ['hardwareInventory', 'battery', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Battery Name',
        },
        {
            id: 'hardwareInventory.Batteries.Chemistry',
            branchDependencies: ['hardwareInventory', 'battery', 'chemistry'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Battery Chemistry',
        },
        {
            id: 'hardwareInventory.Batteries.Manufacturer',
            branchDependencies: ['hardwareInventory', 'battery', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Battery manufacturer',
        },
        {
            id: 'hardwareInventory.Batteries.Serial Number',
            branchDependencies: ['hardwareInventory', 'battery', 'serialNumber'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Battery Serial Number',
        },
        {
            id: 'hardwareInventory.Batteries.Model',
            branchDependencies: ['hardwareInventory', 'battery', 'model'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Battery Model',
        },
        {
            id: 'hardwareInventory.Batteries.Availability',
            branchDependencies: ['hardwareInventory', 'battery', 'availability'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Battery Availability',
        },
        {
            id: 'hardwareInventory.Batteries.Device ID',
            branchDependencies: ['hardwareInventory', 'battery', 'deviceID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Battery Device ID',
        },
        {
            id: 'hardwareInventory.Batteries.Status',
            branchDependencies: ['hardwareInventory', 'battery', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Battery Status',
        },
        {
            id: 'hardwareInventory.Batteries.Voltage.value',
            branchDependencies: ['hardwareInventory', 'battery', 'voltage'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            unit: LEAF_UNIT.VOLT,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Battery voltage',
        },
        {
            id: 'hardwareInventory.Batteries.Capacity.value',
            branchDependencies: ['hardwareInventory', 'battery', 'capacity'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            label: 'Battery Capacity',
            unit: LEAF_UNIT.MAH,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Battery capacity',
        },
        {
            id: 'hardwareInventory.Batteries.Maximum Capacity.value',
            branchDependencies: ['hardwareInventory', 'battery', 'maximumCapacity'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Battery Maximum Capacity',
            unit: LEAF_UNIT.PERCENT,
        },
        {
            id: 'hardwareInventory.Display Adapters.Name',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'GPU Name',
        },
        {
            id: 'hardwareInventory.Display Adapters.Manufacturer',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'GPU Manufacturer',
        },
        {
            id: 'hardwareInventory.Display Adapters.GPU Memory.value',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'gpuMemory'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            placeholder: 'GPU Memory',
            defaultValue: {
                min: '',
                max: '',
            },
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Display Adapters.Driver Version',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'driverVersion'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'GPU Driver Version',
        },
        {
            id: 'hardwareInventory.Display Adapters.Driver date.value',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'driverDate'],
            type: LEAF_TYPE.DATE,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'GPU Driver Date',
        },
        {
            id: 'hardwareInventory.Display Adapters.Max Refresh Rate',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'maxRefresh'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'GPU Max Refresh Rate',
        },
        {
            id: 'hardwareInventory.Display Adapters.Min Refresh Rate',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'minRefresh'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'GPU Min Refresh Rate',
        },
        {
            id: 'hardwareInventory.Display Adapters.Device ID',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'deviceID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'GPU Device ID',
        },
        {
            id: 'hardwareInventory.Display Adapters.Video Memory Type',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'videoMemoryType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'GPU Memory Type',
        },
        {
            id: 'hardwareInventory.Display Adapters.Video Architecture',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'videoArchitecture'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'GPU Video Architecture',
        },
        {
            id: 'hardwareInventory.Display Adapters.Video Mode Description',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'videoModeDescription'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'GPU Video Mode Description',
        },
        {
            id: 'hardwareInventory.Display Adapters.Status',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'GPU Status',
        },
        {
            id: 'hardwareInventory.Display Adapters.Status Info',
            branchDependencies: ['hardwareInventory', 'displayAdapters', 'statusInfo'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'GPU Status Info',
        },
        {
            id: 'hardwareInventory.SCSI Controllers.Name',
            branchDependencies: ['hardwareInventory', 'scsiControllers', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'SCSI Name',
        },
        {
            id: 'hardwareInventory.SCSI Controllers.Manufacturer',
            branchDependencies: ['hardwareInventory', 'scsiControllers', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'SCSI Manufacturer',
        },
        {
            id: 'hardwareInventory.SCSI Controllers.Availability',
            branchDependencies: ['hardwareInventory', 'scsiControllers', 'availability'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'SCSI Availability',
        },
        {
            id: 'hardwareInventory.SCSI Controllers.Driver Name',
            branchDependencies: ['hardwareInventory', 'scsiControllers', 'driverName'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'SCSI Driver Name',
        },
        {
            id: 'hardwareInventory.SCSI Controllers.Protocol Supported',
            branchDependencies: ['hardwareInventory', 'scsiControllers', 'protocolSupported'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'SCSI Protocol Supported',
        },
        {
            id: 'hardwareInventory.SCSI Controllers.Device ID',
            branchDependencies: ['hardwareInventory', 'scsiControllers', 'deviceID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'SCSI Device ID',
        },
        {
            id: 'hardwareInventory.SCSI Controllers.Status',
            branchDependencies: ['hardwareInventory', 'scsiControllers', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'SCSI Status',
        },
        {
            id: 'hardwareInventory.SCSI Controllers.Status Info',
            branchDependencies: ['hardwareInventory', 'scsiControllers', 'statusInfo'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'SCSI Status Info',
        },
        {
            id: 'hardwareInventory.Keyboards.Name',
            branchDependencies: ['hardwareInventory', 'keyboards', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Keyboard Name',
        },
        {
            id: 'hardwareInventory.Keyboards.Manufacturer',
            branchDependencies: ['hardwareInventory', 'keyboards', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Keyboard Manufacturer',
        },
        {
            id: 'hardwareInventory.Keyboards.Caption',
            branchDependencies: ['hardwareInventory', 'keyboards', 'caption'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Keyboard Caption',
        },
        {
            id: 'hardwareInventory.Keyboards.Availability',
            branchDependencies: ['hardwareInventory', 'keyboards', 'availability'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Keyboard Availability',
        },
        {
            id: 'hardwareInventory.Keyboards.Keyboard Layout.value',
            branchDependencies: ['hardwareInventory', 'keyboards', 'keyboardLayout'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Keyboard Layout',
        },
        {
            id: 'hardwareInventory.Keyboards.Function Keys',
            branchDependencies: ['hardwareInventory', 'keyboards', 'functionKeys'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Keyboard Function Keys',
        },
        {
            id: 'hardwareInventory.Keyboards.Device ID',
            branchDependencies: ['hardwareInventory', 'keyboards', 'deviceID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Keyboard Device ID',
        },
        {
            id: 'hardwareInventory.Keyboards.Status',
            branchDependencies: ['hardwareInventory', 'keyboards', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Keyboard Status',
        },
        {
            id: 'hardwareInventory.Keyboards.Status Info',
            branchDependencies: ['hardwareInventory', 'keyboards', 'statusInfo'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Keyboard Status Info',
        },
        {
            id: 'hardwareInventory.Computer.Name',
            branchDependencies: ['hardwareInventory', 'computer', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Name',
        },
        {
            id: 'hardwareInventory.Computer.Manufacturer',
            branchDependencies: ['hardwareInventory', 'computer', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Manufacturer',
        },
        {
            id: 'hardwareInventory.Computer.Computer Name',
            branchDependencies: ['hardwareInventory', 'computer', 'computerName'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Computer Name',
        },
        {
            id: 'hardwareInventory.Computer.System Type',
            branchDependencies: ['hardwareInventory', 'computer', 'systemType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Computer System Type',
        },
        {
            id: 'hardwareInventory.Computer.Time Zone',
            branchDependencies: ['hardwareInventory', 'computer', 'timeZone'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Time Zone',
        },
        {
            id: 'hardwareInventory.Computer.Daylight Saving',
            branchDependencies: ['hardwareInventory', 'computer', 'daylightSaving'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Computer Daylight Saving',
        },
        {
            id: 'hardwareInventory.Computer.Description',
            branchDependencies: ['hardwareInventory', 'computer', 'description'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Description',
        },
        {
            id: 'hardwareInventory.Computer.Domain',
            branchDependencies: ['hardwareInventory', 'computer', 'domain'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Domain',
        },
        {
            id: 'hardwareInventory.Computer.Domain Role',
            branchDependencies: ['hardwareInventory', 'computer', 'domainRole'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Domain Role',
        },
        {
            id: 'hardwareInventory.Computer.Keyboard Password',
            branchDependencies: ['hardwareInventory', 'computer', 'keyboardPassword'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Keyboard Password',
        },
        {
            id: 'hardwareInventory.Computer.PC Type',
            branchDependencies: ['hardwareInventory', 'computer', 'pcType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Computer PC Type',
        },
        {
            id: 'hardwareInventory.Computer.Thermal State',
            branchDependencies: ['hardwareInventory', 'computer', 'thermalState'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Computer Thermal State',
        },
        {
            id: 'hardwareInventory.Computer.WakeUp Type',
            branchDependencies: ['hardwareInventory', 'computer', 'wakeUpType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Computer WakeUp Type',
        },
        {
            id: 'hardwareInventory.Computer.Status',
            branchDependencies: ['hardwareInventory', 'computer', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Computer Status',
        },
        {
            id: 'hardwareInventory.Computer.UUID',
            branchDependencies: ['hardwareInventory', 'computer', 'uuid'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer UUID',
        },
        {
            id: 'hardwareInventory.Computer.Serial Number',
            branchDependencies: ['hardwareInventory', 'computer', 'serialNumber'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Serial Number',
        },
        {
            id: 'hardwareInventory.Computer.BaseBoardProduct',
            branchDependencies: ['hardwareInventory', 'computer', 'baseBoardProduct'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer Base Board Product ID',
        },
        {
            id: 'hardwareInventory.Computer.SystemSKU',
            branchDependencies: ['hardwareInventory', 'computer', 'systemSKU'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Computer System SKU',
        },
        {
            id: 'hardwareInventory.Monitors.Name',
            branchDependencies: ['hardwareInventory', 'monitors', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Monitor Name',
        },
        {
            id: 'hardwareInventory.Monitors.Manufacturer',
            branchDependencies: ['hardwareInventory', 'monitors', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Monitor Manufacturer',
        },
        {
            id: 'hardwareInventory.Monitors.Model Name',
            branchDependencies: ['hardwareInventory', 'monitors', 'modelName'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Monitor Model Name',
        },
        {
            id: 'hardwareInventory.Monitors.Serial Number',
            branchDependencies: ['hardwareInventory', 'monitors', 'serialNumber'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Monitor Serial Number',
        },
        {
            id: 'hardwareInventory.Monitors.Max Resolution X',
            branchDependencies: ['hardwareInventory', 'monitors', 'maxResolutionX'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Monitor Max Resolution X',
        },
        {
            id: 'hardwareInventory.Monitors.Max Resolution Y',
            branchDependencies: ['hardwareInventory', 'monitors', 'maxResolutionY'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Monitor Max Resolution Y',
        },
        {
            id: 'hardwareInventory.Monitors.Output Type',
            branchDependencies: ['hardwareInventory', 'monitors', 'outputType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Monitor Output Type',
        },
        {
            id: 'hardwareInventory.Monitors.Input Type',
            branchDependencies: ['hardwareInventory', 'monitors', 'inputType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Monitor Input Type',
        },
        {
            id: 'hardwareInventory.Monitors.Monitor Width.value',
            branchDependencies: ['hardwareInventory', 'monitors', 'monitorWidth'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Monitor  Width',
            unit: LEAF_UNIT.CM,
        },
        {
            id: 'hardwareInventory.Monitors.Monitor Height.value',
            branchDependencies: ['hardwareInventory', 'monitors', 'monitorHeight'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Monitor Height',
            unit: LEAF_UNIT.CM,
        },
        {
            id: 'hardwareInventory.Monitors.Screen Size.value',
            branchDependencies: ['hardwareInventory', 'monitors', 'screenSize'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Monitor Size',
            unit: LEAF_UNIT.INCH,
        },
        {
            id: 'hardwareInventory.Monitors.Active Off Supported',
            branchDependencies: ['hardwareInventory', 'monitors', 'activeOffSupported'],
            type: LEAF_TYPE.BOOLEAN,
            placeholder: 'Monitor Active Off Supported',
            defaultValue: true,
        },
        {
            id: 'hardwareInventory.Monitors.Standby Supported',
            branchDependencies: ['hardwareInventory', 'monitors', 'standbySupported'],
            type: LEAF_TYPE.BOOLEAN,
            placeholder: 'Monitor Standby Supported',
            defaultValue: true,
        },
        {
            id: 'hardwareInventory.Monitors.Suspend Supported',
            branchDependencies: ['hardwareInventory', 'monitors', 'suspendSupported'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Monitor Suspend Supported',
        },
        {
            id: 'hardwareInventory.Monitors.Device ID',
            branchDependencies: ['hardwareInventory', 'monitors', 'deviceID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Monitor Device ID',
        },
        {
            id: 'hardwareInventory.Monitors.Product Code',
            branchDependencies: ['hardwareInventory', 'monitors', 'productCode'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Monitor Product Code',
        },
        {
            id: 'hardwareInventory.Monitors.Production Week',
            branchDependencies: ['hardwareInventory', 'monitors', 'productionWeek'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Monitor Production Week',
        },
        {
            id: 'hardwareInventory.Monitors.Production Year',
            branchDependencies: ['hardwareInventory', 'monitors', 'productionYear'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Monitor Production Year',
        },
        {
            id: 'hardwareInventory.Disks.Name',
            branchDependencies: ['hardwareInventory', 'disks', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Disk Name',
        },
        {
            id: 'hardwareInventory.Disks.Manufacturer',
            branchDependencies: ['hardwareInventory', 'disks', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Disk Manufacturer',
        },
        {
            id: 'hardwareInventory.Disks.Size.value',
            branchDependencies: ['hardwareInventory', 'disks', 'size'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Disk Size',
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Disks.Serial Number',
            branchDependencies: ['hardwareInventory', 'disks', 'serialNumber'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Disk Serial Number',
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Disks.Media Type',
            branchDependencies: ['hardwareInventory', 'disks', 'mediaType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Disk Media Type',
        },
        {
            id: 'hardwareInventory.Disks.Bus Type',
            branchDependencies: ['hardwareInventory', 'disks', 'busType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Disk Bus Type',
        },
        {
            id: 'hardwareInventory.Disks.Health Status',
            branchDependencies: ['hardwareInventory', 'disks', 'healthStatus'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Disk Health Status',
        },
        {
            id: 'hardwareInventory.Disks.Capabilities',
            branchDependencies: ['hardwareInventory', 'disks', 'capabilities'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Disk Capabilities',
        },
        {
            id: 'hardwareInventory.Disks.Availability',
            branchDependencies: ['hardwareInventory', 'disks', 'availability'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Disk Availability',
        },
        {
            id: 'hardwareInventory.Disks.Device ID',
            branchDependencies: ['hardwareInventory', 'disks', 'deviceID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Disk Device ID',
        },
        {
            id: 'hardwareInventory.Disks.Status',
            branchDependencies: ['hardwareInventory', 'disks', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Disk Status',
        },
        {
            id: 'hardwareInventory.Disks.Status Info',
            branchDependencies: ['hardwareInventory', 'disks', 'statusInfo'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Disk Status Info',
        },
        {
            id: 'hardwareInventory.Operating System.Edition',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'edition'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS Edition',
        },
        {
            id: 'hardwareInventory.Operating System.Update Build Release',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'updateBuildRelease'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'OS Update Build Release',
        },
        {
            id: 'hardwareInventory.Operating System.Boot Device',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'bootDevice'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS Boot Device',
        },
        {
            id: 'hardwareInventory.Operating System.Build Type',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'buildType'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS Build Type',
        },
        {
            id: 'hardwareInventory.Operating System.Encryption Level.value',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'encryptionLevel'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'OS Encryption Level',
            unit: LEAF_UNIT.BIT,
        },
        {
            id: 'hardwareInventory.Operating System.Install Date.value',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'installDate'],
            type: LEAF_TYPE.DATE,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'OS Install Date',
        },
        {
            id: 'hardwareInventory.Operating System.Service StartUp.value',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'serviceStartUp'],
            type: LEAF_TYPE.DATE,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'OS Service StartUp',
        },
        {
            id: 'hardwareInventory.Operating System.Organization',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'organization'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS Organization',
        },
        {
            id: 'hardwareInventory.Operating System.Architecture',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'architecture'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS Architecture',
        },
        {
            id: 'hardwareInventory.Operating System.Serial Number',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'serialNumber'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS Serial Number',
        },
        {
            id: 'hardwareInventory.Operating System.Locale.value',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'locale'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'OS Locale',
        },
        {
            id: 'hardwareInventory.Operating System.Language.value',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'language'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'OS Language',
        },
        {
            id: 'hardwareInventory.Operating System.Service Pack',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'servicePack'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS Service Pack',
        },
        {
            id: 'hardwareInventory.Operating System.System Directory',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'systemDirectory'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'OS System Directory',
        },
        {
            id: 'hardwareInventory.Operating System.Has Pending Reboot',
            branchDependencies: ['hardwareInventory', 'operatingSystem', 'hasPendingReboot'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Has Pending Reboot',
        },
        {
            id: 'hardwareInventory.Mice.Name',
            branchDependencies: ['hardwareInventory', 'mice', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Mouse Name',
        },
        {
            id: 'hardwareInventory.Mice.Manufacturer',
            branchDependencies: ['hardwareInventory', 'mice', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Mouse Manufacturer',
        },
        {
            id: 'hardwareInventory.Mice.Pointing Type',
            branchDependencies: ['hardwareInventory', 'mice', 'pointingType'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Mouse Pointing Type',
        },
        {
            id: 'hardwareInventory.Mice.Interface',
            branchDependencies: ['hardwareInventory', 'mice', 'interface'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Mouse Interface',
        },
        {
            id: 'hardwareInventory.Mice.Device ID',
            branchDependencies: ['hardwareInventory', 'mice', 'deviceID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Mouse Device ID',
        },
        {
            id: 'hardwareInventory.Mice.Status',
            branchDependencies: ['hardwareInventory', 'mice', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Mouse Status',
        },
        {
            id: 'hardwareInventory.Mice.Status Info',
            branchDependencies: ['hardwareInventory', 'mice', 'statusInfo'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Mouse Status Info',
        },
        {
            id: 'hardwareInventory.Network Adapters.Name',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Network Adapter Name',
        },
        {
            id: 'hardwareInventory.Network Adapters.Type',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'type'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Network Adapter Type',
        },
        {
            id: 'hardwareInventory.Network Adapters.Description',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'description'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Network Adapter Description',
        },
        {
            id: 'hardwareInventory.Network Adapters.Operational Status',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'operationalStatus'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Network Adapter Operational Status',
        },
        {
            id: 'hardwareInventory.Network Adapters.Physical Address',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'physicalAddress'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Network Adapter Physical Address',
        },
        {
            id: 'hardwareInventory.Network Adapters.Speed.value',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'speed'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Network Adapter Speed',
            unit: LEAF_UNIT.BIT,
        },
        {
            id: 'hardwareInventory.Network Adapters.Suports Multicast',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'supportsMulticast'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Network Adapter Suports Multicast',
        },
        {
            id: 'hardwareInventory.Network Adapters.Is Receive Only',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'isReceiveOnly'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Network Adapter Is Receive Only',
        },
        {
            id: 'hardwareInventory.Network Adapters.DHCP Enabled',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'dhcpEnabled'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Network Adapter DHCP Enabled',
        },
        {
            id: 'hardwareInventory.Network Adapters.MTU',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'mtu'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Network Adapter MTU',
        },
        {
            id: 'hardwareInventory.Network Adapters.APIPA Enabled',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'apipaEnabled'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Network Adapter APIPA Enabled',
        },
        {
            id: 'hardwareInventory.Network Adapters.APIPA Active',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'apipaActive'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Network Adapter APIPA Active',
        },
        {
            id: 'hardwareInventory.Network Adapters.Forwarding Enabled',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'forwardingEnabled'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Network Adapter Forwarding Enabled',
        },
        {
            id: 'hardwareInventory.Network Adapters.Uses WINS',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'usesWINS'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Network Adapter Uses WINS',
        },
        {
            id: 'hardwareInventory.Network Adapters.IPv4 Address',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'ipv4Address'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Network Adapter IPv4 Address',
        },
        {
            id: 'hardwareInventory.Network Adapters.IPv6 Address',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'ipv6Address'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Network Adapter IPv6 Address',
        },
        {
            id: 'hardwareInventory.Network Adapters.Default Gateway',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'defaultGateway'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Network Adapter Default Gateway',
        },
        {
            id: 'hardwareInventory.Network Adapters.DHCP Address',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'dhcpAddress'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Network Adapter DHCP Address',
        },
        {
            id: 'hardwareInventory.Network Adapters.Is Dynamic DNS Enabled',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'isDynamicDNSEnabled'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
            placeholder: 'Network Adapter Is Dynamic DNS Enabled',
        },
        {
            id: 'hardwareInventory.Network Adapters.DNS Servers',
            branchDependencies: ['hardwareInventory', 'networkAdapters', 'dnsServers'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Network Adapter DNS Servers',
        },
        {
            id: 'hardwareInventory.Processors.Name',
            branchDependencies: ['hardwareInventory', 'processors', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Processor Name',
        },
        {
            id: 'hardwareInventory.Processors.Manufacturer',
            branchDependencies: ['hardwareInventory', 'processors', 'manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Processor Manufacturer',
        },
        {
            id: 'hardwareInventory.Processors.Clock Speed.value',
            branchDependencies: ['hardwareInventory', 'processors', 'clockSpeed'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Processor Clock Speed',
            unit: LEAF_UNIT.HZ,
        },
        {
            id: 'hardwareInventory.Processors.Cores',
            branchDependencies: ['hardwareInventory', 'processors', 'cores'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Processor Cores',
        },
        {
            id: 'hardwareInventory.Processors.Threads',
            branchDependencies: ['hardwareInventory', 'processors', 'threads'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Processor Threads',
        },
        {
            id: 'hardwareInventory.Processors.Address Width.value',
            branchDependencies: ['hardwareInventory', 'processors', 'addressWidth'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Processor Address Width',
            unit: LEAF_UNIT.BIT,
        },
        {
            id: 'hardwareInventory.Processors.Family',
            branchDependencies: ['hardwareInventory', 'processors', 'family'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Processor Family',
        },
        {
            id: 'hardwareInventory.Processors.Architecture',
            branchDependencies: ['hardwareInventory', 'processors', 'architecture'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            placeholder: 'Processor Architecture',
        },
        {
            id: 'hardwareInventory.Processors.L2CacheSize.value',
            branchDependencies: ['hardwareInventory', 'processors', 'l2CacheSize'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Processor L2CacheSize',
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Processors.L3CacheSize.value',
            branchDependencies: ['hardwareInventory', 'processors', 'l3CacheSize'],
            type: LEAF_TYPE.NUMBER,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: 'Processor L3CacheSize',
            unit: LEAF_UNIT.BYTES,
        },
        {
            id: 'hardwareInventory.Processors.Processor Type',
            branchDependencies: ['hardwareInventory', 'processors', 'processorType'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Processor Processor Type',
        },
        {
            id: 'hardwareInventory.Processors.Status',
            branchDependencies: ['hardwareInventory', 'processors', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Processor Status',
        },
        {
            id: 'hardwareInventory.Processors.Status Info',
            branchDependencies: ['hardwareInventory', 'processors', 'statusInfo'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            placeholder: 'Processor Status Info',
        },
        ...DRIVER_INVENTORY_CHILDREN_OPTIONS.reduce((map: any, option: any) => {
            map.push({
                id: `driverInventory.${option.name}`,
                branchDependencies: ['driverInventory', option.id],
                type: option.type,
                operator: option.operator,
                defaultValue: option.defaultValue,
                placeholder: `${option.name}`,
            });
            return map;
        }, []).flatMap((option: any) => option),
    ].map((leaf) => {
        if (leaf.type === LEAF_TYPE.STRING) {
            if (
                leaf.branchDependencies.includes('hardwareInventory') ||
                leaf.branchDependencies.includes('softwareInventory') ||
                leaf.branchDependencies.includes('driverInventory')
            ) {
                return {
                    operators: SUPPORTED_OPERATORS_FOR_LEAF_TYPE_STRING.filter(
                        (leafT) => leafT !== LEAF_OPERATOR.STRING_NOT_CONTAINS && leafT !== LEAF_OPERATOR.NOT_EQUAL
                    ),
                    ...leaf,
                };
            }
            return {
                operators: SUPPORTED_OPERATORS_FOR_LEAF_TYPE_STRING,
                ...leaf,
            };
        }
        if (leaf.type === LEAF_TYPE.SELECT) {
            return {
                operators: SUPPORTED_OPERATORS_FOR_LEAF_TYPE_SELECT,
                ...leaf,
            };
        }
        if (leaf.type === LEAF_TYPE.DATE) {
            return {
                operators: SUPPORTED_OPERATORS_FOR_LEAF_TYPE_DATE,
                ...leaf,
            };
        }

        return leaf;
    }),
};

export const QUERY_BUILDER_ADMIN_ON_DEMAND_CONFIGURATION = {
    root: {
        gridItemColumns: 0,
        options: [{ id: 'adminOnDemand', name: 'AdminOnDemand' }],
        children: {
            adminOnDemand: {
                gridItemColumns: 3,
                options: [
                    { id: LEAF_ID.APPLICATION_NAME, name: 'Application' },
                    { id: LEAF_ID.USER_NAME, name: 'User name' },
                    { id: LEAF_ID.DEVICE_NAME, name: 'Endpoint name' },
                ],
                children: null,
            },
        },
    },
    leafs: [
        {
            id: LEAF_ID.APPLICATION_NAME,
            branchDependencies: ['adminOnDemand', LEAF_ID.APPLICATION_NAME],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.EQUAL,
            defaultValue: '',
            locked: true,
            placeholder: 'Application name',
        },
        {
            id: LEAF_ID.USER_NAME,
            branchDependencies: ['adminOnDemand', LEAF_ID.USER_NAME],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.EQUAL,
            defaultValue: '',
            locked: true,
            placeholder: 'User name',
        },
        {
            id: LEAF_ID.DEVICE_NAME,
            branchDependencies: ['adminOnDemand', LEAF_ID.DEVICE_NAME],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.EQUAL,
            defaultValue: '',
            locked: true,
            placeholder: 'Endpoint name',
        },
    ],
};

export const QUERY_BUILDER_RELIABILITY_CONFIGURATION = {
    root: {
        gridItemColumns: 0,
        options: [
            { id: 'reliability', name: 'Reliability' },
            // ...QUERY_BUILDER_REF_DEVICE.root.options
        ],
        children: {
            reliability: {
                gridItemColumns: 3,
                options: [
                    { id: LEAF_ID.SEARCH, name: 'Description' },
                    { id: LEAF_ID.LOG_TYPE, name: 'Log type' },
                    { id: LEAF_ID.SOURCE, name: 'Source' },
                    { id: LEAF_ID.EVENT_ID, name: 'ID' },
                ],
                children: null,
            },
            // ...QUERY_BUILDER_REF_DEVICE.root.children
        },
    },
    leafs: [
        /** Reliability leafs */
        {
            id: LEAF_ID.SEARCH,
            branchDependencies: ['reliability', LEAF_ID.SEARCH],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            // primary: true,
            placeholder: 'Description',
            pattern: /[()|&:!]/,
        },
        {
            id: LEAF_ID.LOG_TYPE,
            branchDependencies: ['reliability', LEAF_ID.LOG_TYPE],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            locked: true,
            required: false,
            placeholder: 'Log Type',
            apiKey: 'LOGTYPE',
        },
        {
            id: LEAF_ID.SOURCE,
            branchDependencies: ['reliability', LEAF_ID.SOURCE],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            locked: true,
            placeholder: 'Source',
            apiKey: 'SOURCE',
            multiple: true,
        },
        {
            id: LEAF_ID.EVENT_ID,
            branchDependencies: ['reliability', LEAF_ID.EVENT_ID],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            locked: true,
            placeholder: 'Event ID',
            apiKey: 'EVENTID',
            multiple: true,
        },
        // ...QUERY_BUILDER_REF_DEVICE.leafs
    ],
};

const availableForTabsList = ['endpoint', 'software', 'cve', 'cveId', 'endpointId'];

export const QUERY_BUILDER_SECURITY = {
    root: {
        gridItemColumns: 0,
        options: [{ id: 'security', name: 'Security' }],
        children: {
            security: {
                gridItemColumns: 3,
                options: [
                    { id: LEAF_ID.NAME, name: 'Endpoint name' },
                    { id: LEAF_ID.SOFTWARE_NAME, name: 'Software name' },
                    { id: LEAF_ID.CVSS_SCORE, name: 'CVSS Score' },
                    { id: 'vulnerableEndpoints', name: 'Vulnerable endpoints' },
                    { id: 'vulnerableSoftware', name: 'Vulnerable software' },
                    { id: 'date', name: 'Latest Inventory Collected' },
                    { id: 'cveId', name: 'CVE ID' },
                    { id: 'disputed', name: 'Disputed' },
                ],
                children: null,
            },
        },
    },
    leafs: [
        /** Security leafs */
        {
            id: LEAF_ID.NAME,
            branchDependencies: ['security', LEAF_ID.NAME],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            operators: SUPPORTED_OPERATORS_FOR_LEAF_TYPE_STRING,
            defaultValue: '',
            locked: true,
            placeholder: 'Endpoint name',
            primary: true,
            apiKey: LEAF_ID.NAME,
            custom: {
                isCveFilter: false,
                availableForTabs: availableForTabsList.filter((tab) => !['endpointId'].includes(tab)),
            },
        },
        {
            id: LEAF_ID.SOFTWARE_NAME,
            branchDependencies: ['security', LEAF_ID.SOFTWARE_NAME],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            operators: SUPPORTED_OPERATORS_FOR_LEAF_TYPE_STRING,
            defaultValue: '',
            locked: true,
            placeholder: 'Software name',
            custom: {
                isCveFilter: true,
                availableForTabs: availableForTabsList.filter((tab) => !['cveId'].includes(tab)),
            },
        },
        {
            id: LEAF_ID.CVSS_SCORE,
            branchDependencies: ['security', LEAF_ID.CVSS_SCORE],
            type: LEAF_TYPE.NUMBER,
            min: 0,
            max: 10,
            operator: LEAF_OPERATOR.RANGE,
            locked: true,
            placeholder: 'CVSS Score',
            defaultValue: {
                min: null,
                max: null,
            },
            custom: {
                isCveFilter: true,
                availableForTabs: availableForTabsList.filter((tab) => !['cveId'].includes(tab)),
            },
        },
        {
            id: 'vulnerableEndpoints',
            branchDependencies: ['security', 'vulnerableEndpoints'],
            type: LEAF_TYPE.NUMBER,
            min: 0,
            max: 10,
            operator: LEAF_OPERATOR.RANGE,
            locked: true,
            placeholder: 'Vulnerable endpoints',
            defaultValue: {
                min: null,
                max: null,
            },
            custom: {
                isCveFilter: true,
                availableForTabs: availableForTabsList.filter((tab) => !['endpoint', 'cveId', 'endpointId'].includes(tab)),
            },
        },
        {
            id: 'vulnerableSoftware', // Endpoint tab only
            branchDependencies: ['security', 'vulnerableSoftware'],
            type: LEAF_TYPE.NUMBER,
            min: 0,
            max: 10,
            operator: LEAF_OPERATOR.RANGE,
            locked: true,
            placeholder: 'Vulnerable software',
            defaultValue: {
                min: null,
                max: null,
            },
            custom: {
                isCveFilter: true,
                availableForTabs: availableForTabsList.filter((tab) => !['software', 'cve', 'cveId'].includes(tab)),
            },
        },
        {
            id: 'hardwareInventory.collectedDate',
            ignoreInventoryCheck: true,
            branchDependencies: ['security', 'date'],
            type: LEAF_TYPE.DATE,
            operator: LEAF_OPERATOR.RANGE,
            operators: SUPPORTED_OPERATORS_FOR_LEAF_TYPE_DATE,
            defaultValue: {
                min: '',
                max: '',
            },
            placeholder: '',
            locked: true,
            custom: {
                isCveFilter: false,
                availableForTabs: availableForTabsList.filter((tab) => !['endpointId', 'cveId'].includes(tab)),
            },
        },
        {
            id: 'cveId',
            branchDependencies: ['security', 'cveId'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            operators: SUPPORTED_OPERATORS_FOR_LEAF_TYPE_STRING,
            defaultValue: '',
            locked: true,
            placeholder: 'CVE-YEAR-NUMBER',
            custom: {
                isCveFilter: true,
                availableForTabs: availableForTabsList.filter((tab) => ['cve', 'endpointId'].includes(tab)),
            },
        },
        {
            id: 'disputed',
            branchDependencies: ['security', 'disputed'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: undefined,
            locked: true,
            required: false,
            custom: {
                isCveFilter: true,
                availableForTabs: availableForTabsList.filter((tab) => ['endpoint', 'software', 'cve', 'endpointId'].includes(tab)),
            },
        },
    ],
};

export const QUERY_BUILDER_REF_SOFTWARE_LIST = {
    root: {
        gridItemColumns: 0,
        options: [{ id: 'software', name: 'Software filtering' }],
        children: {
            software: {
                gridItemColumns: 3,
                options: [
                    { id: 'name', name: 'Name' },
                    { id: 'category', name: 'Category' },
                ],
                children: null,
            },
            // ...QUERY_BUILDER_REF_DEVICE.root.children
        },
    },
    leafs: [
        {
            id: 'name',
            branchDependencies: ['software', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            primary: true,
            placeholder: 'Name',
        },
        {
            id: 'category',
            branchDependencies: ['software', 'category'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            multiple: true,
            options: [],
            locked: true,
            placeholder: 'Category',
        },
    ],
};

export const QUERY_BUILDER_REF_SOFTWARE_DASH = {
    root: {
        gridItemColumns: 0,
        options: [
            // QUERY_BUILDER_REF_DEVICE.root.options[1],
            { id: 'updater', name: 'Updater', negateAble: true },
        ],
        children: {
            updater: {
                gridItemColumns: 3,
                options: [{ id: 'name', name: 'Name' }],
                children: null,
            },
            // ...QUERY_BUILDER_REF_DEVICE.root.children
        },
    },
    leafs: [
        {
            id: 'patches.name',
            branchDependencies: ['updater', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            primary: true,
            placeholder: 'Software name',
        },
        // ...QUERY_BUILDER_REF_DEVICE.leafs.filter(leaf => leaf.branchDependencies[0] === "hardwareInventory").map(leaf => {
        //     return { ...leaf, id: leaf.id.replace('hardwareInventory', 'dictionaries') };
        // })
    ],
};

export const QUERY_BUILDER_REF_DEVICE_SOFTWARE_INVENTORY = {
    root: {
        gridItemColumns: 0,
        options: QUERY_BUILDER_REF_DEVICE.root.options
            .filter((option) => option.id === 'softwareInventory')
            .map((option) => {
                return { ...option, negateAble: false };
            }),
        children: {
            softwareInventory: {
                gridItemColumns: 3,
                optionsDEP: [
                    ...QUERY_BUILDER_REF_DEVICE.root.children.softwareInventory.options,
                    {
                        id: 'hideSystemComponents',
                        name: 'Hide system components',
                    },
                ],
                options: [
                    { id: 'name', name: 'Name' },
                    { id: 'publisher', name: 'Publisher' },
                    { id: 'platform', name: 'Platform' },
                    { id: 'language', name: 'Language' },
                    { id: 'userAccount', name: 'User account' },
                    { id: 'installDate', name: 'Install date' },
                    {
                        id: 'hideSystemComponents',
                        name: 'Hide system components',
                    },
                ],
                children: null,
            },
        },
    },
    leafs: [
        {
            id: 'name',
            branchDependencies: ['softwareInventory', 'name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            primary: true,
            placeholder: 'Software name',
        },
        {
            id: 'publisher',
            dataKey: 'Publisher',
            branchDependencies: ['softwareInventory', 'publisher'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            multiple: true,
            options: [],
            locked: true,
            placeholder: 'Publisher',
        },
        {
            id: 'platform',
            dataKey: 'platformName',
            branchDependencies: ['softwareInventory', 'platform'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            required: false,
            options: [],
            locked: true,
            placeholder: 'Platform',
        },
        {
            id: 'language',
            dataKey: 'languageName',
            branchDependencies: ['softwareInventory', 'language'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            multiple: true,
            options: [],
            locked: true,
            placeholder: 'Language',
        },
        {
            id: 'userAccount',
            dataKey: 'UserAccount',
            branchDependencies: ['softwareInventory', 'userAccount'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [],
            locked: true,
            placeholder: 'User account',
        },
        {
            id: 'installDate',
            branchDependencies: ['softwareInventory', 'installDate'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ALL_IN_ARRAY,
            defaultValue: [],
            options: [
                {
                    name: 'Last 7 days',
                    id: '7D',
                    value: 7,
                    unit: 'days',
                },
                {
                    name: 'Last 30 days',
                    id: '30D',
                    value: 30,
                    unit: 'days',
                },
                {
                    name: 'Last 3 months',
                    id: '3M',
                    value: 3,
                    unit: 'months',
                },
                {
                    name: 'Last 6 months',
                    id: '6M',
                    value: 6,
                    unit: 'months',
                },
                {
                    name: 'Last year',
                    id: '1Y',
                    value: 12,
                    unit: 'months',
                },
            ],
            required: false,
            locked: true,
            placeholder: 'Install date',
        },
        {
            id: 'hideSystemComponents',
            branchDependencies: ['softwareInventory', 'hideSystemComponents'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: false,
            locked: true,
        },
    ],
};

export const QUERY_BUILDER_REF_DEVICE_DRIVER_INVENTORY = {
    root: {
        gridItemColumns: 0,
        options: [{ id: 'driverInventory', name: 'Driver', negateAble: false }],
        children: {
            driverInventory: {
                gridItemColumns: 3,
                optionsDEP: [{ id: 'Name', name: 'Name' }],
                options: [
                    { id: 'Name', name: 'Name' },
                    { id: 'Driver Provider', name: 'Provider' },
                    { id: 'Device ID', name: 'Device ID' },
                    { id: 'Hardware ID', name: 'Hardware ID' },
                    { id: 'Inf Name', name: 'Filename' },
                    { id: 'Manufacturer', name: 'Manufacturer' },
                    { id: 'Driver Version', name: 'Driver Version' },
                    { id: 'Driver Date', name: 'Driver Date' },
                    { id: 'OEM Driver', name: 'Show Only OEM driver' },
                    {
                        id: 'updateAvailable',
                        name: 'Show Only Update Available',
                    },
                ],
                children: null,
            },
        },
    },
    leafs: [
        {
            id: 'Name',
            branchDependencies: ['driverInventory', 'Name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            primary: true,
            placeholder: 'Driver name',
        },
        {
            id: 'Driver Provider',
            branchDependencies: ['driverInventory', 'Driver Provider'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            placeholder: 'Provider name',
        },
        {
            id: 'Device ID',
            branchDependencies: ['driverInventory', 'Device ID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            placeholder: 'Device ID',
        },
        {
            id: 'Hardware ID',
            branchDependencies: ['driverInventory', 'Hardware ID'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            placeholder: 'Hardware ID',
        },
        {
            id: 'Inf Name',
            branchDependencies: ['driverInventory', 'Inf Name'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            placeholder: 'Filename',
        },
        {
            id: 'Manufacturer',
            branchDependencies: ['driverInventory', 'Manufacturer'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            placeholder: 'Manufacturer',
        },
        {
            id: 'Driver Version',
            branchDependencies: ['driverInventory', 'Driver Version'],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            placeholder: 'Driver Version',
        },
        {
            id: 'Driver Date',
            branchDependencies: ['driverInventory', 'Driver Date'],
            type: LEAF_TYPE.DATE,
            operator: LEAF_OPERATOR.RANGE,
            defaultValue: {
                min: null,
                max: null,
            },
            locked: true,
            placeholder: 'Driver Date',
        },
        {
            id: 'OEM Driver',
            branchDependencies: ['driverInventory', 'OEM Driver'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: false,
            locked: true,
        },
        {
            id: 'updateAvailable',
            branchDependencies: ['driverInventory', 'updateAvailable'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: false,
            locked: true,
        },
    ],
};

export const QUERY_BUILDER_UPDATER_IN_PROGRESS_LIST = {
    root: {
        gridItemColumns: 0,
        options: [
            { id: 'endpoint', name: 'Endpoint', defaultSelection },
            { id: 'patch', name: 'Patch' },
        ],
        children: {
            endpoint: {
                gridItemColumns: 4,
                options: [{ id: 'online', name: 'Online' }],
                children: null,
            },
            patch: {
                gridItemColumns: 4,
                options: [{ id: 'status', name: 'Status' }],
                children: null,
            },
        },
    },
    leafs: [
        /** Updater leafs */
        {
            id: 'online',
            branchDependencies: ['endpoint', 'online'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
        },
        {
            id: 'status',
            branchDependencies: ['patch', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ALL_IN_ARRAY,
            defaultValue: [],
            options: [
                {
                    name: 'Downloading',
                    id: 'downloading',
                },
                {
                    name: 'Initializing',
                    id: 'initializing',
                },
                {
                    name: 'Installing',
                    id: 'installing',
                },
                {
                    name: 'Queued',
                    id: 'queued',
                },
                {
                    name: 'Searching',
                    id: 'searching',
                },
                {
                    name: 'Sending',
                    id: 'sending',
                },
                {
                    name: 'Uninstalling',
                    id: 'uninstalling',
                },
                {
                    name: 'Unpacking',
                    id: 'unpacking',
                },
                {
                    name: 'Waiting',
                    id: 'waiting',
                },
            ],
        },
        // ...QUERY_BUILDER_REF_DEVICE.leafs
    ],
};

export const QUERY_BUILDER_DRIVER_IN_PROGRESS_LIST = {
    root: {
        gridItemColumns: 0,
        options: [
            { id: 'endpoint', name: 'Endpoint', defaultSelection },
            { id: 'patch', name: 'Patch' },
        ],
        children: {
            endpoint: {
                gridItemColumns: 4,
                options: [{ id: 'online', name: 'Online' }],
                children: null,
            },
            patch: {
                gridItemColumns: 4,
                options: [{ id: 'status', name: 'Status' }],
                children: null,
            },
        },
    },
    leafs: [
        /** Updater leafs */
        {
            id: 'online',
            branchDependencies: ['endpoint', 'online'],
            type: LEAF_TYPE.BOOLEAN,
            defaultValue: true,
        },
        {
            id: 'status',
            branchDependencies: ['patch', 'status'],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ALL_IN_ARRAY,
            defaultValue: [],
            options: [
                {
                    name: 'Downloading',
                    id: 'driver downloading',
                },
                {
                    name: 'Initializing',
                    id: 'driver initializing',
                },
                {
                    name: 'Installing',
                    id: 'driver installing',
                },
                {
                    name: 'Queued',
                    id: 'driver queued',
                },
                {
                    name: 'Searching',
                    id: 'driver searching',
                },
                {
                    name: 'Sending',
                    id: 'driver sending',
                },
                {
                    name: 'Uninstalling',
                    id: 'driver uninstalling',
                },
                {
                    name: 'Unpacking',
                    id: 'driver unpacking',
                },
                {
                    name: 'Waiting',
                    id: 'driver waiting',
                },
                {
                    name: 'Awaiting user approval',
                    id: 'driver awaiting user approval',
                },
            ],
        },
        // ...QUERY_BUILDER_REF_DEVICE.leafs
    ],
};

export const QUERY_BUILDER_APPLE_APPLICATION_LIST = {
    root: {
        gridItemColumns: 0,
        options: [{ id: 'appleApplications', name: 'Applications' }],
        children: {
            appleApplications: {
                gridItemColumns: 3,
                options: [
                    { id: LEAF_ID.NAME, name: 'Application' },
                    { id: LEAF_ID.OS_TYPE, name: 'OS Type' },
                    { id: LEAF_ID.REMOVABLE, name: 'Removable' },
                    { id: LEAF_ID.IS_VPP, name: 'VPP' },
                ],
                children: null,
            },
        },
    },
    leafs: [
        /** Apple Applications leafs */
        {
            id: LEAF_ID.NAME,
            branchDependencies: ['appleApplications', LEAF_ID.NAME],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            primary: true,
            placeholder: 'Name',
            pattern: /[()|&:!]/,
        },
        {
            id: LEAF_ID.OS_TYPE,
            branchDependencies: ['appleApplications', LEAF_ID.OS_TYPE],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [
                { id: 'iOS', name: 'iOS' },
                { id: 'iPadOS', name: 'iPadOS' },
            ],
            locked: true,
            appleApiKey: 'OS_TYPE',
            placeholder: 'OS Type',
            pattern: /[()|&:!]/,
            required: false,
        },
        {
            id: LEAF_ID.REMOVABLE,
            branchDependencies: ['appleApplications', LEAF_ID.REMOVABLE],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [
                { id: 'true', name: 'Yes' },
                { id: 'false', name: 'No' },
            ],
            locked: true,
            appleApiKey: 'REMOVABLE',
            placeholder: 'Removable',
            pattern: /[()|&:!]/,
            required: false,
        },
        {
            id: LEAF_ID.IS_VPP,
            branchDependencies: ['appleApplications', LEAF_ID.IS_VPP],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [
                { id: '1', name: 'Yes' },
                { id: '0', name: 'No' },
            ],
            locked: true,
            appleApiKey: 'IS_VPP',
            placeholder: 'VPP',
            pattern: /[()|&:!]/,
            required: false,
        },
    ],
};

export const QUERY_BUILDER_ANDROID_APPLICATION_LIST = {
    root: {
        gridItemColumns: 0,
        options: [{ id: 'androidApplications', name: 'Applications' }],
        children: {
            androidApplications: {
                gridItemColumns: 3,
                options: [
                    { id: LEAF_ID.NAME, name: 'Application' },
                    { id: LEAF_ID.ANDROID_APP_INSTALL_TYPE, name: 'Install Type' },
                    { id: LEAF_ID.ANDROID_APPLICATION_TYPE, name: 'Application Type' },
                ],
                children: null,
            },
        },
    },
    leafs: [
        /** Android Applications leafs */
        {
            id: LEAF_ID.NAME,
            branchDependencies: ['androidApplications', LEAF_ID.NAME],
            type: LEAF_TYPE.STRING,
            operator: LEAF_OPERATOR.CONTAINS,
            defaultValue: '',
            locked: true,
            primary: true,
            placeholder: 'Name',
            pattern: /[()|&:!]/,
        },
        {
            id: LEAF_ID.ANDROID_APP_INSTALL_TYPE,
            branchDependencies: ['androidApplications', LEAF_ID.ANDROID_APP_INSTALL_TYPE],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [
                { id: 'PREINSTALLED', name: 'Preinstalled. Can be removed' },
                { id: 'FORCE_INSTALLED', name: "Force installed. Can't be removed" },
                { id: 'BLOCKED', name: 'Blocked from installation' },
                { id: 'AVAILABLE', name: 'Available in store' },
                {
                    id: 'REQUIRED_FOR_SETUP',
                    name: "Required for Setup. Automatically installed and can't be removed by the user. Will prevent setup from completion until installation is complete",
                },
                { id: 'KIOSK', name: 'Automatically installed in kiosk mode' },
            ],
            locked: true,
            androidApiKey: 'ANDROID_APP_INSTALL_TYPE',
            placeholder: 'Install Type',
            pattern: /[()|&:!]/,
            required: false,
        },
        {
            id: LEAF_ID.ANDROID_APPLICATION_TYPE,
            branchDependencies: ['androidApplications', LEAF_ID.ANDROID_APPLICATION_TYPE],
            type: LEAF_TYPE.SELECT,
            operator: LEAF_OPERATOR.ANY_IN_ARRAY,
            defaultValue: [],
            options: [
                { id: 'application', name: 'Google Play App' },
                { id: 'webApp', name: 'Web App' },
            ],
            locked: true,
            androidApiKey: 'ANDROID_APP_INSTALL_TYPE',
            placeholder: 'Application Type',
            pattern: /[()|&:!]/,
            required: false,
        },
    ],
};

export const QUERY_BUILDER_EDIT_MODE_URL_KEY = 'editFilterId';
