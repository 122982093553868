import { SORT_DIRECTION } from '@capasystems/constants';
import { Avatar, Column, LayoutRow, Page, Paper, Tooltip, VirtualizedTable, useParams } from '@capasystems/ui';
import { Url, isDefined } from '@capasystems/utils';
import { NONE } from '@thirdparty/constants';
import { CapaOneLink, useApi, useAppleApi, useNavigate } from '@thirdparty/ui';
import { React, useEffect, useRef, useState } from 'react';
import { PageTitle } from './../../../../../../../libs/thirdparty/ui/src/lib/page/page-title';

const SORT_BY = 'sortBy';
const SORT_DIR = 'sortDir';

const AppleVPPAssignmentsList = () => {
    const { vppAssignments } = useParams();
    const appleApi = useAppleApi();
    const api = useApi();
    const { applicationList } = useParams();
    const propertiesBuilderRef = useRef();
    const navigate = useNavigate();
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [applicationUrl, setApplicationUrl] = useState();
    const [applicationName, setApplicationName] = useState();
    const [loading, setLoading] = useState(isDefined(true));
    const [errorMessage, setErrorMessage] = useState(null);
    const [searchTerm, setSearchTerm] = useState(Url.getString('search') || '');
    const [vppLicenseList, setVppLicenseList] = useState([]);
    const [assignmentsData, setAssignmentsData] = useState([]);
    const [sortingState, setSortingState] = useState({
        sortBy: Url.getString(SORT_BY, 'name'),
        sortDirection: Url.getString(SORT_DIR, SORT_DIRECTION.ASC),
        pageNumber: 0,
        pageSize: 50,
    });
    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        rowData: { name: '' },
    });

    const navigateToVPPAssignment = (response) => {
        navigate.to(`apple/vpp/${vppAssignments || rowData}/assignments`);
    };
    useEffect(() => {
        if (isDefined(vppAssignments)) {
            setLoading(true);
            Promise.all([appleApi.getAppleSingleVppLicense(vppAssignments), appleApi.getAppleVppAssignments(vppAssignments)])
                .then(([singleLicenseResponse, assignmentResponse]) => {
                    if (singleLicenseResponse && singleLicenseResponse.adamId === vppAssignments) {
                        const appIconUrl = singleLicenseResponse.iconUrl;
                        const appName = singleLicenseResponse.name;
                        setApplicationUrl(appIconUrl);
                        setApplicationName(appName);
                    }
                    if (Array.isArray(assignmentResponse)) {
                        setAssignmentsData(assignmentResponse);
                    }

                    setLoading(false);
                })

                .catch((error) => {
                    setErrorMessage('Failed to fetch VPP assignments data');
                    console.error(error);
                    setLoading(false);
                });
        }
    }, [vppAssignments]);

    return (
        <Page title="VPP Assignments">
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <LayoutRow verticalAlign="center">
                    <div>
                        <Avatar
                            src={applicationUrl}
                            alt={applicationName}
                            variant="rounded"
                            className="tw-mr-4 tw-h-10 tw-w-10"
                        />
                    </div>
                    <div>
                        <PageTitle
                            category="VPP assignments"
                            description="A list of your assignments for this VPP license"
                        >
                            <span>{applicationName}</span>
                        </PageTitle>
                    </div>
                </LayoutRow>
                <Paper className="tw-h-full tw-shadow-sm">
                    <VirtualizedTable
                        entity="assignment"
                        items={assignmentsData}
                        isLoading={loading}
                        showRowCount
                        totalRowCount={assignmentsData.length}
                    >
                        <Column
                            minWidth={160}
                            dataKey="name"
                            label="Name"
                            cellRenderer={({ rowData }) => {
                                if (!rowData.name) {
                                    return (
                                        <Tooltip content="This endpoint is likely enrolled in a different MDM system sharing the same Apple Business/Education Account.">
                                            <i className="tw-text-gray-300">{NONE}</i>
                                        </Tooltip>
                                    );
                                }
                                return <CapaOneLink to={`apple/endpoint/${rowData.endpointId}/dashboard`}>{rowData.name}</CapaOneLink>;
                            }}
                        />
                        <Column
                            minWidth={160}
                            dataKey="serialNumber"
                            label="Serial Number"
                        />
                    </VirtualizedTable>
                </Paper>
            </div>
        </Page>
    );
};

export { AppleVPPAssignmentsList };
