import { BUTTON, SORT_DIRECTION } from '@capasystems/constants';
import {
    Avatar,
    Button,
    Checkbox,
    Column,
    Ellipsis,
    Icon,
    LayoutCenter,
    LayoutRow,
    Loading,
    Page,
    Tooltip,
    VirtualizedTable,
    useParams,
    virtualizedTableColumnPropTypes,
} from '@capasystems/ui';
import { formatDate, noop } from '@capasystems/utils';
import {
    CapaOneLink,
    ConfirmDialog,
    DeviceAgentState,
    PageTitle,
    PatchStatusTabs,
    QueryBuilder,
    WidgetPaper,
    useApi,
    useApplicationPatchUpdateAvailableSocket,
    useApplicationPatchingSocket,
    useAuthContext,
    useDialog,
    useMessageContext,
    usePatchStatusTabs,
} from '@thirdparty/ui';
import { getDefaultQueryBuilderConfiguration, patchInstallFailed, patchUninstallFailed } from '@thirdparty/utils';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UpdaterPermissionsCheck } from './updater-teaser';

const defaultQueryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Search for endpoints' });

const UpdaterApplicationManagement = () => {
    const api = useApi();
    const { permissions } = useAuthContext();
    const { applicationId } = useParams();
    const [application, setApplication] = useState({});
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);

    const {
        selectedTab,
        onTabChange,
        patchStatusFilter,
        searchString,
        setSearchString,
        showUninstallButton,
        showingUpdateAvailable,
        showingNotInstalled,
        showReinstallButton,
        showingFailed,
        showRequestedDate,
    } = usePatchStatusTabs();
    const installConfirmDialog = useDialog();
    const uninstallConfirmDialog = useDialog();
    const { handleApiError } = useMessageContext();
    const pageTitle = useRef('');

    const [sort, setSort] = useState({ sortDirection: SORT_DIRECTION.ASC, sortBy: 'deviceName' });

    const onApplicationPatching = useCallback(({ operationType, fullDocument, documentId, updateDescription, ...rest }, { deleteOperation }) => {
        const { deviceId, status, requestedInstallDate } = fullDocument;
        setDevices((currentDevices) =>
            currentDevices.map((device) => {
                if (device.deviceId === deviceId) {
                    if (deleteOperation) {
                        return {
                            ...device,
                            status: {
                                code: 404,
                                message: 'Not Installed',
                            },
                            requestedInstallDate: null,
                        };
                    }
                    return { ...device, status, requestedInstallDate };
                }
                return device;
            })
        );
    }, []);

    const onApplicationPatchUpdateAvailable = useCallback(({ operationType, fullDocument, documentId, updateDescription, ...rest }) => {
        console.log('TODO: Document must include deviceName. Currently we only have deviceId.', fullDocument);
    }, []);

    useApplicationPatchingSocket(applicationId, onApplicationPatching);

    useApplicationPatchUpdateAvailableSocket(applicationId, onApplicationPatchUpdateAvailable);

    const getApplicationAndDeviceMatches = () => {
        const promises = [api.getApplication(applicationId), api.getApplicationAndDeviceMatches(applicationId)];
        Promise.all(promises)
            .then(([{ application, ...rest }, applicationAndDeviceMatchesResponse]) => {
                pageTitle.current = ['Software', application];
                setApplication({
                    ...rest,
                    name: application,
                });
                setDevices(
                    applicationAndDeviceMatchesResponse.map(({ patchStatus, ...device }) => ({
                        ...device,
                        status: patchStatus,
                        selected: false,
                    }))
                );
            })
            .catch(noop)
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(getApplicationAndDeviceMatches, []);

    useEffect(() => {
        if (showRequestedDate) {
            setSort({
                sortBy: 'requestedInstallDate',
                sortDirection: SORT_DIRECTION.DESC,
            });
        } else {
            setSort({
                sortBy: 'deviceName',
                sortDirection: SORT_DIRECTION.ASC,
            });
        }
    }, [showRequestedDate]);

    const doTheMagic = (dialogHook, apiMethod) => {
        if (dialogHook.open) {
            const selectedDeviceIds = devices
                .filter(patchStatusFilter)
                .filter(searchFilter)
                .filter((d) => d.selected)
                .map((d) => d.deviceId);
            apiMethod(applicationId, selectedDeviceIds)
                .then(() => {
                    setDevices((currentDevices) =>
                        currentDevices.map((device) => ({
                            ...device,
                            selected: selectedDeviceIds.includes(device.deviceId) ? false : device.selected,
                        }))
                    );
                })
                .catch(handleApiError);
            dialogHook.onClose();
        } else {
            dialogHook.setOpen(true);
        }
    };

    const installApplicationOnDevices = () => {
        doTheMagic(installConfirmDialog, api.installApplicationOnDevices);
    };

    const uninstallApplicationOnDevices = () => {
        doTheMagic(uninstallConfirmDialog, api.uninstallApplicationOnDevices);
    };

    const toggleDeviceSelect =
        (rowData) =>
        ({ target: { checked } }) => {
            setDevices((currentDevices) =>
                currentDevices.map((device) => ({
                    ...device,
                    selected: rowData.deviceId === device.deviceId ? checked : device.selected,
                }))
            );
        };

    const onRowClick = ({ rowData }) => {
        setDevices((currentDevices) =>
            currentDevices.map((device) => ({
                ...device,
                selected: rowData.deviceId === device.deviceId ? !device.selected : device.selected,
            }))
        );
    };

    const searchFilter = (d) => {
        return d.deviceName.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
    };

    const toggleAllDevicesSelect = ({ target: { checked } }) => {
        const deviceIds = devices
            .filter(patchStatusFilter)
            .filter(searchFilter)
            .map((d) => d.deviceId);
        setDevices((currentDevices) =>
            currentDevices.map((device) => ({
                ...device,
                selected: deviceIds.includes(device.deviceId) ? checked : device.selected,
            }))
        );
    };

    const onSubmit = ([activeLeaf]) => {
        setSearchString(activeLeaf ? activeLeaf.value : '');
    };

    if (loading) {
        return (
            <LayoutCenter>
                <Loading />
            </LayoutCenter>
        );
    }

    const items = devices.filter(patchStatusFilter).filter(searchFilter);
    const selectedItems = items.filter((d) => d.selected);
    const selectedItemsCount = selectedItems.length;

    const getAvailableAction = () => {
        if (showingUpdateAvailable) {
            return 'Update';
        }
        if (showingNotInstalled) {
            return 'Install';
        }
        if (showReinstallButton) {
            return 'Reinstall';
        }
        return null;
    };

    const availableAction = getAvailableAction();

    const manageSort = (a, b) => {
        if (sort.sortBy === 'requestedInstallDate') {
            if (sort.sortDirection === SORT_DIRECTION.ASC) {
                return new Date(a.requestedInstallDate) - new Date(b.requestedInstallDate);
            }
            return new Date(b.requestedInstallDate) - new Date(a.requestedInstallDate);
        }
        if (sort.sortBy === 'status') {
            if (sort.sortDirection === SORT_DIRECTION.ASC) {
                return a.status.message.toLocaleLowerCase().localeCompare(b.status.message.toLocaleLowerCase());
            }
            return b.status.message.toLocaleLowerCase().localeCompare(a.status.message.toLocaleLowerCase());
        }
        if (sort.sortDirection === SORT_DIRECTION.ASC) {
            return a[sort.sortBy].toLocaleLowerCase().localeCompare(b[sort.sortBy].toLocaleLowerCase());
        }
        return b[sort.sortBy].toLocaleLowerCase().localeCompare(a[sort.sortBy].toLocaleLowerCase());
    };

    return (
        <Page title={pageTitle.current}>
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-y-4 tw-p-4">
                <div className="tw-grid tw-grid-cols-1fr-auto">
                    <LayoutRow verticalAlign="center">
                        <div>
                            <Avatar
                                alt={application.name}
                                src={application.thumbnail}
                                variant="square"
                                className="tw-h-12 tw-w-12"
                            />
                        </div>
                        <div className="tw-ml-4">
                            <PageTitle description={<>Developed by {application.vendor}</>}>{application.name}</PageTitle>
                        </div>
                        <div className="tw-ml-4">
                            <Tooltip
                                interactive
                                content={
                                    <div>
                                        <h2 className="tw-mb-4">{application.name}</h2>
                                        <p className="tw-whitespace-pre-wrap tw-text-xs">{application.description}</p>
                                    </div>
                                }
                            >
                                <Icon type="infoOutlined" />
                            </Tooltip>
                        </div>
                    </LayoutRow>
                    <div className="tw-flex tw-w-full tw-items-end tw-pl-4">
                        {permissions.canManageSoftwarePatching && (
                            <QueryBuilder
                                defaultConfiguration={defaultQueryBuilderConfiguration}
                                onInit={onSubmit}
                                onSubmit={onSubmit}
                            />
                        )}
                    </div>
                </div>
                <UpdaterPermissionsCheck>
                    <div className="tw-grid tw-grid-cols-auto-1fr">
                        <PatchStatusTabs
                            selectedTab={selectedTab}
                            onTabChange={onTabChange}
                            items={devices}
                            searchFilter={searchFilter}
                        />
                        <WidgetPaper
                            className="tw-ml-4"
                            title="Endpoints"
                            description={selectedItemsCount === 0 ? 'No endpoints selected' : <>{pluralize('endpoint', selectedItemsCount, true)} selected</>}
                            actions={
                                selectedItemsCount > 0 &&
                                availableAction && (
                                    <>
                                        {(showUninstallButton || (showingFailed && selectedItems.filter(patchUninstallFailed).length > 0)) && (
                                            <>
                                                <Tooltip
                                                    content={
                                                        <>
                                                            Uninstall {application.name} on {pluralize('endpoint', selectedItemsCount, true)}
                                                        </>
                                                    }
                                                >
                                                    <Button
                                                        variant={BUTTON.RAISED}
                                                        color={BUTTON.DANGER}
                                                        onClick={uninstallApplicationOnDevices}
                                                    >
                                                        Uninstall
                                                    </Button>
                                                </Tooltip>
                                                <ConfirmDialog
                                                    open={uninstallConfirmDialog.open}
                                                    onCancel={uninstallConfirmDialog.onClose}
                                                    onConfirm={uninstallApplicationOnDevices}
                                                    title={`Uninstall ${application.name} on the following ${pluralize('endpoint', selectedItemsCount)}?`}
                                                >
                                                    {showingFailed
                                                        ? selectedItems.filter(patchUninstallFailed).map((device) => {
                                                              return (
                                                                  <p
                                                                      key={device.deviceName}
                                                                      className="tw-mt-0"
                                                                  >
                                                                      &bull;&nbsp;&nbsp;{device.deviceName}
                                                                  </p>
                                                              );
                                                          })
                                                        : selectedItems.map((device) => {
                                                              return (
                                                                  <p
                                                                      key={device.deviceName}
                                                                      className="tw-mt-0"
                                                                  >
                                                                      &bull;&nbsp;&nbsp;{device.deviceName}
                                                                  </p>
                                                              );
                                                          })}
                                                </ConfirmDialog>
                                            </>
                                        )}
                                        {(!showingFailed || selectedItems.filter(patchInstallFailed).length > 0) && (
                                            <Tooltip
                                                content={
                                                    <>
                                                        {availableAction} {application.name} on {pluralize('endpoint', selectedItemsCount, true)}
                                                    </>
                                                }
                                            >
                                                <Button
                                                    variant={BUTTON.RAISED}
                                                    color={BUTTON.PRIMARY}
                                                    onClick={installApplicationOnDevices}
                                                    noMargin
                                                >
                                                    {availableAction}
                                                </Button>
                                            </Tooltip>
                                        )}
                                        <ConfirmDialog
                                            open={installConfirmDialog.open}
                                            onCancel={installConfirmDialog.onClose}
                                            onConfirm={installApplicationOnDevices}
                                            title={`${availableAction} ${application.name} on the following ${pluralize('endpoint', selectedItemsCount)}?`}
                                        >
                                            {showingFailed
                                                ? selectedItems.filter(patchInstallFailed).map((device) => {
                                                      return (
                                                          <p
                                                              key={device.deviceName}
                                                              className="tw-mt-0"
                                                          >
                                                              &bull;&nbsp;&nbsp;{device.deviceName}
                                                          </p>
                                                      );
                                                  })
                                                : selectedItems.map((device) => {
                                                      return (
                                                          <p
                                                              key={device.deviceName}
                                                              className="tw-mt-0"
                                                          >
                                                              &bull;&nbsp;&nbsp;{device.deviceName}
                                                          </p>
                                                      );
                                                  })}
                                        </ConfirmDialog>
                                    </>
                                )
                            }
                        >
                            <VirtualizedTable
                                items={items.sort(manageSort)}
                                totalRowCount={items.length}
                                showRowCount
                                disableHeader={items.length === 0}
                                onRowClick={availableAction ? onRowClick : undefined}
                                sortBy={sort.sortBy}
                                sortDirection={sort.sortDirection}
                                sort={(a) => setSort(a)}
                                entity="endpoint"
                            >
                                <Column
                                    headerRenderer={() =>
                                        availableAction ? (
                                            <Checkbox
                                                label=""
                                                checked={items.length === items.filter((d) => d.selected).length}
                                                onChange={toggleAllDevicesSelect}
                                                className="tw-mx-2"
                                            />
                                        ) : null
                                    }
                                    dataKey="selected"
                                    minWidth={40}
                                    maxWidth={40}
                                    cellRenderer={({ cellData, rowData }) => {
                                        if (availableAction === null) {
                                            return (
                                                <span>
                                                    <Loading size={16}></Loading>
                                                </span>
                                            );
                                        }
                                        return (
                                            <Checkbox
                                                checked={cellData}
                                                onChange={toggleDeviceSelect(rowData)}
                                            />
                                        );
                                    }}
                                    disableSort
                                />

                                <Column
                                    key="deviceName"
                                    dataKey="deviceName"
                                    label="Name"
                                    minWidth={160}
                                    cellRenderer={({ rowData }) => (
                                        <CapaOneLink to={`windows/device/${rowData.deviceId}/updates/software`}>
                                            <Ellipsis>{rowData.deviceName}</Ellipsis>
                                        </CapaOneLink>
                                    )}
                                />

                                <Column
                                    key="online"
                                    dataKey="online"
                                    label="Agent Status"
                                    minWidth={96}
                                    maxWidth={96}
                                    cellRenderer={({ cellData, rowData }) => <DeviceAgentState device={{ ...rowData, id: rowData.deviceId }} />}
                                    disableSort
                                />

                                <Column
                                    key="requestedInstallDate"
                                    dataKey="requestedInstallDate"
                                    label="Requested Date"
                                    minWidth={120}
                                    maxWidth={120}
                                    shouldRender={({ tableWidth }) => showRequestedDate && tableWidth >= 900}
                                    defaultSortDirection={SORT_DIRECTION.DESC}
                                    cellRenderer={({ cellData }) => formatDate(cellData)}
                                />

                                <Column
                                    key="status"
                                    dataKey="status"
                                    label="Status"
                                    minWidth={120}
                                    maxWidth={120}
                                    justify={virtualizedTableColumnPropTypes.justify.end}
                                    cellRenderer={({ cellData }) => <Ellipsis>{cellData.message}</Ellipsis>}
                                />
                            </VirtualizedTable>
                        </WidgetPaper>
                    </div>
                </UpdaterPermissionsCheck>
            </div>
        </Page>
    );
};

export { UpdaterApplicationManagement };
