import { BUTTON, SORT_DIRECTION, TOOLTIP } from '@capasystems/constants';
import { Avatar, Button, Checkbox, Collapse, Column, Ellipsis, EmptyState, Icon, Tooltip, VirtualizedTable } from '@capasystems/ui';
import { formatTimestamp, isDefined, noop } from '@capasystems/utils';
import { Portal } from '@mui/base';
import { LEAF_ID, LOCAL_STORAGE_ID, QUERY_BUILDER_UPDATER_IN_PROGRESS_LIST } from '@thirdparty/constants';
import { patchIsInProgress } from '@thirdparty/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import { useColumnPicker, useRefState } from '../hooks/thirdparty-hooks';
import { useApi } from '../hooks/useApi/useApi';
import { useDevicesSocket, useOrgConfigSocket, useOrgPatchingSocket } from '../hooks/useSocket/useSocket';
import { QueryBuilder } from '../query-builder/query-builder';
import {
    ActionsDialog,
    ActionsIconButton,
    CapaOneLink,
    DeviceAgentState,
    TwoLineCellRenderer,
    WidgetPaper,
} from '../thirdparty-components/thirdparty-components';

const STATUS_QUEUED = 'Queued';

const pauseAction = {
    id: 'pause',
    name: 'Pause deployment',
    tooltipProps: {
        content: 'Instantly pause application deployment. Applications will not be deployed before deployment is manually resumed.',
        position: TOOLTIP.POSITION.LEFT,
    },
};
const resumeAction = {
    id: 'resume',
    name: 'Resume deployment',
    tooltipProps: {
        content: 'Deactivate pause mode and resume application deployments.',
        position: TOOLTIP.POSITION.LEFT,
    },
};

const UpdaterInProgressList = ({ portalContainer }) => {
    const api = useApi();
    const pauseApi = useApi();
    const columnPicker = useColumnPicker({ id: 'windows-updater-in-progress-list', lockedColumns: ['Name'] });

    const [isLoading, setIsLoading] = useState(true);
    const [sort, setSort] = useState({
        by: 'name',
        direction: SORT_DIRECTION.ASC,
    });
    const [activeLeafs, setActiveLeafs] = useState(null);
    const [applicationsRef, refreshComponent] = useRefState([]);
    const queryBuilderRef = useRef({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [pausedState, setPausedState] = useState({
        isPaused: null,
        updatedAt: null,
        updatedBy: {
            name: null,
            email: null,
        },
    });
    const [dirtyCount, setDirtyCount] = useState(0);

    const [actionsDialogState, setActionsDialogState] = useState({
        open: false,
        anchorEl: null,
        actions: [],
        pages: [],
    });

    const openActionsDialog = (event) => {
        setActionsDialogState((c) => ({
            ...c,
            anchorEl: event.currentTarget,
            actions: [
                { ...pauseAction, disabled: pausedState.isPaused },
                { ...resumeAction, disabled: !pausedState.isPaused },
            ],
            open: true,
        }));
    };

    const closeActionsDialog = () => {
        setActionsDialogState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action) => {
        closeActionsDialog();
        switch (action.id) {
            case pauseAction.id:
                setShowWarning(true);
                break;
            case resumeAction.id:
                api.updatePausedStateForQueuedApplications(false)
                    .then(() => {
                        // Let the socket update the state.
                    })
                    .catch(() => {
                        // Failed to update the paused state.
                    });
                break;
        }
    };

    const onSort = ({ sortBy, sortDirection }) => {
        setSort({
            ...sort,
            by: sortBy,
            direction: sortDirection,
        });
    };

    const sorting = useCallback(
        (a, b) => {
            if (sort.direction === SORT_DIRECTION.ASC) {
                if (sort.by === 'status') {
                    return a[sort.by].message.localeCompare(b[sort.by].message);
                }
                if (sort.by === 'requestedInstallDate') {
                    return new Date(a[sort.by]) - new Date(b[sort.by]);
                }
                if (sort.by === 'online') {
                    return a[sort.by] - b[sort.by];
                }
                const aSort = a[sort.by] || '';
                const bSort = b[sort.by] || '';
                return aSort.localeCompare(bSort);
            } else {
                if (sort.by === 'status') {
                    return b[sort.by].message.localeCompare(a[sort.by].message);
                }
                if (sort.by === 'requestedInstallDate') {
                    return new Date(b[sort.by]) - new Date(a[sort.by]);
                }
                if (sort.by === 'online') {
                    return b[sort.by] - a[sort.by];
                }
                const aSort = a[sort.by] || '';
                const bSort = b[sort.by] || '';
                return bSort.localeCompare(aSort);
            }
        },
        [sort]
    );

    useEffect(() => {
        pauseApi
            .getPausedStateForQueuedApplications()
            .then((pausedResponse) => {
                setPausedState({
                    isPaused: pausedResponse.pause,
                    updatedAt: formatTimestamp(pausedResponse.updatedAt),
                    updatedBy: {
                        name: pausedResponse.updatedBy?.name,
                        email: pausedResponse.updatedBy?.email,
                    },
                });
            })
            .catch(() => {
                // Do nothing for now
            });
    }, [pauseApi]);

    const refreshList = useCallback(() => {
        setIsLoading(true);
        setDirtyCount(0);
        api.cancel();
        if (activeLeafs) {
            const onlineFilter = activeLeafs.find((filter) => filter.id === LEAF_ID.ONLINE);
            const statusFilter = activeLeafs.find((filter) => filter.id === LEAF_ID.STATUS);
            api.getApplicationInstallationsInProgress({
                orderBy: `${sort.by},${sort.direction}`,
                online: onlineFilter?.value,
                status: statusFilter?.value,
            })
                .then((content) => {
                    applicationsRef.current = content;
                })
                .catch(noop)
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [api, activeLeafs, applicationsRef, sort]);

    useEffect(() => {
        refreshList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLeafs]);

    const onSocketNotification = useCallback(
        ({ updateDescription, fullDocument }, { deleteOperation }) => {
            const { _id: patchId, ...rest } = fullDocument;
            const existsInList = applicationsRef.current.some((application) => application.patchId === patchId);

            if (!deleteOperation && patchIsInProgress(fullDocument)) {
                if (existsInList) {
                    // Update it.
                    const updatedApplications = applicationsRef.current.map((application) => {
                        if (application.patchId === patchId) {
                            return {
                                patchId,
                                online: application.online,
                                state: application.state,
                                ...rest,
                            };
                        }
                        return application;
                    });
                    applicationsRef.current = updatedApplications.sort(sorting);
                    refreshComponent(10); // Wait for 10ms silence before updating the list again.
                } else {
                    const device = applicationsRef.current.find((application) => application.deviceId === fullDocument.deviceId);
                    if (device) {
                        const onlineFilter = activeLeafs.find((filter) => filter.id === LEAF_ID.ONLINE);
                        const statusFilter = activeLeafs.find((filter) => filter.id === LEAF_ID.STATUS);
                        if (
                            (!onlineFilter && !statusFilter) ||
                            (onlineFilter?.value === device.online && !statusFilter) ||
                            (!onlineFilter && statusFilter?.value[0] === fullDocument.status.message.toLocaleLowerCase()) ||
                            (onlineFilter?.value === device.online && statusFilter?.value[0] === fullDocument.status.message.toLocaleLowerCase())
                        ) {
                            applicationsRef.current.push({
                                patchId,
                                online: device.online,
                                state: device.state,
                                ...rest,
                            });
                            applicationsRef.current = applicationsRef.current.sort(sorting);
                            refreshComponent(10);
                        }
                    } else {
                        // Just set the dirty count because we don't know anything about this device.
                        setDirtyCount((c) => c + 1);
                    }
                }
            } else if (existsInList) {
                // Remove it.
                const modifiedApplicationsList = applicationsRef.current.filter((application) => application.patchId !== patchId);
                applicationsRef.current = modifiedApplicationsList.sort(sorting);
                if (selectedItems.includes(patchId)) {
                    setSelectedItems(selectedItems.filter((selectedPatchId) => selectedPatchId !== patchId));
                }
                refreshComponent(10);
            }
        },
        [applicationsRef, refreshComponent, activeLeafs, sorting, selectedItems]
    );

    const onDeviceSocketNotification = useCallback(
        ({ fullDocument }) => {
            const { _id: deviceId, online, state } = fullDocument;
            const onlineLeaf = activeLeafs.find((leaf) => leaf.id === LEAF_ID.ONLINE);
            if (isDefined(onlineLeaf) && online !== onlineLeaf.value) {
                const modifiedApplicationsList = applicationsRef.current.filter((application) => application.deviceId !== deviceId);
                applicationsRef.current = modifiedApplicationsList.sort(sorting);
                refreshComponent(10);
            } else {
                const updatedApplications = applicationsRef.current.map((application) => {
                    if (application.deviceId === deviceId) {
                        return {
                            ...application,
                            online: online,
                            state: state,
                        };
                    }
                    return application;
                });
                applicationsRef.current = updatedApplications.sort(sorting);
                refreshComponent(10); // Wait for 10ms silence before updating the list again.
            }
        },
        [applicationsRef, refreshComponent, activeLeafs, sorting]
    );

    const onOrgConfigSocketNotification = useCallback(({ updateDescription, fullDocument }) => {
        const { pauseConfig } = fullDocument;
        setPausedState({
            isPaused: pauseConfig.pause,
            updatedAt: formatTimestamp(pauseConfig.updatedAt),
            updatedBy: {
                name: pauseConfig.updatedBy?.name,
                email: pauseConfig.updatedBy?.email,
            },
        });
    }, []);

    useOrgConfigSocket(onOrgConfigSocketNotification);
    useDevicesSocket(onDeviceSocketNotification);
    useOrgPatchingSocket(onSocketNotification);

    useEffect(() => {
        applicationsRef.current = applicationsRef.current.sort(sorting);
        refreshComponent();
    }, [sort]);

    const onMissingLeafData = (leafRef, callback) => {
        leafRef.options = [].map((name) => ({ name: name, id: name }));
        callback();
    };

    const onQueryBuilderSubmit = (leafs) => {
        setActiveLeafs(leafs);
    };

    return (
        <div className="tw-grid tw-grid-rows-auto-1fr">
            <Portal container={portalContainer}>
                <div className="tw-flex tw-gap-x-2">
                    <QueryBuilder
                        defaultConfiguration={QUERY_BUILDER_UPDATER_IN_PROGRESS_LIST}
                        onInit={onQueryBuilderSubmit}
                        onSubmit={onQueryBuilderSubmit}
                        onMissingLeafData={onMissingLeafData}
                        ref={queryBuilderRef}
                    />
                    {pausedState.isPaused !== null && (
                        <>
                            <ActionsIconButton onClick={openActionsDialog} />
                            <ActionsDialog
                                open={actionsDialogState.open}
                                anchorEl={actionsDialogState.anchorEl}
                                onClose={closeActionsDialog}
                                category="Available actions"
                                title="Application deployment"
                                actions={actionsDialogState.actions}
                                onActionClick={onActionClick}
                            />
                        </>
                    )}
                </div>
            </Portal>
            <ConfirmDialog
                open={showWarning}
                onCancel={() => {
                    setShowWarning(false);
                }}
                onConfirm={() => {
                    setShowWarning(false);
                    api.updatePausedStateForQueuedApplications(true)
                        .then((response) => {
                            // Lets the socket update the state.
                        })
                        .catch(() => {
                            // Ignore for now.
                        });
                }}
                title="Activate pause mode?"
            >
                <div className="tw-font-medium">Applications will not be deployed before deployment is manually resumed.</div>
                <div className="tw-mt-4 tw-font-medium">You can cancel queued deployment requests in pause mode.</div>
            </ConfirmDialog>
            <div>
                <Collapse
                    in={pausedState.isPaused === true}
                    unmountOnExit
                >
                    <div>
                        <WidgetPaper
                            color="red"
                            className="tw-mb-4"
                            light
                            title="Pause mode activated"
                            description={`Application deployment is paused by ${pausedState.updatedBy.email || 'unknown user'} at ${pausedState.updatedAt}`}
                            actions={
                                <Tooltip {...resumeAction.tooltipProps}>
                                    <Button
                                        variant={BUTTON.RAISED}
                                        color={BUTTON.PRIMARY}
                                        onClick={() => onActionClick(resumeAction)}
                                    >
                                        {resumeAction.name}
                                    </Button>
                                </Tooltip>
                            }
                        />
                    </div>
                </Collapse>
            </div>
            <WidgetPaper
                className="tw-h-full"
                headerless
            >
                <VirtualizedTable
                    sortBy={sort.by}
                    sortDirection={sort.direction.toUpperCase()}
                    items={applicationsRef.current}
                    totalRowCount={applicationsRef.current.length}
                    showRowCount
                    entity="application"
                    sort={onSort}
                    isLoading={isLoading}
                    noRowsRenderer={() => (
                        <EmptyState
                            entity="application"
                            isSearching={activeLeafs.length > 0}
                            onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                            iconType="lightbulbOutlined"
                            title="No application deployments in progress"
                            description="Once a request is queued or in progress, it will appear here"
                        />
                    )}
                    actions={
                        <>
                            {dirtyCount > 0 && (
                                <Tooltip content="Changes detected">
                                    <Button
                                        size={BUTTON.SMALL}
                                        color={BUTTON.PRIMARY}
                                        variant={BUTTON.RAISED}
                                        onClick={refreshList}
                                    >
                                        Refresh
                                    </Button>
                                </Tooltip>
                            )}
                            {pausedState.isPaused && (
                                <Button
                                    disabled={selectedItems.length === 0}
                                    color={BUTTON.PRIMARY}
                                    variant={BUTTON.RAISED}
                                    size={BUTTON.SMALL}
                                    onClick={() => {
                                        pauseApi
                                            .removeQueuedPatchIds(selectedItems)
                                            .then(() => {
                                                setSelectedItems([]);
                                                // Let the socket update the state.
                                            })
                                            .catch(() => {
                                                //
                                            });
                                    }}
                                >
                                    Cancel selected requests{selectedItems.length > 0 && ` (${selectedItems.length})`}
                                </Button>
                            )}
                        </>
                    }
                    columnPicker={columnPicker}
                >
                    {pausedState.isPaused && (
                        <Column
                            key="selected"
                            dataKey="selected"
                            label={
                                <Checkbox
                                    checked={!isLoading && selectedItems.length > 0 && selectedItems.length === applicationsRef.current.length}
                                    disabled={applicationsRef.current.length === 0}
                                    className="tw-ml-0.5"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedItems(applicationsRef.current.map((e) => e.patchId));
                                        } else {
                                            setSelectedItems([]);
                                        }
                                    }}
                                />
                            }
                            minWidth={40}
                            maxWidth={40}
                            disableSort
                            cellRenderer={({ rowData }) => (
                                <Checkbox
                                    checked={rowData.status.message === STATUS_QUEUED && selectedItems.includes(rowData.patchId)}
                                    disabled={rowData.status.message !== STATUS_QUEUED} // Only allow selection of queued items.
                                    onChange={(e) => {
                                        setSelectedItems((c) =>
                                            e.target.checked
                                                ? [...selectedItems, rowData.patchId]
                                                : selectedItems.filter((patchId) => patchId !== rowData.patchId)
                                        );
                                    }}
                                />
                            )}
                        />
                    )}
                    <Column
                        key="name"
                        dataKey="name"
                        label="Name"
                        minWidth={160}
                        cellRenderer={({ rowData }) => (
                            <CapaOneLink to={`windows/updater/application/${rowData.appId}?${LOCAL_STORAGE_ID.ACTIVE_PATCH_STATUS_TAB}=2`}>
                                <TwoLineCellRenderer
                                    main={rowData.name}
                                    callToAction
                                    secondary={rowData.publisher}
                                />
                            </CapaOneLink>
                        )}
                    />
                    <Column
                        key="version"
                        dataKey="version"
                        label="Version"
                        minWidth={120}
                        disableSort
                        cellRenderer={({ cellData, rowData }) => (
                            <TwoLineCellRenderer
                                main={cellData}
                                secondary={rowData.language}
                            />
                        )}
                    />

                    <Column
                        key="deviceName"
                        dataKey="deviceName"
                        label="Endpoint"
                        minWidth={160}
                        cellRenderer={({ rowData }) => (
                            <div className="tw-flex tw-items-center tw-gap-2">
                                <Tooltip
                                    content={
                                        <>
                                            <div className="tw-font-medium">Software status for</div>
                                            <div className="tw-mt-1 tw-font-semibold">{rowData.deviceName || rowData.deviceId}</div>
                                        </>
                                    }
                                    fullWidth
                                >
                                    <CapaOneLink
                                        to={'windows/device/' + rowData.deviceId + '/updates/software'}
                                        className="tw-truncate tw-font-semibold"
                                    >
                                        <Ellipsis>{rowData.deviceName || rowData.deviceId}</Ellipsis>
                                    </CapaOneLink>
                                </Tooltip>
                            </div>
                        )}
                    />
                    <Column
                        key="online"
                        dataKey="online"
                        label="Agent Status"
                        minWidth={96}
                        maxWidth={96}
                        cellRenderer={({ cellData, rowData }) => (
                            <DeviceAgentState
                                device={{ ...rowData, id: rowData.deviceId }}
                                hideLatestAgentStartup
                            />
                        )}
                    />

                    <Column
                        key="requestedInstallDate"
                        dataKey="requestedInstallDate"
                        label="Requested Date"
                        minWidth={120}
                        maxWidth={120}
                        cellRenderer={({ cellData, dataKey }) => formatTimestamp(cellData)}
                    />
                    <Column
                        key="workflowId"
                        dataKey="workflowId"
                        label="Requested By"
                        minWidth={160}
                        disableSort
                        cellRenderer={({ cellData, rowData }) => {
                            if (rowData.workflowId) {
                                return (
                                    <div className="tw-flex tw-items-center tw-gap-2 tw-font-semibold">
                                        <div>
                                            <Icon
                                                type="playCircleOutlined"
                                                className="tw-h-6 tw-w-6"
                                            />
                                        </div>
                                        <Tooltip content="View Workflow">
                                            <CapaOneLink
                                                to={'windows/workflow/' + rowData.workflowId}
                                                className="tw-truncate"
                                            >
                                                {rowData?.workflowName || rowData.workflowId}
                                            </CapaOneLink>
                                        </Tooltip>
                                    </div>
                                );
                            }
                            if (rowData.userEmail) {
                                return (
                                    <div className="tw-flex tw-items-center tw-gap-2">
                                        <Avatar className="tw-h-6 tw-w-6 tw-bg-slate-600 tw-text-xs tw-font-semibold tw-uppercase tw-leading-none tw-text-white">
                                            {rowData.userEmail.substring(0, 1)}
                                        </Avatar>
                                        <Ellipsis>{rowData.userEmail}</Ellipsis>
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                    <Column
                        key="status"
                        dataKey="status"
                        label="Status"
                        minWidth={120}
                        maxWidth={120}
                        cellRenderer={({ cellData }) => <Ellipsis>{cellData.message}</Ellipsis>}
                    />
                </VirtualizedTable>
            </WidgetPaper>
        </div>
    );
};

export { UpdaterInProgressList };
