import { TOOLTIP } from '@capasystems/constants';
import { TSocketMethod } from '@capasystems/types';
import {
    Avatar,
    Button,
    Checkbox,
    Collapse,
    Column,
    Dialog,
    DialogActions,
    DialogTitle,
    Icon,
    IconButton,
    Input,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Tab,
    Tabs,
    Tooltip,
    VirtualizedTable,
    useParams,
} from '@capasystems/ui';
import { isDefined, isUndefined, noop } from '@capasystems/utils';
import { TAndroidDeviceWithId } from '@db/party';
import { DeviceStateEnums, ENDPOINT_TYPE, ManagementModeEnums, OwnershipEnums, coreAndroidManagement } from '@thirdparty/constants';
import { SchemaBuilder, TailwindBadge } from '@thirdparty/ui';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate as useCoreNavigate } from 'react-router-dom';
import {
    ActionsDialog,
    ConfirmDialog,
    Heading,
    PageTitle,
    RenamingDialog,
    TwoLineCellRenderer,
    UserManagementAssignUserToEndpoint,
    UserTypeIcon,
    useAndroidApi,
    useAndroidEndpointSocket,
    useMessageContext,
    useNavigate,
} from '../../../index';
import { EndpointApplicationsTab } from '../components/Endpoint/AndroidEndpointApplicationsTab';
import { EndpointDashboardTab } from '../components/Endpoint/AndroidEndpointDashboardTab';
import { EndpointGroupsTab } from '../components/Endpoint/AndroidEndpointGroupsTab';
import { AndroidConfigurationsWrapper } from '../components/Endpoint/Configuration/AndroidEndpointConfigurationsWrapper';
import { EndpointInventoryTab } from '../components/Endpoint/Inventory/AndroidEndpointInventory';
import { ResultingPolicyErrorsInformation } from './android-device-list';

const tabClassName = 'tw-font-semibold tw-min-w-0 lg:tw-px-8';

const minApiLevel = {
    reboot: 24,
    clearAppData: 28,
    startLostModeFullyManaged: 30,
    startLostModeWorkProfile: 33,
};

const renameAction = {
    id: 'rename',
    name: 'Rename',
};

const manageUserAction = {
    id: 'manageUser',
    name: 'User management',
};

const policyLogAction = {
    id: 'showPolicyLog',
    name: 'Policy Log',
    disabled: false,
};

const deleteAction = {
    id: 'delete',
    name: 'Delete',
};

const lockAction = {
    id: 'LOCK',
    name: 'Lock',
    icon: 'lock',
    tooltipProps: {
        content: 'Lock the device, as if the lock screen timeout had expired',
        fullWidth: true,
    },
};

const lockActionWorkProfile = {
    ...lockAction,
    tooltipProps: {
        content: (
            <div>
                <span className="tw-block tw-text-xs">Lock the work profile</span>
                <span className="tw-mt-1 tw-block tw-text-tiny">Users will be prompted to enter their passcode</span>
            </div>
        ),
        fullWidth: true,
    },
};

const resetPasswordAction = {
    id: 'RESET_PASSWORD',
    name: 'Reset password',
    icon: 'key',
    tooltipProps: {
        content: 'Choose a new password for the device',
    },
};

const resetPasswordActionWorkProfile = {
    ...resetPasswordAction,
    tooltipProps: {
        content: 'Choose a new password for the work profile',
    },
};

const rebootAction = {
    id: 'REBOOT',
    name: 'Reboot',
    icon: 'reset',
    tooltipProps: {
        content: 'Reboot the device',
    },
};

const rebootActionDisabled = {
    ...rebootAction,
    disabled: true,
    tooltipProps: {
        content: `Only supported on fully managed devices running Android 7.0 (API level ${minApiLevel.reboot}) or higher`,
        fullWidth: true,
    },
};

const clearAppDataAction = {
    id: 'CLEAR_APP_DATA',
    name: 'Clear application data',
    icon: 'deleteSweepOutlined',
    tooltipProps: {
        content: 'Clear the application data of specified apps',
        fullWidth: true,
    },
};

const clearAppDataActionDisabled = {
    ...clearAppDataAction,
    disabled: true,
    tooltipProps: {
        content: `Only supported on devices running Android 9 (API level ${minApiLevel.clearAppData}) or higher`,
        fullWidth: true,
    },
};

const startLostModeAction = {
    id: 'START_LOST_MODE',
    name: 'Start lost mode',
    icon: 'search',
    tooltipProps: {
        content: 'Start lost mode on the device',
        fullWidth: true,
    },
};

const stopLostModeAction = {
    id: 'STOP_LOST_MODE',
    name: 'Stop lost mode',
    icon: 'searchOff',
    tooltipProps: {
        content: 'Stop lost mode on the device',
        fullWidth: true,
    },
};

const startLostModeActionDisabled = {
    ...startLostModeAction,
    disabled: true,
    tooltipProps: {
        content: `Only supported on fully managed devices running Android 11 (API level ${minApiLevel.startLostModeFullyManaged}) or higher, or on company owned devices with a work profile running Android 13 (API level ${minApiLevel.startLostModeWorkProfile}) or higher.`,
    },
};

const stopLostModeActionDisabled = {
    ...stopLostModeAction,
    disabled: true,
    tooltipProps: {
        content: `Only supported on fully managed devices running Android 11 (API level ${minApiLevel.startLostModeFullyManaged}) or higher, or on company owned devices with a work profile running Android 13 (API level ${minApiLevel.startLostModeWorkProfile}) or higher.`,
    },
};

const getDataAction = {
    id: 'GETDATA',
    name: 'Synchronize device',
    icon: 'configSync',
    tooltipProps: {
        content: 'Synchronize endpoint with latest policy',
        fullWidth: true,
    },
};

const startLostModeSchema = {
    schema: {
        type: 'object',
        schemaId: 'StartLostMode',
        properties: {
            lostMessage: {
                type: 'object',
                properties: {
                    defaultMessage: {
                        title: 'Message',
                        description: 'A Message displayed on the phone when lost mode has been activated.',
                        required: true,
                        type: 'string',
                    },
                },
            },
            lostPhoneNumber: {
                type: 'object',
                properties: {
                    defaultMessage: {
                        title: 'Phone number',
                        description: 'A phone number to call when the phone is found.',
                        type: 'string',
                    },
                },
            },
            lostEmailAddress: {
                type: 'string',
                title: 'Email address',
                description: 'An email address to contact when the phone is found.',
            },
            lostStreetAddress: {
                type: 'object',
                properties: {
                    defaultMessage: {
                        title: 'Street address',
                        description: 'The address where the phone was lost.',
                        type: 'string',
                    },
                },
            },
            lostOrganization: {
                type: 'object',
                properties: {
                    defaultMessage: {
                        title: 'Organization',
                        description: 'The organization where the phone was lost.',
                        type: 'string',
                    },
                },
            },
        },
    },
};

// Available device fields: https://developers.google.com/android/management/reference/rest/v1/enterprises.devices
const AndroidEndpoint = () => {
    const { deviceId, '*': splat } = useParams();
    const selectedTab = splat === '' ? 'settings' : splat?.split('/')[0];
    const [endpoint, setEndpoint] = useState<TAndroidDeviceWithId>();
    const androidApi = useAndroidApi();
    const [appliedPolicy, setAppliedPolicy] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { appendBaseURL } = useNavigate();
    const coreNavigate = useCoreNavigate();

    const onTabChange = (e: any, selectedTabId: any) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(appendBaseURL(`android/device/${deviceId}/${selectedTabId}`), '_blank');
        } else {
            coreNavigate(selectedTabId);
        }
    };

    useAndroidEndpointSocket(
        deviceId,
        useCallback<TSocketMethod<TAndroidDeviceWithId>>(({ fullDocument }, { updateOperation, deleteOperation }) => {
            if (updateOperation) {
                const { _id: id, ...rest } = fullDocument;
                setEndpoint((c) => ({
                    id,
                    ...rest,
                    user: c?.user,
                }));
                if (isDefined(fullDocument.resultingPolicy)) {
                    setAppliedPolicy(fullDocument.resultingPolicy);
                }
            } else if (deleteOperation) {
                coreNavigate('..');
            }
        }, [])
    );

    useEffect(() => {
        androidApi
            .getDevice(deviceId)
            .then((response) => {
                setEndpoint(response);
                const updateAppliedPolicy = (policyResponse: any) => {
                    setAppliedPolicy(policyResponse);
                    setLoading(false);
                };

                if (isDefined(response.resultingPolicy)) {
                    updateAppliedPolicy(response.resultingPolicy);
                } else if (isDefined(response.androidData.appliedPolicyName)) {
                    const policyName = response.androidData.appliedPolicyName.split(`/`).pop();
                    androidApi
                        .getPolicy(policyName)
                        .then(updateAppliedPolicy)
                        .catch((policyResponseError) => {
                            console.log(policyResponseError);
                            setErrorMessage('Could not load device policy');
                        });
                } else {
                    setErrorMessage(
                        DeviceStateEnums[response.androidData.state] === DeviceStateEnums.PROVISIONING ? DeviceStateEnums.PROVISIONING : 'No policy applied yet'
                    );
                }
            })
            .catch((deviceResponseError) => {
                console.log(deviceResponseError);
                setErrorMessage('Could not find device');
            });
    }, [androidApi, deviceId]);

    if (errorMessage) {
        return (
            <LayoutCentered>
                <b>{errorMessage}</b>
            </LayoutCentered>
        );
    }
    if (loading) {
        return (
            <LayoutCentered>
                <Loading></Loading>
            </LayoutCentered>
        );
    }
    return (
        <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-auto-1fr tw-px-4">
            <AndroidEndpointTopbar
                endpoint={endpoint}
                setEndpoint={setEndpoint}
                setLoading={setLoading}
                inKioskMode={appliedPolicy.kioskCustomLauncherEnabled}
            />
            <Tabs
                value={selectedTab}
                onChange={onTabChange}
                onRails
            >
                <Tab
                    label="Dashboard"
                    value="dashboard"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Inventory"
                    value="inventory"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Configurations"
                    value="configurations"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Applications"
                    value="applications"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Groups"
                    value="groups"
                    disableRipple
                    className={tabClassName}
                />
            </Tabs>
            <div className="tw-overflow-auto">
                <Routes>
                    <Route
                        path="dashboard"
                        element={
                            <EndpointDashboardTab
                                endpoint={endpoint}
                                appliedPolicy={appliedPolicy}
                                inKioskMode={appliedPolicy.kioskCustomLauncherEnabled}
                            />
                        }
                    />
                    <Route path="inventory">
                        <Route
                            path=":inventoryId"
                            element={<EndpointInventoryTab endpoint={endpoint} />}
                        />
                        <Route
                            index
                            element={
                                <Navigate
                                    to="software"
                                    replace
                                />
                            }
                        />
                    </Route>

                    <Route
                        path="configurations"
                        element={<AndroidConfigurationsWrapper endpoint={endpoint} />}
                    />
                    <Route
                        path="applications"
                        element={<EndpointApplicationsTab endpoint={endpoint} />}
                    />
                    <Route
                        path="groups"
                        element={<EndpointGroupsTab endpoint={endpoint} />}
                    />
                    <Route
                        path="*"
                        element={
                            <LayoutCentered>
                                <PageTitle>Page not found</PageTitle>
                            </LayoutCentered>
                        }
                    />
                </Routes>
            </div>
        </div>
    );
};

type AndroidEndpointTopbarProps = {
    endpoint?: TAndroidDeviceWithId;
    setEndpoint: React.Dispatch<React.SetStateAction<TAndroidDeviceWithId | undefined>>;
    setLoading: (loading: boolean) => void;
    inKioskMode: boolean;
};

const AndroidEndpointTopbar: React.FC<AndroidEndpointTopbarProps> = ({ endpoint, setEndpoint, setLoading, inKioskMode }) => {
    const androidApi = useAndroidApi();
    const navigate = useNavigate();
    const { handleApiError } = useMessageContext();
    const inputRef = useRef({});
    const [actionDialogProps, setActionDialogProps] = useState<{
        open: boolean;
        anchorEl: HTMLElement | null;
        category: string;
        title: string;
        actions?: any[];
    }>({
        open: false,
        anchorEl: null,
        category: '',
        title: '',
        actions: [],
    });
    const [deleteDeviceToken, setDeleteDeviceToken] = useState<string | null>(null);
    const [showPolicyLog, setShowPolicyLog] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const [isManagingUser, setIsManagingUser] = useState(false);
    const [resetPasswordDialogProps, setResetPasswordDialogProps] = useState<{ open: boolean; newPassword: string; resetPasswordFlags: any[] }>({
        open: false,
        newPassword: '',
        resetPasswordFlags: [],
    });
    const [startLostModeDialogProps, setStartLostModeDialogProps] = useState({
        open: false,
        lostMessageParams: {},
    });
    const [showClearAppDataDialog, setShowClearAppDataDialog] = useState(false);

    const openMenu = (e: any) => {
        const noPolicyLogAvailable = isUndefined(endpoint?.resultingConfigurationDescription);
        const hasManagedWorkProfile = ManagementModeEnums[endpoint?.androidData.managementMode] === ManagementModeEnums.PROFILE_OWNER;
        const isPersonallyOwned = ManagementModeEnums[endpoint?.androidData.ownership] === OwnershipEnums.PERSONALLY_OWNED;
        const lostModeRequestAvailabilityHandler = () => {
            if (hasManagedWorkProfile && endpoint?.androidData.apiLevel > minApiLevel.startLostModeWorkProfile && !isPersonallyOwned) {
                return [startLostModeAction, stopLostModeAction];
            } else if (!hasManagedWorkProfile && endpoint?.androidData.apiLevel > minApiLevel.startLostModeFullyManaged) {
                return [startLostModeAction, stopLostModeAction];
            } else {
                return [startLostModeActionDisabled, stopLostModeActionDisabled];
            }
        };
        setActionDialogProps({
            open: true,
            anchorEl: e.currentTarget,
            actions: [
                {
                    id: 'requestAction',
                    name: 'Request',
                    actions: [
                        getDataAction,
                        hasManagedWorkProfile ? lockActionWorkProfile : lockAction,
                        hasManagedWorkProfile ? resetPasswordActionWorkProfile : resetPasswordAction,
                        hasManagedWorkProfile || endpoint?.androidData.apiLevel < minApiLevel.reboot ? rebootActionDisabled : rebootAction,
                        endpoint?.androidData.apiLevel < minApiLevel.clearAppData ? clearAppDataActionDisabled : clearAppDataAction,
                        ...lostModeRequestAvailabilityHandler(),
                    ],
                },
                renameAction,
                manageUserAction,
                {
                    ...policyLogAction,
                    disabled: noPolicyLogAvailable,
                    tooltipProps: noPolicyLogAvailable
                        ? {
                              content: 'No policy log available for this device',
                          }
                        : {},
                },
                deleteAction,
            ],
            category: 'Endpoint',
            title: endpoint?.name || '',
        });
    };

    const closeMenu = () => {
        setActionDialogProps((c) => ({
            ...c,
            open: false,
        }));
    };

    const onDeleteDevice = () => {
        closeMenu();
        if (deleteDeviceToken) {
            setLoading(true);
            androidApi
                .deleteDevice(endpoint?.id)
                .then(() => {
                    navigate.to('android/device', true);
                })
                .catch((error) => {
                    setDeleteDeviceToken('failedToDelete');
                    setLoading(false);
                });
        } else {
            setDeleteDeviceToken('markedForDelete');
        }
    };

    const cancelDeleteOperation = () => {
        setDeleteDeviceToken(null);
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            closeMenu();
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName: string) => {
        setIsRenaming(false);
        androidApi
            .updateDevice(endpoint?.id, {
                name: newName,
            })
            .then(noop)
            .catch(() => {
                setIsRenaming(true);
            });
    };

    const onActionClick = (action: any) => {
        closeMenu();
        switch (action.id) {
            case getDataAction.id: {
                androidApi.syncDevice(endpoint?.id).then(noop).catch(handleApiError);
                break;
            }
            case renameAction.id:
                onRename();
                break;
            case deleteAction.id:
                onDeleteDevice();
                break;
            case policyLogAction.id:
                setShowPolicyLog(true);
                break;
            case manageUserAction.id:
                setIsManagingUser(true);
                break;
            case lockAction.id:
                androidApi.sendLockDeviceCommand(endpoint?.id).then(noop).catch(handleApiError);
                break;
            case resetPasswordAction.id:
                if (resetPasswordDialogProps.open) {
                    androidApi
                        .sendResetPasswordCommand(endpoint?.id, resetPasswordDialogProps)
                        .then(() => {
                            setResetPasswordDialogProps((c) => ({
                                ...c,
                                open: false,
                            }));
                        })
                        .catch(handleApiError);
                } else {
                    setResetPasswordDialogProps({
                        open: true,
                        newPassword: '',
                        resetPasswordFlags: [],
                    });
                }
                break;
            case rebootAction.id:
                androidApi.sendRebootDeviceCommand(endpoint?.id).then(noop).catch(handleApiError);
                break;
            case clearAppDataAction.id:
                setShowClearAppDataDialog(true);
                break;
            case startLostModeAction.id:
                if (startLostModeDialogProps.open) {
                    androidApi
                        .sendStartLostModeCommand(endpoint?.id, startLostModeDialogProps.lostMessageParams)
                        .then(() => {
                            setStartLostModeDialogProps((c) => ({
                                ...c,
                                open: false,
                                lostMessageParams: {},
                            }));
                        })
                        .catch(handleApiError);
                } else {
                    setStartLostModeDialogProps({
                        open: true,
                        lostMessageParams: inputRef.current,
                    });
                }
                break;
            case stopLostModeAction.id:
                androidApi.sendStopLostModeCommand(endpoint?.id).then(noop).catch(handleApiError);
                break;
        }
    };

    return (
        <Page title={endpoint?.androidData.hardwareInfo?.model}>
            <div className="tw-pb-3 tw-pt-4">
                <LayoutRow
                    verticalAlign="center"
                    className="tw-gap-x-6"
                >
                    <PageTitle
                        description={
                            <div>
                                {endpoint?.androidData.hardwareInfo?.model}
                                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                                {ManagementModeEnums[endpoint?.androidData.managementMode]}
                            </div>
                        }
                    >
                        {endpoint?.name}
                    </PageTitle>
                    <EndpointUserInfo
                        endpoint={endpoint}
                        setIsManagingUser={setIsManagingUser}
                    />
                    {(() => {
                        if (endpoint?.androidData.state === 'LOST') {
                            return (
                                <Tooltip
                                    content="This device is currently in Lost Mode"
                                    position={TOOLTIP.POSITION.TOP_END}
                                >
                                    {/* @ts-ignore - this is not typed */}
                                    <TailwindBadge
                                        color="red"
                                        className="tw-gap-1"
                                        size="small"
                                    >
                                        <Icon
                                            type="error"
                                            className="tw-text-lg"
                                        />
                                        <span>Lost Mode</span>
                                    </TailwindBadge>
                                </Tooltip>
                            );
                        } else if (inKioskMode) {
                            return (
                                <Tooltip
                                    content="This device is currently in Kiosk Mode"
                                    position={TOOLTIP.POSITION.TOP_END}
                                >
                                    {/* @ts-ignore - this is not typed  */}
                                    <TailwindBadge
                                        color="cyan"
                                        className="tw-gap-1"
                                        size="small"
                                        dark
                                    >
                                        <Icon
                                            type="kioskMode"
                                            className="tw-text-lg"
                                        />
                                        <span>Kiosk Mode</span>
                                    </TailwindBadge>
                                </Tooltip>
                            );
                        }
                    })()}
                    <IconButton
                        onClick={openMenu}
                        size="large"
                        noMargin
                    >
                        <Icon type="moreVert" />
                    </IconButton>
                    <ResultingPolicyErrorsInformation endpoint={endpoint} />
                    <div className="tw-flex-1" />
                </LayoutRow>
                {/* @ts-ignore - this is not typed  */}
                <ActionsDialog
                    {...actionDialogProps}
                    onClose={closeMenu}
                    // @ts-ignore - this is not typed
                    onActionClick={onActionClick}
                />
                {/* @ts-ignore - this is not typed  */}
                <RenamingDialog
                    onCancel={onRename}
                    onSubmit={onRenameSubmit}
                    currentName={endpoint?.name}
                    open={isRenaming}
                />
                {/* @ts-ignore - this is not typed  */}
                <UserManagementAssignUserToEndpoint
                    open={isManagingUser}
                    onSubmit={(user: any) => {
                        setIsManagingUser(false);
                        setEndpoint((c) => {
                            return c
                                ? {
                                      ...c,
                                      user,
                                  }
                                : undefined;
                        });
                    }}
                    endpointType={ENDPOINT_TYPE.ANDROID}
                    endpoint={endpoint}
                    onClose={() => {
                        setIsManagingUser(false);
                    }}
                />
                {/* @ts-ignore - this is no typed */}
                <ConfirmDialog
                    open={resetPasswordDialogProps.open}
                    // @ts-ignore - this is no typed
                    title={
                        <PageTitle
                            description={endpoint?.name}
                            className="tw-mb-2"
                        >
                            {resetPasswordAction.name}
                        </PageTitle>
                    }
                    onConfirm={() => {
                        onActionClick(resetPasswordAction);
                    }}
                    onCancel={() => {
                        setResetPasswordDialogProps((c) => ({
                            ...c,
                            open: false,
                        }));
                    }}
                >
                    <div className="tw-grid tw-gap-4">
                        <Input
                            value={resetPasswordDialogProps.newPassword}
                            callToAction
                            autoFocus
                            placeholder="New password"
                            type="text"
                            disableSpinner
                            onChange={(e) => {
                                setResetPasswordDialogProps((c) => ({
                                    ...c,
                                    newPassword: e.target.value,
                                }));
                            }}
                            className="tw-mb-2"
                            inputClassName={classNames({
                                'tw-tracking-widest tw-font-mono': resetPasswordDialogProps.newPassword !== '',
                            })}
                        />
                        <Checkbox
                            checked={resetPasswordDialogProps.resetPasswordFlags.includes('REQUIRE_ENTRY')}
                            label={<b>Don't allow other admins to change the password again until the user has entered it</b>}
                            onChange={(e, checked) => {
                                if (checked) {
                                    setResetPasswordDialogProps((c) => ({
                                        ...c,
                                        resetPasswordFlags: [...c.resetPasswordFlags, 'REQUIRE_ENTRY'],
                                    }));
                                } else {
                                    setResetPasswordDialogProps((c) => ({
                                        ...c,
                                        resetPasswordFlags: c.resetPasswordFlags.filter((flag) => flag !== 'REQUIRE_ENTRY'),
                                    }));
                                }
                            }}
                        />
                        <Checkbox
                            checked={resetPasswordDialogProps.resetPasswordFlags.includes('LOCK_NOW')}
                            label={<b>Lock the device after password reset</b>}
                            onChange={(e, checked) => {
                                if (checked) {
                                    setResetPasswordDialogProps((c) => ({
                                        ...c,
                                        resetPasswordFlags: [...c.resetPasswordFlags, 'LOCK_NOW'],
                                    }));
                                } else {
                                    setResetPasswordDialogProps((c) => ({
                                        ...c,
                                        resetPasswordFlags: c.resetPasswordFlags.filter((flag) => flag !== 'LOCK_NOW'),
                                    }));
                                }
                            }}
                        />
                        <Checkbox
                            checked={resetPasswordDialogProps.resetPasswordFlags.includes('DO_NOT_ASK_CREDENTIALS_ON_BOOT')}
                            label={<b>Don't ask for user credentials on device boot</b>}
                            onChange={(e, checked) => {
                                if (checked) {
                                    setResetPasswordDialogProps((c) => ({
                                        ...c,
                                        resetPasswordFlags: [...c.resetPasswordFlags, 'DO_NOT_ASK_CREDENTIALS_ON_BOOT'],
                                    }));
                                } else {
                                    setResetPasswordDialogProps((c) => ({
                                        ...c,
                                        resetPasswordFlags: c.resetPasswordFlags.filter((flag) => flag !== 'DO_NOT_ASK_CREDENTIALS_ON_BOOT'),
                                    }));
                                }
                            }}
                        />
                    </div>
                </ConfirmDialog>
                {/* @ts-ignore - this is not typed */}
                <ConfirmDialog
                    open={startLostModeDialogProps.open}
                    // @ts-ignore - this is not typed
                    size="md"
                    // @ts-ignore - this is not typed
                    title={
                        <PageTitle
                            description={endpoint?.name}
                            className="tw-mb-2"
                        >
                            {startLostModeAction.name}
                        </PageTitle>
                    }
                    onConfirm={() => {
                        onActionClick(startLostModeAction);
                    }}
                    onCancel={() => {
                        setStartLostModeDialogProps((c) => ({
                            ...c,
                            open: false,
                        }));
                    }}
                >
                    <SchemaBuilder
                        {...startLostModeSchema}
                        currentState={inputRef.current}
                    />
                </ConfirmDialog>
                <ClearAppDataDialog
                    open={showClearAppDataDialog}
                    endpoint={endpoint}
                    onClose={() => {
                        setShowClearAppDataDialog(false);
                    }}
                />
                {/* @ts-ignore - this is not typed  */}
                <ConfirmDialog
                    open={deleteDeviceToken !== null}
                    onCancel={cancelDeleteOperation}
                    onConfirm={onDeleteDevice}
                    // @ts-ignore - this is not typed
                    title={null}
                >
                    <PageTitle description={endpoint?.name}>
                        {ManagementModeEnums[endpoint?.androidData.managementMode] === ManagementModeEnums.PROFILE_OWNER
                            ? 'Delete work profile from the device?'
                            : 'Delete and reset device to factory settings?'}
                    </PageTitle>
                    <div className="tw-mt-4 tw-rounded-lg tw-bg-red-50 tw-p-6 tw-text-center tw-ring-1 tw-ring-red-100">
                        <PageTitle
                            category="Important"
                            description="This operation cannot be reverted and all endpoint related data will be deleted"
                            className=" tw-text-red-900"
                            color="inherit"
                        >
                            {ManagementModeEnums[endpoint?.androidData.managementMode] === ManagementModeEnums.PROFILE_OWNER
                                ? 'Corporate apps and data will be deleted'
                                : 'A factory reset erases all data from the device'}
                        </PageTitle>
                    </div>
                    {deleteDeviceToken === 'failedToDelete' && (
                        // @ts-ignore - this is not typed
                        <Heading
                            subheading
                            regular
                            className="tw-mb-2 tw-mt-6 tw-text-red-700"
                        >
                            Something went wrong. Please try again.
                        </Heading>
                    )}
                </ConfirmDialog>
                <Dialog
                    open={showPolicyLog}
                    onClose={() => setShowPolicyLog(false)}
                    size="lg"
                >
                    <DialogTitle>Policy Log</DialogTitle>
                    <div className="tw-grid-cols-auto tw-grid tw-max-h-full tw-overflow-auto">
                        <div className="tw-max-h-full tw-overflow-auto tw-whitespace-pre-wrap tw-break-words tw-bg-slate-800 tw-p-4 tw-font-mono tw-text-tiny tw-leading-loose tw-text-white">
                            {endpoint?.resultingConfigurationDescription}
                        </div>
                    </div>
                    <DialogActions>
                        <Button onClick={() => setShowPolicyLog(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Page>
    );
};

type ClearAppDataDialogProps = {
    open: boolean;
    endpoint?: TAndroidDeviceWithId;
    onClose: () => void;
};

const ClearAppDataDialog: React.FC<ClearAppDataDialogProps> = ({ open, endpoint, onClose }) => {
    const androidApi = useAndroidApi();
    const [packageNames, setPackageNames] = useState<any[]>([]);
    const [applicationsList, setApplicationsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const onEnter = () => {
        setPackageNames([]);
        setIsProcessing(false);
        if (endpoint?.resultingPolicy.applications && endpoint?.resultingPolicy.applications.length > 0) {
            setIsLoading(true);
            androidApi
                .getApplications()
                .then((applicationsResponse) => {
                    setApplicationsList(
                        endpoint.resultingPolicy.applications?.map((application: any) => {
                            const appData = applicationsResponse.find((app: any) => {
                                if (app.configurationType === coreAndroidManagement.configurationType.webapp) {
                                    return app.googlePlayId.endsWith(application.packageName);
                                }
                                return (
                                    app.configurationType === coreAndroidManagement.configurationType.application &&
                                    app.data.applications.some((a) => a.packageName === application.packageName)
                                );
                            });

                            return {
                                ...application,
                                ...appData,
                            };
                        })
                    );
                })
                .catch((e) => {
                    console.log('error', e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    const onConfirm = () => {
        setIsProcessing(true);
        androidApi
            .sendClearApplicationDataCommand(endpoint?.id, packageNames)
            .then(() => {
                onClose();
            })
            .catch((e) => {
                setIsProcessing(false);
                console.log(e);
            });
    };

    return (
        // @ts-ignore - this is not typed
        <ConfirmDialog
            // @ts-ignore - this is not typed
            onEnter={onEnter}
            open={open}
            // @ts-ignore - this is not typed
            title={
                <>
                    <PageTitle
                        description={endpoint?.name}
                        className="tw-mb-2"
                    >
                        {clearAppDataAction.name}
                    </PageTitle>
                    <p className="tw-text-sm">
                        Note that an application can store data outside of its application data, for example in external storage or in a user dictionary.
                    </p>
                </>
            }
            onConfirm={onConfirm}
            onCancel={onClose}
            dialogContentClassName="tw-px-0 tw-pt-0 tw-h-112"
            disabled={packageNames.length === 0}
            isProcessing={isProcessing}
        >
            {/* @ts-ignore - this is not typed */}
            <VirtualizedTable
                items={applicationsList}
                disableHeader
                noRowsRenderer={() => <LayoutCentered>No assigned applications</LayoutCentered>}
                isLoading={isLoading}
                onRowClick={({ rowData }: { rowData: any }) => {
                    if (!isProcessing) {
                        if (packageNames.includes(rowData.packageName)) {
                            setPackageNames((currentPackageNames) => {
                                return currentPackageNames.filter((currentPackageName) => currentPackageName !== rowData.packageName);
                            });
                        } else {
                            setPackageNames([...packageNames, rowData.packageName]);
                        }
                    }
                }}
            >
                <Column
                    minWidth={32}
                    maxWidth={32}
                    dataKey="packageName"
                    disableSort
                    label=""
                    cellRenderer={({ rowData }) => {
                        return <Checkbox checked={packageNames.includes(rowData.packageName)} />;
                    }}
                />
                <Column
                    minWidth={32}
                    maxWidth={32}
                    dataKey="iconUrl"
                    label=""
                    cellRenderer={({ rowData }) => {
                        if (rowData.iconUrl) {
                            return (
                                <Avatar
                                    src={rowData.iconUrl}
                                    alt={rowData.name}
                                    variant="rounded"
                                    className="tw-h-8 tw-w-8"
                                />
                            );
                        }
                        return (
                            <Icon
                                type="app"
                                className="tw-h-8 tw-w-8"
                            />
                        );
                    }}
                />
                <Column
                    minWidth={160}
                    dataKey="name"
                    label="Name"
                    cellRenderer={({ rowData }) => {
                        return (
                            <TwoLineCellRenderer
                                main={rowData.name}
                                callToAction
                                secondary={rowData.installTypeName}
                            />
                        );
                    }}
                />
            </VirtualizedTable>
        </ConfirmDialog>
    );
};

type EndpointUserInfoProps = {
    endpoint?: TAndroidDeviceWithId & { user?: any };
    setIsManagingUser: React.Dispatch<React.SetStateAction<boolean>>;
};

const EndpointUserInfo: React.FC<EndpointUserInfoProps> = ({ endpoint, setIsManagingUser }) => {
    const navigate = useNavigate();

    const [mouseEntered, setMouseEntered] = useState(false);

    if (endpoint?.user) {
        return (
            <div>
                <Button
                    variant="outlined"
                    className="tw-rounded-xl tw-border-slate-200 tw-bg-slate-100 tw-px-3 tw-py-2.5 hover:tw-bg-slate-200"
                    onClick={() => navigate.to(`management/user/${endpoint?.user.id}`)}
                    onMouseEnter={() => setMouseEntered(true)}
                    onMouseLeave={() => setMouseEntered(false)}
                    disableRipple
                >
                    <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-items-center tw-gap-x-3 tw-text-left">
                        <div>
                            <UserTypeIcon
                                type={endpoint?.user.type}
                                className="tw-h-6 tw-w-6"
                                deleted={endpoint?.user.deleted}
                            />
                        </div>
                        <Tooltip
                            content="View user"
                            disableHoverListener={endpoint?.user.deleted}
                        >
                            <div>
                                <div className="tw-text-xs tw-font-semibold tw-leading-none">{endpoint.user.name}</div>
                                <div className="tw-mt-1 tw-block tw-text-xs tw-font-medium tw-leading-none">{endpoint.user.email}</div>
                            </div>
                        </Tooltip>
                        <div>
                            <Collapse
                                in={mouseEntered}
                                unmountOnExit
                                orientation="horizontal"
                            >
                                <Tooltip content={manageUserAction.name}>
                                    <Icon
                                        type="edit"
                                        color="inherit"
                                        className="tw-text-blue-500 hover:tw-text-sky-700"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setIsManagingUser(true);
                                        }}
                                    />
                                </Tooltip>
                            </Collapse>
                        </div>
                    </div>
                </Button>
            </div>
        );
    }
    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                className="tw-py-2"
                noMargin
                onClick={() => {
                    setIsManagingUser(true);
                }}
            >
                <LayoutRow verticalAlign="center">
                    <Icon
                        type="addUser"
                        className="tw-mr-2 tw-text-xl"
                    />
                    <div className="tw-text-xs tw-font-bold">Assign user</div>
                </LayoutRow>
            </Button>
        </div>
    );
};

export { AndroidEndpoint };
