import { LayoutRow, Page, Paper, Tab, Tabs, useParams } from '@capasystems/ui';
import { Url, isDefined } from '@capasystems/utils';
import {
    AppleAppLayerVPNConfiguration,
    AppleAppLockConfiguration,
    AppleCellularConfiguration,
    AppleDNSConfiguration,
    AppleDnsProxyConfiguration,
    AppleDomainsConfiguration,
    AppleHomeScreenLayoutConfiguration,
    AppleInstallApplicationCommand,
    AppleLdapConfiguration,
    AppleLockScreenMessageConfiguration,
    AppleNetworkUsageRulesConfiguration,
    AppleNotificationSettingsConfiguration,
    ApplePasscodeConfiguration,
    AppleRestrictionsConfiguration,
    AppleScepConfiguration,
    AppleVPNConfiguration,
    AppleWallpaperConfiguration,
    AppleWebClipConfiguration,
    AppleWebContentFilterConfiguration,
    AppleWifiConfiguration,
} from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import classNames from 'classnames';
import { React, useEffect, useMemo, useRef, useState } from 'react';
import { QueryBuilder, SchemaBuilder, useNavigate } from '../../../index';
import { useAppleApi } from '../../hooks/useApi/useApi';
import { AppleHomeScreenLayoutView } from './AppleHomeScreenLayout';

const configurationTypes = [
    AppleVPNConfiguration.schema,
    AppleLockScreenMessageConfiguration.schema,
    AppleWebClipConfiguration.schema,
    AppleDNSConfiguration.schema,
    AppleDomainsConfiguration.schema,
    AppleRestrictionsConfiguration.schema,
    ApplePasscodeConfiguration.schema,
    AppleWifiConfiguration.schema,
    AppleCellularConfiguration.schema,
    AppleInstallApplicationCommand.schema,
    AppleWebContentFilterConfiguration.schema,
    AppleLdapConfiguration.schema,
    AppleAppLayerVPNConfiguration.schema,
    AppleDnsProxyConfiguration.schema,
    AppleNetworkUsageRulesConfiguration.schema,
    AppleNotificationSettingsConfiguration.schema,
    AppleScepConfiguration.schema,
    AppleWallpaperConfiguration.schema,
    AppleHomeScreenLayoutConfiguration.schema,
    AppleAppLockConfiguration.schema,
].sort((a, b) => {
    const categoryCalc = a.category.toLocaleLowerCase().localeCompare(b.category.toLocaleLowerCase());
    if (categoryCalc === 0) {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    } else {
        return categoryCalc;
    }
});

const AppleConfigurationSummary = ({ configuration, isApplication }) => {
    const [selectedTab, setSelectedTab] = useState(() => {
        if (isApplication) {
            return AppleInstallApplicationCommand.schema.schemaId;
        }
        return configurationTypes;
    });
    const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Search configuration' });
    const queryBuilderRef = useRef({});
    const [searchTerm, setSearchTerm] = useState('');
    const { configurationId } = useParams();
    const appleApi = useAppleApi();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(isDefined(configurationId));
    const [errorMessage, setErrorMessage] = useState(null);
    const { applicationId } = useParams();
    const [schemaBuilderSchema, setSchemaBuilderSchema] = useState(configurationTypes.find((cc) => cc.schemaId === selectedTab));
    const changeTab = (_, tab) => {
        const configurationType = configurationTypes.find((cc) => cc.schemaId === tab);
        setSchemaBuilderSchema(configurationType);
        Url.set('schemaId', tab);
        setSelectedTab(tab);
    };

    const navigateToApplicationSummary = () => {
        navigate.to(`apple/application/${applicationId}/membership`);
    };

    const onSubmit = ([activeLeaf]) => {
        const search = activeLeaf ? activeLeaf.value.toLowerCase() : '';
        setSearchTerm(search);
    };

    const propertiesBuilderRef = useRef({});
    const propertiesBuilderRefKeys = Object.keys(propertiesBuilderRef.current);

    useEffect(() => {
        if (isDefined(configurationId)) {
            appleApi
                .getAppleConfiguration(configurationId)
                .then((foundConfiguration) => {
                    propertiesBuilderRef.current = foundConfiguration.data || {};
                    const keys = Object.keys(propertiesBuilderRef.current);
                    if (keys.length !== 0) {
                        const tab = configurationTypes.find((ct) => {
                            return Object.keys(ct.properties).some((propertiesKey) => keys.includes(propertiesKey));
                        });
                        setSchemaBuilderSchema(tab);
                        Url.set('schemaId', tab?.schemaId);
                        setSelectedTab(tab?.schemaId);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setErrorMessage('Did not fetch the configuration data');
                });
        } else {
            if (isDefined(applicationId) && isApplication) {
                appleApi
                    .getSingleAppleApplication(applicationId)
                    .then((application) => {
                        propertiesBuilderRef.current = application.data || {};
                        navigateToApplicationSummary();
                        Url.set('schemaId', AppleInstallApplicationCommand.schemaId);
                        setLoading(false);
                    })
                    .catch(() => {
                        setErrorMessage('Failed to fetch application data');
                    });
            }
        }
    }, [appleApi, configurationId, applicationId, isApplication, setLoading]);

    const memorizedTabs = useMemo(() => {
        const tabs = [];
        Object.entries(Object.groupBy(configurationTypes, (ct) => ct.category))
            .sort(([keyA], [keyB]) => keyA.toLocaleLowerCase().localeCompare(keyB.toLocaleLowerCase()))
            .forEach(([key, ctArray]) => {
                const categoryMatches = key.toLowerCase().includes(searchTerm.toLowerCase());

                const matchingCTs = ctArray.filter(
                    (ct) =>
                        Object.keys(ct.properties).some((propertiesKey) => propertiesBuilderRefKeys.includes(propertiesKey)) &&
                        (ct.title.toLowerCase().includes(searchTerm.toLowerCase()) || categoryMatches)
                );

                if (matchingCTs.length > 0) {
                    tabs.push(
                        <Tab
                            value={key}
                            disabled
                            className={classNames('tw-mb-1 tw-min-h-0 tw-py-0 tw-opacity-100', { 'tw-mt-2': tabs.length === 0, 'tw-mt-6': tabs.length !== 0 })}
                            label={<div className="tw-w-full tw-text-left tw-text-xs tw-text-neutral-500">{key}</div>}
                            key={'cat - ' + key}
                        />
                    );

                    matchingCTs.forEach((ct) => {
                        tabs.push(
                            <Tab
                                value={ct.schemaId}
                                classes={{ root: 'tw-pl-4' }}
                                label={
                                    <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center tw-gap-x-6">
                                        <div className="tw-mr-2 tw-text-left">{ct.title}</div>
                                    </div>
                                }
                                key={'tab - ' + ct.title}
                                disableRipple
                            />
                        );
                    });
                }
            });
        return tabs;
    }, [configurationTypes, propertiesBuilderRefKeys, searchTerm]);

    if (loading) {
        if (errorMessage) {
            return (
                <div>
                    <h2 className="tw-mb-4">{errorMessage}</h2>
                </div>
            );
        }
        return null;
    }

    if (!propertiesBuilderRef.current || Object.keys(propertiesBuilderRef.current).length === 0) {
        return (
            <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
                <div className="tw-p-6 tw-font-semibold">No configuration data found</div>
            </div>
        );
    }
    return (
        <Page title={isApplication ? 'Application · Summary' : 'Configuration · Summary'}>
            <div className="tw-col-span-1 tw-flex tw-h-full">
                <div className="tw-col-span-3">
                    <LayoutRow
                        align="space-between"
                        verticalAlign="center"
                    ></LayoutRow>
                </div>
                {isApplication && (
                    <div>
                        {errorMessage !== null && (
                            <div className="tw-col-span-3 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700">{errorMessage}</div>
                        )}
                    </div>
                )}

                {!isApplication && (
                    <div
                        className="tw-grid tw-h-full tw-grid-rows-auto-1fr tw-gap-y-4"
                        style={{ minWidth: 275, marginRight: 20 }}
                    >
                        <QueryBuilder
                            defaultConfiguration={queryBuilderConfiguration}
                            onInit={onSubmit}
                            onSubmit={onSubmit}
                            className="tw-mx-auto tw-w-full"
                            ref={queryBuilderRef}
                        />
                        <div className="tw-h-full tw-overflow-auto">
                            <Tabs
                                value={selectedTab}
                                onChange={changeTab}
                                orientation="vertical"
                                pills
                                className="tw-h-full"
                                variant="scrollable"
                            >
                                {memorizedTabs}
                            </Tabs>
                        </div>
                    </div>
                )}
                <Paper
                    className="tw-h-full tw-overflow-auto tw-shadow-sm"
                    style={{ flexGrow: 1 }}
                >
                    {selectedTab === AppleHomeScreenLayoutConfiguration.schema.schemaId ? (
                        <AppleHomeScreenLayoutView
                            schema={schemaBuilderSchema}
                            propertiesRef={propertiesBuilderRef.current}
                        />
                    ) : isApplication ? (
                        <>
                            {configuration.configurationType === 'application' && (
                                <SchemaBuilder
                                    key="appleInstallApplicationCommandSchemaBuilder"
                                    properties={AppleInstallApplicationCommand.schema.properties}
                                    schema={AppleInstallApplicationCommand.schema}
                                    currentState={propertiesBuilderRef.current}
                                    readOnly
                                />
                            )}
                            {configuration.configurationType === 'webclip' && (
                                <SchemaBuilder
                                    key="appleWebClipConfigurationSchemaBuilder"
                                    properties={AppleWebClipConfiguration.schema.properties}
                                    schema={AppleWebClipConfiguration.schema}
                                    currentState={propertiesBuilderRef.current}
                                    readOnly
                                />
                            )}
                        </>
                    ) : selectedTab && schemaBuilderSchema ? (
                        <SchemaBuilder
                            key={selectedTab}
                            readOnly
                            schema={schemaBuilderSchema}
                            currentState={propertiesBuilderRef.current}
                        />
                    ) : (
                        <div className="tw-p-6 tw-font-semibold">No configuration data found</div>
                    )}
                </Paper>
            </div>
        </Page>
    );
};

export { AppleConfigurationSummary };
