import { KEY_CODE } from '@capasystems/constants';
import { isFunction, Url } from '@capasystems/utils';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MUIInput from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Ellipsis } from '../ellipsis/ellipsis';
import './input.scss';

const formControlClasses = {
    root: 'cs-input-wrapper',
    fullWidth: 'cs-input-full-width',
};
const inputLabelClasses = {
    root: 'cs-input-label',
    error: 'cs-input-label-error',
    focused: 'cs-input-label-focused',
    disabled: 'cs-input-label-disabled',
    required: 'cs-input-label-required',
};
const inputContainerClasses = {
    root: 'cs-input-container cs-input-animate',
    error: 'cs-input-error',
    focused: 'cs-input-focused',
    disabled: 'cs-input-disabled',
    input: 'cs-input',
    multiline: 'cs-input-multiline',
};
const startAdornmentClasses = {
    root: 'cs-start-adornment',
};
const endAdornmentClasses = {
    root: 'cs-end-adornment',
};

export type InputProps = {
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    inputRef?: React.Ref<HTMLInputElement>;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onSubmit?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    className?: string;
    containerClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    startAdornmentClassName?: string;
    endAdornmentClassName?: string;
    id?: string;
    value?: string | number;
    defaultValue?: string | number;
    placeholder?: string;
    name?: string;
    error?: boolean;
    label?: string | React.ReactNode;
    helperText?: string | React.ReactNode;
    startAdornment?: string | React.ReactNode;
    endAdornment?: string | React.ReactNode;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    rounded?: boolean;
    callToAction?: boolean;
    light?: boolean;
    naked?: boolean;
    transparent?: boolean;
    fullWidth?: boolean;
    multiline?: boolean;
    updateUrl?: boolean;
    readOnly?: boolean;
    rows?: number;
    rowsMax?: number;
    min?: number;
    max?: number;
    maxLength?: number;
    step?: number;
    type?: string;
    autoComplete?: string;
    style?: React.CSSProperties;
    disablePointerEvents?: boolean;
    delay?: number;
    disableSpinner?: boolean;
    disableLabelEllipsis?: boolean;
    isPassword?: boolean;
};

export const Input: React.FC<InputProps> = ({
    className = null,
    onChange = () => null,
    value = undefined, // In order to make controlled component work, ie. defaultValue
    label,
    error = false,
    name = undefined,
    defaultValue = undefined, // In order to make controlled component work, ie. defaultValue
    disabled = false,
    rounded = false,
    callToAction = false,
    light = false,
    naked = false,
    transparent = false,
    required = false,
    rowsMax = 5,
    rows = 5,
    type = 'text',
    placeholder,
    multiline = false,
    fullWidth = true,
    autoFocus = false,
    id = undefined,
    helperText = null,
    endAdornment = '',
    disablePointerEvents = false,
    startAdornment = '',
    max,
    step = 1,
    min,
    style,
    onKeyUp = () => null,
    onKeyDown = () => null,
    onSubmit,
    onClick = () => null,
    onFocus = () => null,
    onBlur = () => null,
    autoComplete,
    inputRef = () => null,
    maxLength,
    readOnly = false,
    updateUrl = false,
    delay,
    disableSpinner,
    disableLabelEllipsis,
    containerClassName,
    inputClassName,
    startAdornmentClassName,
    endAdornmentClassName,
    labelClassName,
}) => {
    /* istanbul ignore next */
    const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        if (updateUrl && name) {
            Url.set(name, event.target.value as any);
        }
        onBlur(event);
    };

    const [currentValue, setCurrentValue] = useState(value);

    const changesTimeout = useRef<NodeJS.Timeout | undefined>();

    const handleChanges: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (change) => {
        if (delay) {
            clearTimeout(changesTimeout.current);
            const newChanges = { ...change };
            changesTimeout.current = setTimeout(() => {
                onChange(newChanges);
            }, delay);
        } else {
            onChange(change);
        }
        if (typeof value === 'string') {
            setCurrentValue(change.target.value);
        }
    };

    useEffect(() => {
        if (value !== currentValue) {
            setCurrentValue(value);
        }
    }, [value]);

    return (
        <FormControl
            classes={formControlClasses}
            fullWidth={fullWidth}
            style={style}
            className={classnames(
                {
                    'cs-input-rounded': rounded,
                    'cs-input-cta': callToAction,
                    'cs-input-light': light,
                    'cs-input-naked': naked,
                    'cs-input-transparent': transparent,
                    'cs-input-no-spinner': disableSpinner,
                },
                className
            )}
        >
            <InputLabel
                classes={inputLabelClasses}
                className={labelClassName}
                error={error}
                shrink
                disabled={disabled}
            >
                {label && (!disableLabelEllipsis ? <Ellipsis>{label}</Ellipsis> : <span className="tw-whitespace-nowrap">{label}</span>)}
            </InputLabel>
            <MUIInput
                inputRef={inputRef}
                readOnly={readOnly}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                id={id}
                name={name}
                onClick={onClick}
                startAdornment={
                    startAdornment && (
                        <InputAdornment
                            position="start"
                            classes={startAdornmentClasses}
                            className={startAdornmentClassName}
                            disablePointerEvents={disablePointerEvents}
                        >
                            {startAdornment}
                        </InputAdornment>
                    )
                }
                endAdornment={
                    endAdornment && (
                        <InputAdornment
                            position="end"
                            classes={endAdornmentClasses}
                            className={endAdornmentClassName}
                            disablePointerEvents={disablePointerEvents}
                        >
                            {endAdornment}
                        </InputAdornment>
                    )
                }
                multiline={multiline}
                minRows={rows}
                maxRows={rowsMax}
                required={required}
                type={type}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={handleBlur}
                inputProps={{
                    min,
                    max,
                    maxLength,
                    step,
                    onKeyUp,
                    onKeyDown: (e) => {
                        if (e.keyCode === KEY_CODE.ENTER && isFunction(onSubmit)) {
                            e.stopPropagation();
                            e.preventDefault();
                            e.currentTarget.blur();
                            onSubmit(e);
                        }
                        onKeyDown(e);
                    },
                    className: inputClassName,
                }}
                value={currentValue}
                onChange={handleChanges}
                classes={inputContainerClasses}
                className={containerClassName}
                error={error}
                disabled={disabled}
                defaultValue={defaultValue}
                disableUnderline
            />
            {helperText && (
                /* istanbul ignore next */
                <FormHelperText
                    error={error}
                    component="div"
                    classes={{
                        root: 'cs-input-helper-text cs-input-animate',
                        error: 'cs-input-helper-text-error',
                    }}
                >
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default Input;
