import { coreMessage, defaultTheme, SCROLLED_TO_BOTTOM_THRESHOLD } from '@capasystems/constants';
import { isFunction } from '@capasystems/utils';
import Popper from '@mui/material/Popper';
import classNames from 'classnames';
import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import Ellipsis from '../ellipsis/ellipsis';
import { Highlighter } from '../highlighter/highlighter';
import { Input } from '../input/input';
import { ListItem } from '../list/list-item';
import { ListItemText } from '../list/list-item-text';
import { Paper } from '../paper/paper';

type AutoCompleteProps = {
    suggestions: {
        id: string | number;
        name: string;
        secondaryContent?: React.ReactNode | string;
    }[];
    value: string;
    searchTerm: string;
    placeholder?: string;
    label?: string;
    onSearch: (searchTerm: string, event: React.ChangeEvent<HTMLInputElement>) => void;
    onSelect: (suggestion: string, event: React.SyntheticEvent) => void;
    onScrolledToBottom?: () => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    required?: boolean;
    startAdornment?: React.ReactNode | string;
    endAdornment?: React.ReactNode | string;
    fullWidth?: boolean;
    noSearchResults?: boolean;
    autoFocus?: boolean;
    type?: string;
    helperText?: React.ReactNode | string;
    inputRef?: any;
    disablePointerEvents?: boolean;
    containerClassName?: string;
    modifiers?: Record<string, unknown>;
    inputProps?: Record<string, unknown>;
    maxPopperHeight?: number | string;
    disableTextSelectOnFocus?: boolean;
    renderSuggestions?: boolean;
    dense?: boolean;
    focusInputOnSuggestionClick?: boolean;
    classes?: {
        listItem?: string;
        listItemText?: string;
    };
    autoComplete?: string;
};

/** CapaSystems AutoComplete types. */
export const AutoComplete: React.FC<AutoCompleteProps> = ({
    classes = {},
    suggestions,
    fullWidth = true,
    disabled = false,
    value,
    disablePointerEvents = false,
    placeholder,
    label,
    startAdornment = '',
    endAdornment = '',
    helperText = null,
    required = false,
    type = 'search',
    noSearchResults = false,
    autoFocus = false,
    searchTerm,
    inputRef = () => null,
    onScrolledToBottom = () => null,
    onFocus = () => null,
    onBlur = () => null,
    onSelect,
    onSearch,
    containerClassName,
    inputProps = {},
    maxPopperHeight = 424,
    disableTextSelectOnFocus = false,
    renderSuggestions = true,
    dense = false,
    focusInputOnSuggestionClick = true,
    ...theRest
}) => {
    const [hasFocus, setHasFocus] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null);
    const [previousScrollHeight, setPreviousScrollHeight] = useState(0);
    /* istanbul ignore next */
    const onScroll: React.UIEventHandler<HTMLDivElement> | undefined = (e) => {
        if (isFunction(onScrolledToBottom)) {
            // eslint-disable-next-line max-len
            const closeToBottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop <= e.currentTarget.clientHeight + SCROLLED_TO_BOTTOM_THRESHOLD;
            if (closeToBottom && previousScrollHeight !== e.currentTarget.scrollHeight) {
                setPreviousScrollHeight(e.currentTarget.scrollHeight);
                onScrolledToBottom();
            }
        }
    };

    /* istanbul ignore next */
    const onSelectFunction = (event: any, { suggestion }: { suggestion: any }) => {
        event.preventDefault();
        setIsSearching(false);
        onSelect(suggestion, event);
    };

    /* istanbul ignore next */
    const onSearchFunction = (event: any, details: any) => {
        if (details.method === 'type') {
            /** suggestions.length might be the same so we have to reset previousScrollHeight */
            setPreviousScrollHeight(0);
            setIsSearching(true);
            // User typed in textfield. Notify.
            onSearch(details.newValue, event);
        }
    };

    /* istanbul ignore next */
    const ignore = () => null;

    /* istanbul ignore next */
    const getSuggestionValue = (suggestion: any) => suggestion;

    const shouldRenderSuggestions = () => renderSuggestions;

    /* istanbul ignore next */
    const renderSuggestion = (suggestion: any, { query, isHighlighted }: { query: any; isHighlighted: any }) => {
        const { secondaryContent, name } = suggestion;
        return (
            <ListItem
                selected={isHighlighted}
                components={{ Root: 'div' }}
                className={classNames(classes.listItem, {
                    'tw-cursor-pointer tw-px-4': true,
                    'tw-py-1.5': dense,
                })}
            >
                <ListItemText
                    className={classes.listItemText}
                    classes={{
                        primary: classNames('tw-font-medium', {
                            'tw-text-sm': !dense,
                            'tw-text-xs': dense,
                        }),
                    }}
                >
                    <Ellipsis>
                        <Highlighter
                            searchWords={[query]}
                            textToHighlight={name}
                        />
                    </Ellipsis>
                </ListItemText>
                {secondaryContent && <div className="tw-float-right tw-ml-2 tw-align-middle tw-text-sm">{secondaryContent}</div>}
            </ListItem>
        );
    };

    const renderInputComponent = (inputProps: any) => {
        const { ref, key, ...other } = inputProps;
        return (
            <Input
                {...other}
                key={key}
                inputRef={(node) => {
                    setInputElement(node);
                    ref(node);
                    inputRef(node);
                }}
            />
        );
    };

    /* istanbul ignore next */
    const onFocusFunction = (event: any) => {
        setHasFocus(true);
        setIsSearching(true);
        setPreviousScrollHeight(0);
        /** Mark all text */
        if (!disableTextSelectOnFocus) {
            event.target.select();
        }
        onFocus(event);
    };

    /* istanbul ignore next */
    const onBlurFunction = (event: any) => {
        setHasFocus(false);
        setIsSearching(false);
        onBlur(event);
    };

    return (
        <Autosuggest
            focusInputOnSuggestionClick={focusInputOnSuggestionClick}
            suggestions={suggestions}
            onSuggestionsFetchRequested={ignore}
            onSuggestionsClearRequested={ignore}
            getSuggestionValue={getSuggestionValue}
            shouldRenderSuggestions={shouldRenderSuggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={onSelectFunction}
            inputProps={{
                //@ts-ignore: We are using the same props as the Input component
                label,
                placeholder,
                value: isSearching ? /* istanbul ignore next */ searchTerm : value,
                onChange: onSearchFunction,
                onBlur: onBlurFunction,
                onFocus: onFocusFunction,
                inputRef,
                startAdornment,
                endAdornment,
                fullWidth,
                disabled,
                helperText,
                required,
                type,
                autoFocus,
                disablePointerEvents,
                ...inputProps,
                ...theRest,
            }}
            renderInputComponent={renderInputComponent}
            theme={{
                suggestionsList: 'tw-m-0 tw-p-0',
                container: containerClassName,
            }}
            renderSuggestionsContainer={(options) => {
                const { containerProps } = options;
                return (
                    <React.Fragment>
                        <Popper
                            onScroll={onScroll}
                            anchorEl={inputElement ? inputElement.parentElement : inputElement}
                            open={hasFocus}
                            ref={containerProps.ref}
                            style={{ zIndex: defaultTheme.zIndex.modal }}
                        >
                            <Paper
                                className="tw-overflow-auto tw-rounded tw-shadow"
                                style={{
                                    maxHeight: maxPopperHeight,
                                    width: inputElement ? inputElement.parentElement?.clientWidth : undefined,
                                }}
                            >
                                {options.children}
                                {noSearchResults && (
                                    <ListItem components={{ Root: 'div' }}>
                                        <ListItemText>
                                            <span className="text-warn text-small">{coreMessage.noSearchResults}</span>
                                        </ListItemText>
                                    </ListItem>
                                )}
                            </Paper>
                        </Popper>
                    </React.Fragment>
                );
            }}
        />
    );
};

export default AutoComplete;
