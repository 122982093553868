import { BUTTON, TOOLTIP } from '@capasystems/constants';
import { Avatar, Button, Icon, Input, LayoutCentered, LayoutRow, Loading, Page, Paper, SavingChanges, Tooltip, UploadButton, useParams } from '@capasystems/ui';
import { Url, isDefined, useWindowSize } from '@capasystems/utils';
import { AppleInstallApplicationCommand, AppleWebClipConfiguration, coreAppleManagement } from '@thirdparty/constants';
import { default as classNames } from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
    JsonViewer,
    SchemaBuilder,
    SchemaBuilderDescription,
    SchemaBuilderTitle,
    WithExperimentalFeatures,
    useAppleApi,
    useAuthContext,
    useForceRefresh,
    useNavigate,
} from '../../../index';

const WIDTH_THRESHOLD = 1440;

const AppleApplicationCreateAndEdit = () => {
    // Used to get information about the application from the app store
    const { applicationId } = useParams();
    const propertiesBuilderRef = useRef({
        /* Identifier: null */
    });
    const appleApi = useAppleApi();
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const { bundleId, trackId } = useParams();
    const [loading, setLoading] = useState(true);
    const [savingChangesState, setSavingChangesState] = useState({
        loading: false,
    });
    const forceRefresh = useForceRefresh();
    const { permissions } = useAuthContext();

    const navigateToApplicationList = () => {
        navigate.to('apple/application/list');
    };

    const navigateToApplicationMembership = (response) => {
        navigate.to(`apple/application/${applicationId || response.id}/membership`);
    };

    const onIconChange = (fileDetails) => {
        if (!propertiesBuilderRef.current['com.apple.webClip.managed']) {
            propertiesBuilderRef.current['com.apple.webClip.managed'] = {};
        }
        if (fileDetails) {
            const [file] = fileDetails.files;
            if (file) {
                setSavingChangesState({
                    loading: true,
                    loadingMessage: 'Processing image',
                    successMessage: 'Validated',
                });
                setLoading(true);
                if (file.size > 1024 * 1024) {
                    setTimeout(() => {
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            errorTitle: 'File is too big',
                            errorMessage: 'The maximum allowed file size is 1 MB',
                            loading: false,
                        }));
                    });
                } else {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = function () {
                        application.current.iconUrl = fileReader.result;
                        const imageData = fileReader.result.split('base64,')[1];
                        propertiesBuilderRef.current['com.apple.webClip.managed'].Icon = imageData;
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            loading: false,
                        }));
                        setLoading(false);
                        forceRefresh();
                    };
                    fileReader.onerror = function (error) {
                        setSavingChangesState({
                            errorMessage: 'Could not read image',
                        });
                        setLoading(false);
                        console.log('Error: ', error);
                    };
                }
            } else {
                propertiesBuilderRef.current.Icon = [];
            }
        }
    };

    const application = useRef({
        name: '',
        iconUrl: '',
        supportedPlatforms: [],
        configurationType: null,
    });

    const onSubmit = () => {
        setErrorMessage(null);
        if (application.current.name.trim() === '') {
            setErrorMessage('You must provide an application name');
        } else {
            if (isDefined(trackId)) {
                appleApi
                    .createAppleApplication(
                        application.current.name,
                        application.current.iconUrl,
                        application.current.supportedPlatforms,
                        coreAppleManagement.configurationType.application,
                        {
                            ...propertiesBuilderRef.current,
                        }
                    )
                    .then(navigateToApplicationMembership)
                    .catch((response) => {
                        setErrorMessage(response.data?.message || 'Failed to create apple application');
                    });
            } else {
                setSavingChangesState({ loading: true });
                const requestData = {
                    ...propertiesBuilderRef.current,
                };

                if (isDefined(applicationId)) {
                    requestData.name = application.current.name;
                    requestData.data = { ...propertiesBuilderRef.current };

                    appleApi
                        .updateAppleApplication(applicationId, requestData)
                        .then(navigateToApplicationMembership)
                        .catch((response) => {
                            setSavingChangesState({
                                errorMessage: response.data?.message || 'Failed to update apple application',
                            });
                        });
                } else {
                    appleApi
                        .createAppleApplication(
                            application.current.name,
                            application.current.iconUrl,
                            application.current.supportedPlatforms,
                            coreAppleManagement.configurationType.webclip,
                            requestData
                        )
                        .then(navigateToApplicationMembership)
                        .catch((response) => {
                            setSavingChangesState({
                                errorMessage: response.data?.message || 'Failed to create apple web clip',
                            });
                        });
                }
            }
        }
    };

    useEffect(() => {
        if (isDefined(trackId)) {
            const country = Url.getString('country');
            appleApi
                .getItunesApplicationLookup(trackId, { country })
                .then((foundApplication) => {
                    propertiesBuilderRef.current.Identifier = foundApplication.bundleId;
                    propertiesBuilderRef.current.Vendor = foundApplication.sellerName;
                    //propertiesBuilderRef.current.data = foundApplication; //Api data
                    application.current.name = foundApplication.trackName;
                    application.current.iconUrl = foundApplication.artworkUrl60;
                    application.current.configurationType = coreAppleManagement.configurationType.application;

                    if (foundApplication.supportedDevices.find((deviceType) => deviceType.includes('iPhone'))) {
                        application.current.supportedPlatforms.push('iOS');
                    }
                    if (foundApplication.supportedDevices.find((deviceType) => deviceType.includes('iPad'))) {
                        application.current.supportedPlatforms.push('iPadOS');
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setErrorMessage('The supplied application is unknown');
                });
        } else if (isDefined(applicationId)) {
            appleApi
                .getSingleAppleApplication(applicationId)
                .then((foundApplication) => {
                    application.current.name = foundApplication.name;
                    application.current.iconUrl = foundApplication.iconUrl;
                    application.current.configurationType = foundApplication.configurationType;
                    propertiesBuilderRef.current = foundApplication.data || {};
                    setLoading(false);
                })
                .catch(() => {
                    setErrorMessage('The supplied application is unknown');
                });
        } else {
            application.current.configurationType = coreAppleManagement.configurationType.webclip;
            setLoading(false);
        }
    }, [appleApi, bundleId, trackId, applicationId]);

    if (loading) {
        if (errorMessage) {
            return (
                <LayoutCentered direction="column">
                    <div>
                        <h2 className="tw-mb-4">{errorMessage}</h2>
                        <Button
                            noMargin
                            onClick={navigateToApplicationList}
                            variant={BUTTON.RAISED}
                            color={BUTTON.PRIMARY}
                        >
                            Back
                        </Button>
                    </div>
                </LayoutCentered>
            );
        }
        return (
            <LayoutCentered>
                <Loading thickness={2} />
            </LayoutCentered>
        );
    }

    const showJsonMiniMap = width >= WIDTH_THRESHOLD && permissions.canViewExperimentalFeatures;

    return (
        <Page title={isDefined(applicationId) ? 'Application · Edit' : 'Application · Create'}>
            <div
                className={classNames({
                    'tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-cols-1fr-auto tw-gap-4 tw-p-4': true,
                    'tw-grid-rows-auto-auto-1fr': errorMessage !== null,
                    'tw-grid-rows-auto-1fr': errorMessage === null,
                })}
            >
                <div className="tw-col-span-2">
                    <LayoutRow
                        align="space-between"
                        verticalAlign="center"
                    >
                        <div>
                            <LayoutRow verticalAlign="center">
                                {application.current.iconUrl ? (
                                    <Avatar
                                        src={application.current.iconUrl}
                                        alt={application.current.name}
                                        variant="rounded"
                                        className="tw-h-16 tw-w-16"
                                    />
                                ) : (
                                    <Tooltip
                                        content={'The icon should be no larger than 400 x 400 pixels, png or jpeg.\n\nThe maximum allowed file size is 1 MB.'}
                                        interactive
                                        position={TOOLTIP.POSITION.BOTTOM_START}
                                    >
                                        <div>
                                            <UploadButton
                                                variant={BUTTON.OUTLINED}
                                                color={BUTTON.PRIMARY}
                                                htmlId="upload-icon-button"
                                                accept=".jpeg,.png"
                                                onChange={onIconChange}
                                                noMargin
                                                hideSelectedFiles
                                                className="tw-h-16 tw-w-16 tw-rounded-xl tw-border-2 tw-border-dashed"
                                            >
                                                <Icon
                                                    type="add"
                                                    className="tw-h-8 tw-w-8"
                                                />
                                            </UploadButton>
                                        </div>
                                    </Tooltip>
                                )}
                                <Input
                                    value={application.current.name}
                                    placeholder="Application name"
                                    fullWidth
                                    callToAction
                                    light
                                    className="tw-ml-4 tw-w-88 lg:tw-w-144"
                                    onChange={(e) => {
                                        setErrorMessage(null);
                                        application.current.name = e.target.value;
                                    }}
                                    containerClassName="tw-py-1 tw-text-sm"
                                    autoFocus
                                />
                            </LayoutRow>
                        </div>

                        <LayoutRow
                            verticalAlign="center"
                            align="space-between"
                            className={classNames({
                                'tw-mb-2 tw-py-2 ': true,
                            })}
                        ></LayoutRow>

                        <div className="full tw-ml-16 tw-flex tw-justify-end">
                            <Button
                                color={BUTTON.PRIMARY}
                                variant={BUTTON.RAISED}
                                onClick={onSubmit}
                            >
                                {isDefined(applicationId) ? 'Save' : 'Create'}
                            </Button>
                            <Button
                                noMargin
                                onClick={isDefined(applicationId) ? navigateToApplicationMembership : navigateToApplicationList}
                            >
                                Cancel
                            </Button>
                        </div>
                    </LayoutRow>
                </div>
                {errorMessage !== null && (
                    <div className="tw-col-span-3 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700">{errorMessage}</div>
                )}
                <Paper
                    className={classNames({
                        'max-w-screen-2xl tw-h-full tw-overflow-auto tw-shadow-sm': true,
                        'tw-col-span-2': !showJsonMiniMap,
                    })}
                >
                    {application.current.configurationType === coreAppleManagement.configurationType.application && (
                        <SchemaBuilder
                            key="appleInstallApplicationCommandSchemaBuilder"
                            properties={AppleInstallApplicationCommand.schema.properties}
                            schema={AppleInstallApplicationCommand.schema}
                            currentState={propertiesBuilderRef.current}
                        />
                    )}

                    {application.current.configurationType === coreAppleManagement.configurationType.webclip && (
                        <>
                            <SchemaBuilder
                                key="appleWebClipConfigurationSchemaBuilder"
                                properties={AppleWebClipConfiguration.schema}
                                schema={AppleWebClipConfiguration.schema}
                                currentState={propertiesBuilderRef.current}
                            />
                            <div className="tw-px-6 tw-pt-4">
                                <LayoutRow
                                    verticalAlign="center"
                                    align="space-between"
                                >
                                    <div>
                                        <SchemaBuilderTitle required={application.current.iconUrl ? false : true}>
                                            {AppleWebClipConfiguration.schema.properties['com.apple.webClip.managed'].properties.Icon.title}
                                        </SchemaBuilderTitle>
                                        <SchemaBuilderDescription>
                                            {AppleWebClipConfiguration.schema.properties['com.apple.webClip.managed'].properties.Icon.description}
                                        </SchemaBuilderDescription>
                                    </div>
                                    <LayoutRow verticalAlign="center">
                                        <UploadButton
                                            variant={application.current.iconUrl ? BUTTON.OUTLINED : BUTTON.RAISED}
                                            color={BUTTON.PRIMARY}
                                            htmlId="upload-icon-button"
                                            accept=".jpeg,.png"
                                            onChange={onIconChange}
                                            noMargin
                                            hideSelectedFiles
                                            size="large"
                                        >
                                            {application.current.iconUrl ? 'Change icon' : 'Select icon'}
                                        </UploadButton>
                                    </LayoutRow>
                                </LayoutRow>
                            </div>
                        </>
                    )}

                    <SavingChanges
                        minLoadingTime={1000}
                        {...savingChangesState}
                    />
                </Paper>

                {showJsonMiniMap && (
                    <WithExperimentalFeatures>
                        <JsonMiniMap
                            json={propertiesBuilderRef.current}
                            className="tw-w-80 tw-text-tiny"
                        />
                    </WithExperimentalFeatures>
                )}
            </div>
        </Page>
    );
};

const JsonMiniMap = React.forwardRef((props, ref) => {
    const forceRefresh = useForceRefresh();

    useEffect(() => {
        const interval = setInterval(forceRefresh, 1000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <JsonViewer
            {...props}
            ref={ref}
        />
    );
});
export { AppleApplicationCreateAndEdit };
