import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    Input,
    SavingChanges,
    Select,
    Tooltip,
    UploadButton,
} from '@capasystems/ui';
import { Url } from '@capasystems/utils';
import { windowsApplication } from '@thirdparty/constants';
import {
    PageTitle,
    useApi,
    useForceRefresh,
    useLocalStorageState,
    useNavigate,
    WidgetPaper,
    WindowsApplicationFormProgressContainer,
    WindowsApplicationThumbnail,
    WindowsCapaPacksPicker,
} from '@thirdparty/ui';
import { formatBytes } from '@thirdparty/utils';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import 'react-resizable/css/styles.css';

const maxContentSize = 1024 * 1024 * 1024 * 5; // 5 GB

const maxContentSizeAsText = formatBytes({
    value: maxContentSize,
    decimalPoints: 1,
    asText: true,
});

const architectureOptions = Object.values(windowsApplication.architecture);

const releaseStatusOptions = Object.values(windowsApplication.releaseStatus)

const languageOptions = Object.values(windowsApplication.language);

const WindowsNewRepositoryApp = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [architecture, setArchitecture] = useState(windowsApplication.architecture['64-bit'].id);
    const [releaseStatus, setReleaseStatus] = useState(windowsApplication.releaseStatus.testing.id);
    const [language, setLanguage] = useState<string>(languageOptions[2].id);
    const [name, setName] = useState('');
    const [icon, setIcon] = useState('');
    const [version, setVersion] = useState('');
    const [application, setApplication] = useState<any>();
    const [isRepositoryAppSelected, setIsRepositoryAppSelected] = useState(true);
    const [description, setDescription] = useState('');
    const [vendor, setVendor] = useState('');
    const [recentlyUsedIcons, setRecentlyUsedIcons] = useLocalStorageState('windows.applications.customapps.recentlyUsedIcons.v1', []);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showPrevDialog, setShowPrevDialog] = useState(false);
    const [showApplicationList, setShowApplicationList] = useState(false);
    const propertiesBuilderRef = useRef<{
        packageName?: string;
    }>({});
    const forceRefresh = useForceRefresh();

    const [savingChangesState, setSavingChangesState] = useState<{
        loading: boolean;
        loadingMessage?: string;
        successMessage?: string;
        errorTitle?: string;
        errorMessage?: string;
    }>({
        loading: false,
    });

    const navigateToApplicationMembership = (response) => {
        navigate.to(`windows/application/${response.id}/membership`);
    };

    const getLatestVersion = (versions) => {
        if (versions && versions.length > 0) {
            const sortedVersions = versions.sort((a, b) => {
                return new Date(b.releaseDate).getMilliseconds() - new Date(a.releaseDate).getMilliseconds();
            });
            return sortedVersions[sortedVersions.length - 1]?.version;
        }
        return '';
    };

    const handleApplicationSelect = (application) => {
        setApplication(application);
        setName(application.application);
        setIcon(application.thumbnail);
        setDescription(application.description);
        setVendor(application.vendor);
        setVersion(application.versions[0]?.version || '');
        Url.set('packageId', application.applicationId);
        if (application.versions[0]?.language === 'Neutral') {
            applicationRef.current.language = windowsApplication.language.system.id;
            setLanguage(windowsApplication.language.system.id);
        } else {
            applicationRef.current.language = application.versions[0].language;
            setLanguage(application.versions[0].language);
        }
        setArchitecture(application.versions[0].platform);
        setVersion(getLatestVersion(application.versions));
        applicationRef.current.platform = application.versions[0].platform;
        applicationRef.current.thumbnail = application.thumbnail;
        setShowApplicationList(false);
        forceRefresh();
    };

    useEffect(() => {
        api.getApplication(Url.getString('packageId')).then((app) => {
            setApplication(app);
            propertiesBuilderRef.current.packageName = app.application;
            setName(app.application);
            setIcon(app.thumbnail);
            setDescription(app.description);
            setVersion(getLatestVersion(app.versions));
            applicationRef.current.thumbnail = app.thumbnail;
            setVendor(app.vendor);
            setLanguage(app.versions[0]?.language);
            setArchitecture(app.versions[0]?.platform);
        });
    }, []);

    const applicationRef = useRef<{
        name?: string;
        thumbnail?: string | ArrayBuffer;
        description?: string;
        language?: string;
        platform?: string;
    }>({
        thumbnail: '',
    });

    const onIconChange = (fileDetails) => {
        if (fileDetails) {
            const [icon] = fileDetails.files;
            if (icon) {
                setSavingChangesState({
                    loading: true,
                    loadingMessage: 'Processing image',
                    successMessage: 'Validated',
                });
                if (icon.size > 1024 * 1024) {
                    setTimeout(() => {
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            errorTitle: 'File is too big',
                            errorMessage: 'The maximum allowed file size is 1 MB',
                            loading: false,
                        }));
                    });
                } else {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(icon);
                    fileReader.onload = function () {
                        applicationRef.current.thumbnail = fileReader.result;
                        setRecentlyUsedIcons((currentIcons) => {
                            function onlyUnique(value, index) {
                                setShowPrevDialog(false);
                                return currentIcons.indexOf(value) === index;
                            }
                            currentIcons.unshift(fileReader.result);
                            setShowPrevDialog(false);
                            return currentIcons.filter(onlyUnique).slice(0, 12);
                        });
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            loading: false,
                        }));
                    };
                    fileReader.onerror = function (error) {
                        setSavingChangesState({
                            loading: false,
                            errorMessage: 'Could not read image',
                        });
                        console.log('Error: ', error);
                    };
                }
            }
        }
    };

    const InfoHelpIcon = ({ help }) => {
        if (help) {
            return (
                <Tooltip
                    extraPadding
                    dark
                    content={<div>{help}</div>}
                >
                    <Icon
                        type="infoOutlined"
                        className="tw-text-xl tw-text-gray-400"
                        style={{ marginLeft: '4px' }}
                    />
                </Tooltip>
            );
        }
        return null;
    };

    const handleApplicationClick = () => {
        createCapaPackApplication();
    };

    const createCapaPackApplication = () => {
        if (name.trim() === '') {
            setErrorMessage('You must provide an application name');
        } else {
            api.createCapaPacksApplicationConfiguration(
                name.trim(),
                description,
                application?.applicationId,
                application?.application,
                {
                    packageName: application?.application,
                    language,
                    platform: architecture,
                    version,
                },
                application?.thumbnail,
                vendor,
                releaseStatus
            )
                .then(navigateToApplicationMembership)

                .catch((response) => {
                    setErrorMessage(response.data || 'Failed to create application');
                });
        }
    };

    const requiredFields = () => {
        const missingFields = [];

        if (name === '') {
            missingFields.push('Name');
        }

        if (missingFields.length === 0) {
            return null;
        }

        const tooltipMsg = 'You are required to fill out:';
        const missingFieldsElements = missingFields.map((field) => <span>{field}</span>);

        return (
            <div>
                <span>{tooltipMsg}</span>
                <br />
                {missingFieldsElements.reduce((prev, curr) => (
                    <React.Fragment>
                        {prev}
                        <br />
                        {curr}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    return (
        <div>
            <div className="tw-mx-auto tw-max-w-screen-2xl tw-overflow-auto tw-p-4">
                <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto-auto tw-items-center tw-gap-4">
                    <PageTitle category="New">Repository Application</PageTitle>
                    <Tooltip content={requiredFields()}>
                        <div>
                            <Button
                                noMargin
                                color="primary"
                                variant="contained"
                                disabled={name === ''}
                                onClick={handleApplicationClick}
                            >
                                Create
                            </Button>
                        </div>
                    </Tooltip>
                    <Button
                        noMargin
                        onClick={() => navigate.to('windows/application/list')}
                    >
                        Cancel
                    </Button>
                </div>
                {errorMessage !== null && (
                    <div className="tw-col-span-2 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700">{errorMessage}</div>
                )}
                <div className="tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-4">
                    <SavingChanges {...savingChangesState} />
                    <WidgetPaper
                        title="Application content"
                        headerClassName="tw-px-6"
                        headerless
                        className="tw-mb-4"
                    >
                        <div className="tw-grid tw-gap-y-6 tw-px-6 tw-py-4">
                            {/* @ts-ignore - this is not typed yet */}
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Source Files</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>These are the files that the application should contain.</p>
                                                    <p>The files are uploaded when you create the application.</p>
                                                    <p>The maximum total file size is {maxContentSizeAsText}.</p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid={true}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>These are the files that the application should contain.</p>
                                    </div>
                                }
                                isSourceFiles
                            >
                                <div
                                    className={classNames(
                                        'tw-ml-auto tw-w-60 tw-rounded-lg tw-bg-sky-50/50 tw-px-4 tw-py-3 tw-text-sky-900 tw-ring-1 tw-ring-sky-100 hover:tw-bg-sky-50',
                                        {}
                                    )}
                                    // onClick={(e) => {
                                    //     if (!formState.isFile && !formState.isDirectory) {
                                    //         setShowApplicationList(!showApplicationList);
                                    //     }
                                    // }}
                                >
                                    <div className="tw-flex tw-justify-between">
                                        <PageTitle
                                            subtitle
                                            category="Select"
                                            color="inherit"
                                        >
                                            Repository Application
                                        </PageTitle>
                                        <Icon
                                            type={'badgeCheckmark'}
                                            className="tw-h-10 tw-w-10 tw-text-emerald-400"
                                            // style={{
                                            //     cursor: formState.isFile || formState.isDirectory ? 'not-allowed' : 'pointer',
                                            // }}
                                        />
                                    </div>

                                    {showApplicationList && (
                                        <WindowsCapaPacksPicker
                                            type={windowsApplication.type.capaPacks.id}
                                            // @ts-ignore - this is not typed yet
                                            showApplicationList={setShowApplicationList}
                                            onApplicationSelect={handleApplicationSelect}
                                        />
                                    )}
                                </div>
                            </WindowsApplicationFormProgressContainer>
                        </div>
                        <div className="tw-grid tw-gap-y-6 tw-px-6 tw-py-4">
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Name</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>This is the name of the application.</p>
                                                    <p>It's recommended to use the same name that's displayed in add/remove programs.</p>
                                                    <p>Example: "Adobe Acrobat Reader" </p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid={name !== ''}
                                TooltipInfo={!(name !== '') ? 'Required' : null}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the name of the application.</p>
                                    </div>
                                }
                            >
                                <Input
                                    light
                                    callToAction
                                    inputClassName="tw-font-semibold tw-h-12"
                                    fullWidth
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => {
                                        setErrorMessage(null);
                                        setName(e.target.value.trim());
                                    }}
                                />
                            </WindowsApplicationFormProgressContainer>
                        </div>
                        <div className="tw-grid tw-gap-y-6 tw-px-6 tw-py-4">
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Vendor</span>
                                    </div>
                                }
                                valid={vendor !== ''}
                                TooltipInfo={!(vendor !== '') ? 'Required' : null}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the name of the vendor for the application.</p>
                                    </div>
                                }
                            >
                                <Input
                                    light
                                    callToAction
                                    inputClassName="tw-font-semibold tw-h-12"
                                    fullWidth
                                    placeholder="Vendor"
                                    value={vendor}
                                    onChange={(e) => {
                                        setErrorMessage(null);
                                        setVendor(e.target.value.trim());
                                    }}
                                />
                            </WindowsApplicationFormProgressContainer>
                        </div>
                        <div className="tw-grid tw-gap-y-6 tw-px-6 tw-py-4">
                            <WindowsApplicationFormProgressContainer
                                label="Description"
                                valid={description !== ''}
                                required={false}
                                TooltipInfo={!(description !== '') ? 'Optional' : null}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the description of the application.</p>
                                    </div>
                                }
                            >
                                <Input
                                    multiline
                                    rows={2}
                                    fullWidth
                                    rowsMax={5}
                                    callToAction
                                    light
                                    inputClassName="tw-px-4 tw-py-2 tw-font-medium"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </WindowsApplicationFormProgressContainer>
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Icon</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>The application icon is used in CapaOne to easily identify applications.</p>
                                                    <p>It won't have any effect on installed applications on endpoints.</p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid={applicationRef.current.thumbnail}
                                required={false}
                                TooltipInfo={!applicationRef.current.thumbnail ? 'Optional' : null}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the application icon that will be displayed in CapaOne.</p>
                                    </div>
                                }
                            >
                                <div className="tw-grid tw-grid-cols-1fr-auto-auto tw-items-center tw-justify-items-end tw-gap-2">
                                    <div>
                                        {recentlyUsedIcons.length > 0 && (
                                            <Dialog
                                                open={showPrevDialog}
                                                onClose={() => setShowPrevDialog(false)}
                                            >
                                                <DialogTitle>Recently used icons</DialogTitle>
                                                <DialogContent className="tw-min-h-48">
                                                    <div className="tw-grid tw-grid-cols-4 tw-gap-4 tw-gap-y-5 tw-pt-3">
                                                        {recentlyUsedIcons.map((src, index) => (
                                                            <div
                                                                className="tw-relative tw-h-11 tw-w-11 tw-justify-self-center"
                                                                key={index}
                                                            >
                                                                <IconButton
                                                                    className="tw-absolute tw-bottom-8 tw-left-8 tw-z-10"
                                                                    variant="contained"
                                                                    color="danger"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setRecentlyUsedIcons((c) => {
                                                                            return c.filter((src, i) => i !== index);
                                                                        });
                                                                        forceRefresh();
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        type="remove"
                                                                        size="small"
                                                                        className="tw-h-3 tw-w-3"
                                                                    />
                                                                </IconButton>
                                                                <Avatar
                                                                    src={src}
                                                                    alt={applicationRef.current.name}
                                                                    variant="square"
                                                                    className="tw-h-10 tw-w-10 tw-cursor-pointer"
                                                                    onClick={() => {
                                                                        applicationRef.current.thumbnail = src;
                                                                        forceRefresh();
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </DialogContent>
                                                <DialogActions>
                                                    <UploadButton
                                                        color="primary"
                                                        htmlId="upload-icon-button"
                                                        accept="image/*"
                                                        onChange={onIconChange}
                                                        noMargin
                                                        hideSelectedFiles
                                                        multiple={false}
                                                    >
                                                        Upload new
                                                    </UploadButton>
                                                    <Button
                                                        onClick={() => setShowPrevDialog(false)}
                                                        color="primary"
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        )}
                                    </div>

                                    {applicationRef.current.thumbnail ? (
                                        <div className="tw-group tw-relative">
                                            {!isRepositoryAppSelected && (
                                                <IconButton
                                                    className="tw-absolute tw-bottom-8 tw-left-8 tw-z-10 tw-opacity-0 tw-transition-opacity tw-duration-500 tw-ease-in-out hover:tw-scale-110 group-hover:tw-opacity-100"
                                                    variant="contained"
                                                    color="danger"
                                                    size="small"
                                                    onClick={() => {
                                                        applicationRef.current.thumbnail = null;
                                                        forceRefresh();
                                                    }}
                                                >
                                                    <Icon
                                                        type="remove"
                                                        size="small"
                                                    />
                                                </IconButton>
                                            )}
                                            <WindowsApplicationThumbnail
                                                application={applicationRef.current}
                                                size="xl"
                                                onClick={() => {
                                                    if (!isRepositoryAppSelected) {
                                                        setShowPrevDialog(true);
                                                    }
                                                }}
                                                className={`tw-cursor-pointer ${setIsRepositoryAppSelected ? 'tw-cursor-default' : ''}`}
                                            />
                                        </div>
                                    ) : recentlyUsedIcons.length > 0 ? (
                                        <Button>
                                            {/* @ts-ignore - this is not typed */}
                                            <WindowsApplicationThumbnail
                                                application={{
                                                    thumbnail: applicationRef.current.thumbnail,
                                                    name,
                                                }}
                                                size="xl"
                                                onClick={() => {
                                                    if (!isRepositoryAppSelected) {
                                                        setShowPrevDialog(true);
                                                    }
                                                }}
                                            />
                                        </Button>
                                    ) : (
                                        <UploadButton
                                            className="tw-h-12 tw-w-12"
                                            color="primary"
                                            htmlId="upload-icon-button"
                                            accept="image/*"
                                            onChange={onIconChange}
                                            noMargin
                                            hideSelectedFiles
                                            multiple={false}
                                        >
                                            {/* @ts-ignore - this is not typed */}
                                            <WindowsApplicationThumbnail
                                                application={{ thumbnail: applicationRef.current.thumbnail, name }}
                                                size="xl"
                                                onClick={() => {
                                                    if (!isRepositoryAppSelected) {
                                                        setShowPrevDialog(true);
                                                    }
                                                }}
                                            />
                                        </UploadButton>
                                    )}
                                </div>
                            </WindowsApplicationFormProgressContainer>
                            {/* @ts-ignore - this is not typed */}
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Architecture</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>This is the architecture of the application.</p>
                                                    <p>It's used to clearly indicate if an application is 32-bit or 64-bit.</p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the architecture of the application.</p>
                                    </div>
                                }
                            >
                                <Select
                                    options={architectureOptions.filter((o) => application?.versions.some((v) => v.platform === o.id))}
                                    selectedOptions={architectureOptions.filter((o) => o.id === architecture)}
                                    callToAction
                                    onChange={([{ id }]) => setArchitecture(id as string)}
                                    required
                                    inputClassName="tw-font-semibold tw-h-12 tw-cursor-pointer"
                                    light
                                />
                            </WindowsApplicationFormProgressContainer>
                            {/* @ts-ignore - this is not typed */}
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Language</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>This is the language of the application. </p>
                                                    <p>
                                                        Select "{windowsApplication.language.system.name}" if the application language automatically adjusts to
                                                        the operating system language.{' '}
                                                    </p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid
                                hideConnector={isRepositoryAppSelected}
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the language of the application. </p>
                                    </div>
                                }
                            >
                                <Select
                                    options={languageOptions.filter((o) =>
                                        application?.versions.some((v) => {
                                            let currentLanguageId = v.language;
                                            if (currentLanguageId === 'Neutral') {
                                                currentLanguageId = windowsApplication.language.system.id;
                                            }
                                            return o.id === currentLanguageId;
                                        })
                                    )}
                                    selectedOptions={languageOptions.filter((o) => o.id === language)}
                                    callToAction
                                    onChange={([{ id }]) => setLanguage(id as string)}
                                    required
                                    className=""
                                    inputClassName=" tw-font-semibold tw-h-12 tw-cursor-pointer"
                                    light
                                />
                            </WindowsApplicationFormProgressContainer>
                            {/* @ts-ignore - this is not typed  */}
                            <WindowsApplicationFormProgressContainer
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Release status</span>
                                        <InfoHelpIcon
                                            help={
                                                <div>
                                                    <p>This is the release status of the application.</p>
                                                    <p>It's used to clearly indicate if an application has been thoroughly tested and released.</p>
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                valid
                                hideConnector
                                description={
                                    <div
                                        style={{ fontSize: '12px' }}
                                        className="tw-text-gray-500"
                                    >
                                        <p>This is the release status of the application.</p>
                                    </div>
                                }
                            >
                                <Select
                                    options={releaseStatusOptions}
                                    selectedOptions={releaseStatusOptions.filter((o) => o.id === releaseStatus)}
                                    required
                                    callToAction
                                    onChange={([{ id }]) => setReleaseStatus(id as number)}
                                    inputClassName="tw-font-semibold tw-h-12"
                                    light
                                />
                            </WindowsApplicationFormProgressContainer>
                        </div>
                    </WidgetPaper>
                </div>
            </div>
        </div>
    );
};

export default WindowsNewRepositoryApp;
