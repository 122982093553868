import { isBeta, isLocalHost } from '@capasystems/constants';
import { api as baseApi, noop } from '@capasystems/utils';
import { windowsApplication } from '@thirdparty/constants';
import { useApi, useApplicationSocket } from '@thirdparty/ui';
import { windowsApplicationUtils } from '@thirdparty/utils';
import { createContext, useCallback, useEffect, useState } from 'react';

export const UploadContext = createContext({
    createCustomApplication: noop,
    windowsApplications: [],
    subscribeToUpdates: noop,
    unsubScribeToUpdates: noop,
    storageUsed: 0,
});

let subcriptions = [];

const CAPI_ROOT = isLocalHost ? '' : isBeta ? 'https://capibeta.capaone.com' : 'https://capi.capaone.com';

export const UploadProvider = ({ children }) => {
    const api = useApi();

    const [windowsApplications, setUploadWindowsApplications] = useState([]);
    const [storageUsed, setStorageUsed] = useState(0);

    const fetchData = () => {
        api.getApplicationConfigurations()
            .then((applicationResponse) => {
                const applications = applicationResponse.filter(windowsApplicationUtils.fileUploadInprogressOrFailed).map((application) => {
                    return {
                        ...application,
                        endpointMemberCount: application.endpointRefIds.length,
                        releaseStatus: application.type === windowsApplication.type.capaPacks.id ? 200 : application.releaseStatus,
                    };
                });

                const tmpStorageUsed = applicationResponse?.reduce((acc, application) => {
                    if (application.fileInfo?.fileUploaded) {
                        acc += application.fileInfo.fileSizeBytes;
                    }
                    return acc;
                }, 0);
                setUploadWindowsApplications(applications);
                setStorageUsed(tmpStorageUsed);
            })
            .catch(noop);
    };

    const onSocketNotification = useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
        let update = false;

        if (insertOperation) {
            fetchData();
            update = true;
        } else if (deleteOperation) {
            setUploadWindowsApplications((currentList) => {
                return [...currentList.filter((application) => application.id !== documentId)];
            });
            if (fullDocument?.fileInfo?.fileUploaded) {
                setStorageUsed((currentStorage) => {
                    return currentStorage - fullDocument.fileInfo.fileSizeBytes;
                });
            }
            update = true;
        } else {
            const { _id: id, ...changes } = fullDocument;
            setUploadWindowsApplications((currentApplications) =>
                currentApplications.map((application) => {
                    if (application.id === id) {
                        return {
                            ...changes,
                            id,
                        };
                    }
                    return application;
                })
            );

            // if upload is completed, notify subscribers
            if (changes?.fileInfo?.fileUploaded && changes?.fileInfo?.fileSizeBytes) {
                setStorageUsed((currentStorage) => {
                    return currentStorage + changes.fileInfo.fileSizeBytes;
                });
                update = true;
            }
        }
        // Notify subscribers
        if (update) {
            subcriptions.forEach((sub) => {
                if (sub.callback) {
                    sub.callback();
                }
            });
        }
    }, []);

    useApplicationSocket(onSocketNotification);

    const createCustomApplication = (data, formData) => api.createCustomApplication(data, formData);

    const subscribeToUpdates = (id, callback) => subcriptions.push({ id, callback });

    const unsubScribeToUpdates = (id) => (subcriptions = subcriptions.filter((sub) => sub.id !== id));

    const removeCustomApplication = (id) =>
        setUploadWindowsApplications((currentApplications) => currentApplications.filter((application) => application.id !== id));

    const createAndUploadWindowsApplication = (data, formData = new FormData()) =>
        api.createCustomApplication(data, formData).then((app) => {
            setUploadWindowsApplications((currentApplications) => {
                return [
                    ...currentApplications,
                    {
                        ...app,
                        endpointMemberCount: 0,
                        releaseStatus: app.releaseStatus,
                    },
                ];
            });

            fetch(`${CAPI_ROOT}/${baseApi.defaults.baseURL}/file/upload/customapp/${app.id}`, {
                body: formData,
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
            }).then(async (response) => {
                if (response.ok) {
                    return response;
                } else {
                    throw response;
                }
            });

            return app;
        });

    const updateAndUploadWindowsApplication = (id, data, formData = new FormData()) =>
        api.updateCustomApplication(id, data).then((app) => {
            setUploadWindowsApplications((currentApplications) => {
                return [
                    ...currentApplications,
                    {
                        ...app,
                    },
                ];
            });
            fetch(`${CAPI_ROOT}/${baseApi.defaults.baseURL}/file/upload/customapp/${id}`, {
                body: formData,
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
            }).then(async (response) => {
                if (response.ok) {
                    return response;
                } else {
                    throw response;
                }
            });

            return app;
        });

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <UploadContext.Provider
            value={{
                createCustomApplication,
                windowsApplications,
                subscribeToUpdates,
                unsubScribeToUpdates,
                removeCustomApplication,
                manualFetchUpdates: fetchData,
                createAndUploadWindowsApplication,
                updateAndUploadWindowsApplication,
                storageUsed,
            }}
        >
            {children}
        </UploadContext.Provider>
    );
};
