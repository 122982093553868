import { TooltipProps } from '@capasystems/types';
import MUITooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import React from 'react';

/** Tooltip component */
export const Tooltip: React.FC<TooltipProps> = ({
    classes = {},
    position = 'top',
    content,
    fullWidth = false,
    noPadding = false,
    interactive = false,
    className, // Use classes.popper instead?
    disableHoverListener = false,
    open,
    onOpen,
    onClose,
    children,
    arrow = false,
    theme = 'slate',
    offset = [0, 0],
    disablePortal = false,
}) => {
    return (
        <MUITooltip
            open={open}
            arrow={arrow}
            PopperProps={{
                disablePortal,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset,
                        },
                    },
                ]
            }}
            onOpen={onOpen}
            onClose={onClose}
            disableHoverListener={disableHoverListener}
            title={content}
            placement={position}
            classes={{
                popper: classNames('cs-tooltip-container', classes.popper, className),
                tooltip: classNames(
                    {
                        'cs-tooltip tw-relative tw-font-semibold tw-p-2': true,
                        'tw-bg-slate-800 tw-text-white': theme === "slate",
                        'tw-bg-rose-600 tw-text-white': theme === "rose",
                        'tw-bg-white tw-text-black': theme === "paper",
                        'tw-px-0 tw-py-0': noPadding,
                        'tw-max-w-none': fullWidth,
                    },
                    classes.tooltip
                ),
                arrow: classNames('cs-tooltip-arrow', {
                    "tw-text-slate-800":  theme === "slate",
                    "tw-text-rose-600":  theme === "rose",
                    "tw-text-white":  theme === "paper",
                }, classes.arrow),
            }}
            disableInteractive={!interactive}
        >
            {children}
        </MUITooltip>
    );
};

export default Tooltip;
