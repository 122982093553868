import { Paper, Tab, Tabs } from '@capasystems/ui';
import { Url, isDefined } from '@capasystems/utils';
import { Portal } from '@mui/base';
import {
    AppleAppLayerVPNConfiguration,
    AppleAppLockConfiguration,
    AppleCellularConfiguration,
    AppleDNSConfiguration,
    AppleDnsProxyConfiguration,
    AppleDomainsConfiguration,
    AppleEndpointSettings,
    AppleLdapConfiguration,
    AppleLockScreenMessageConfiguration,
    AppleNetworkUsageRulesConfiguration,
    AppleNotificationSettingsConfiguration,
    ApplePasscodeConfiguration,
    AppleRestrictionsConfiguration,
    AppleScepConfiguration,
    AppleVPNConfiguration,
    AppleWallpaperConfiguration,
    AppleWebClipConfiguration,
    AppleWebContentFilterConfiguration,
    AppleWifiConfiguration,
} from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import { default as classNames } from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { QueryBuilder, SchemaBuilder, useAppleApi } from '../../../../index';

const configurationTypes = [
    AppleEndpointSettings.schema,
    AppleVPNConfiguration.schema,
    AppleWebClipConfiguration.schema,
    AppleDNSConfiguration.schema,
    AppleAppLayerVPNConfiguration.schema,
    AppleDomainsConfiguration.schema,
    AppleRestrictionsConfiguration.schema,
    ApplePasscodeConfiguration.schema,
    AppleWifiConfiguration.schema,
    AppleCellularConfiguration.schema,
    AppleLockScreenMessageConfiguration.schema,
    AppleNetworkUsageRulesConfiguration.schema,
    AppleScepConfiguration.schema,
    AppleLdapConfiguration.schema,
    AppleWebContentFilterConfiguration.schema,
    AppleDnsProxyConfiguration.schema,
    AppleNotificationSettingsConfiguration.schema,
    AppleWallpaperConfiguration.schema,
    AppleAppLockConfiguration.schema,
].sort((a, b) => {
    const categoryCalc = a.category.toLocaleLowerCase().localeCompare(b.category.toLocaleLowerCase());
    if (categoryCalc === 0) {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    } else {
        return categoryCalc;
    }
});

const AppleEndpointAppliedConfigurationsTab = ({ endpoint, portalContainer }) => {
    const [selectedTab, setSelectedTab] = useState(Url.getString('schemaId', configurationTypes[0].schemaId));
    const [schemaBuilderSchema, setSchemaBuilderSchema] = useState(
        configurationTypes.find((cc) => cc.schemaId === selectedTab) || { title: 'Endpoint not found' }
    );

    const changeTab = (_, tab) => {
        const configurationType = configurationTypes.find((cc) => cc.schemaId === tab);
        setSchemaBuilderSchema(configurationType);
        Url.set('schemaId', tab);
        setSelectedTab(tab);
    };
    const queryBuilderProfiles = getDefaultQueryBuilderConfiguration({ placeholder: 'Search policy' });
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProfiles, setFilteredProfiles] = useState([]);
    const [endpointId, setEndpointId] = useState(endpoint._id);
    const [loading, setLoading] = useState(isDefined(endpointId));
    const propertiesBuilderRef = useRef({});
    const propertiesBuilderRefKeys = Object.keys(propertiesBuilderRef.current);
    const appleApi = useAppleApi();
    const [profiles, setProfiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const queryBuilderRef = useRef({});

    const onSubmit = ([activeLeaf]) => {
        const search = activeLeaf ? activeLeaf.value.toLowerCase() : '';
        setSearchTerm(search);
    };

    useEffect(() => {
        if (isDefined(endpointId)) {
            appleApi
                .getEndpointSettings(endpointId)
                .then((foundEndpoint) => {
                    if (foundEndpoint) {
                        propertiesBuilderRef.current = foundEndpoint;
                        setProfiles(propertiesBuilderRef.current);
                        const keys = Object.keys(propertiesBuilderRef.current);
                        if (keys.length !== 0) {
                            const tab = configurationTypes.find((ct) => {
                                return Object.keys(ct.properties).some((propertiesKey) => keys.includes(propertiesKey));
                            });
                            setSchemaBuilderSchema(tab);
                            Url.set('schemaId', tab?.schemaId);
                            setSelectedTab(tab?.schemaId);
                        }
                        setLoading(false);

                        if (foundEndpoint.profiles) {
                            const profilesData = foundEndpoint.profiles;
                            const filtered =
                                searchTerm === ''
                                    ? profilesData
                                    : profilesData.filter((profile) => profile.PayloadDisplayName.toLowerCase().includes(searchTerm.toLowerCase()));
                            setFilteredProfiles(filtered);
                        } else {
                            setFilteredProfiles([]);
                        }
                        setLoading(false);
                    } else {
                        setErrorMessage('Endpoint data is missing or invalid');
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.error('Fetch error:', error);
                    setErrorMessage('Failed to fetch endpoint data');
                    setLoading(false);
                });
        }
    }, [endpointId, appleApi, searchTerm]);

    const memorizedTabs = useMemo(() => {
        const tabs = [];
        Object.entries(Object.groupBy(configurationTypes, (ct) => ct.category))
            .sort(([keyA], [keyB]) => keyA.toLocaleLowerCase().localeCompare(keyB.toLocaleLowerCase()))
            .forEach(([key, ctArray]) => {
                const categoryMatches = key.toLowerCase().includes(searchTerm.toLowerCase());

                const matchingCTs = ctArray.filter(
                    (ct) =>
                        Object.keys(ct.properties).some((propertiesKey) => {
                            return propertiesBuilderRefKeys.includes(propertiesKey);
                        }) &&
                        (ct.title.toLowerCase().includes(searchTerm.toLowerCase()) || categoryMatches)
                );

                if (matchingCTs.length > 0) {
                    tabs.push(
                        <Tab
                            value={key}
                            disabled
                            className={classNames('tw-mb-1 tw-min-h-0 tw-py-0 tw-opacity-100', { 'tw-mt-2': tabs.length === 0, 'tw-mt-6': tabs.length !== 0 })}
                            label={<div className="tw-w-full tw-text-left tw-text-xs tw-text-neutral-500">{key}</div>}
                            key={'cat - ' + key}
                        />
                    );

                    matchingCTs.forEach((ct) => {
                        tabs.push(
                            <Tab
                                value={ct.schemaId}
                                classes={{ root: 'tw-pl-4' }}
                                label={
                                    <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center tw-gap-x-6">
                                        <div className="tw-mr-2 tw-text-left">{ct.title}</div>
                                    </div>
                                }
                                key={'tab - ' + ct.title}
                                disableRipple
                            />
                        );
                    });
                }
            });
        return tabs;
    }, [configurationTypes, propertiesBuilderRefKeys, searchTerm]);

    if (loading) {
        if (errorMessage) {
            return (
                <div>
                    <h2 className="tw-mb-4">{errorMessage}</h2>
                </div>
            );
        }
        return null;
    }
    if (!propertiesBuilderRef.current || Object.keys(propertiesBuilderRef.current).length === 0) {
        return (
            <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
                <div className="tw-p-6 tw-font-semibold">No endpoint data found</div>
            </div>
        );
    }

    return (
        <>
            <Portal container={portalContainer.current}>
                <QueryBuilder
                    defaultConfiguration={queryBuilderProfiles}
                    onInit={onSubmit}
                    onSubmit={onSubmit}
                    className="tw-mx-auto tw-w-full"
                    ref={queryBuilderRef}
                />
            </Portal>
            <div className="tw-col-span-1 tw-flex tw-h-full tw-overflow-hidden">
                <div
                    className="tw-grid tw-h-full tw-grid-rows-auto-1fr tw-gap-y-4"
                    style={{ minWidth: 275 }}
                >
                    <Tabs
                        value={selectedTab}
                        onChange={changeTab}
                        orientation="vertical"
                        pills
                        className="tw-h-full"
                        variant="scrollable"
                    >
                        {memorizedTabs}
                    </Tabs>
                </div>
                <Paper
                    className="tw-h-full tw-overflow-auto tw-shadow-sm"
                    style={{ flexGrow: 1, marginLeft: '20px' }}
                >
                    <SchemaBuilder
                        key={selectedTab}
                        readOnly
                        schema={schemaBuilderSchema}
                        currentState={propertiesBuilderRef.current}
                    />
                </Paper>
            </div>
        </>
    );
};

export { AppleEndpointAppliedConfigurationsTab };
